import riding from './../../static/images/do-nothing/riding.jpeg'
import reading from './../../static/images/do-nothing/reading.jpg'
import relaxing from './../../static/images/do-nothing/relaxing.jpg'
import playing from './../../static/images/do-nothing/playing.jpeg'
import resting from './../../static/images/do-nothing/resting.jpg'
import staringAt from './../../static/images/do-nothing/staring-at.jpg'
import walkingOut from './../../static/images/do-nothing/walking-out.png'
import dreaming from './../../static/images/do-nothing/dreaming.jpg'
import reloading from './../../static/images/do-nothing/reloading.jpeg'
import entertaining from './../../static/images/do-nothing/entertaining.jpg'
import eating from './../../static/images/do-nothing/eating.jpeg'
import swimming from './../../static/images/do-nothing/swimming.jpg'
import competing from './../../static/images/do-nothing/competing.jpeg'
import doing from './../../static/images/do-nothing/doing.jpg'
import listenTo from './../../static/images/do-nothing/listen-to.jpeg'
import beingTogether from './../../static/images/do-nothing/being-together.jpg'
import kidding from './../../static/images/do-nothing/kidding.jpg'

export const doNothingOptionsUkr = [
  {
    key: 'shelest-riding',
    image: riding,
    description:
      'Ловіть зустрічний вітер і досліджуйте лісові стежки. Велопрогулянки відмінно знімають стрес.',
    title: 'Кататися',
  },
  {
    key: 'shelest-reading',
    image: reading,
    description:
      'Шукайте улюблених або відкривайте нових авторів у нашій мінібібліотеці.',
    title: 'Читати',
  },
  {
    key: 'shelest-relaxing',
    image: relaxing,
    description:
      'Сеанс від професійного масажиста відновлює сили та покращує самопочуття.',
    title: 'Релаксувати',
  },
  {
    key: 'shelest-playing',
    image: playing,
    description:
      'Ви командний гравець або змагаєтеся самотужки? В обох випадках ви зможете розважитися з користю!',
    title: 'Грати',
  },
  {
    key: 'shelest-resting',
    image: resting,
    description:
      'Залишіться наодинці із собою, щоб повністю позбавитися думок і ненадовго втекти від реальності. Надихайтеся відпочинком біля озера.',
    title: 'Відпочивати',
  },
  {
    key: 'shelest-staring-at',
    image: staringAt,
    description:
      'Щодня в пабі дивимося шедеври кіно на великому екрані. Залюбки покажемо ваші улюблені стрічки — тільки дайте знати.',
    title: 'Дивитися',
  },
  {
    key: 'shelest-walking-out',
    image: walkingOut,
    description:
      'Ліс, озеро, спів пташок і свіже повітря — у цю затишну атмосферу хочеться повертатися знову й знову.',
    title: 'Гуляти',
  },
  {
    key: 'shelest-dreaming',
    image: dreaming,
    description:
      'Зігріватися біля вогнища й думати про майбутні досягнення. Без мрій не буває перемог, чи не так?',
    title: 'Мріяти',
  },
  {
    key: 'shelest-reloading',
    image: reloading,
    description:
      'Наповніть свій організм цілющою енергією пари. На вас чекає абсолютний релакс у лазні',
    title: 'Перезавантажуватися',
  },
  {
    key: 'shelest-entertaining',
    image: entertaining,
    description:
      'Затишна компанія, настільні ігри — створюйте разом із близькими теплі спогади.',
    title: 'Розважатися',
  },
  {
    key: 'shelest-eating',
    image: eating,
    description:
      'Улюблені страви. Кухня різних країн світу, затишок, заспокійлива природа навколо — ресторан Shelest створений для медитації та смакування їжі одночасно.',
    title: 'Їсти',
  },
  {
    key: 'shelest-swimming',
    image: swimming,
    description:
      'У басейні просто неба. Літо без нього — не літо. Засмагайте, плавайте або просто читайте книгу біля води. Також є маленький басейн для діток.',
    title: 'Плавати',
  },
  {
    key: 'shelest-competing',
    image: competing,
    description:
      'Весело проведіть час на мініфутбольному полі. З нас м’яч, з вас — гарний настрій.',
    title: 'Змагатися',
  },
  {
    key: 'shelest-doing',
    image: doing,
    description:
      'Відпочинок не завадить вашому графіку тренувань — займайтеся на спортмайданчику на свіжому повітрі та залишайтеся у формі.',
    title: 'Займатися',
  },
  {
    key: 'shelest-listen-to',
    image: listenTo,
    description:
      'Беріть із собою улюблені платівки або слухайте наші. Вініловий програвач — окремий вид релаксу для істинних поціновувачів музики.',
    title: 'Слухати',
  },
  {
    key: 'shelest-being-together',
    image: beingTogether,
    description:
      'Ми dogs friendly. Обожнюємо песиків, а вони — нас. Беріть із собою хвостатих улюбленців і відпочивайте разом з ними.',
    title: 'Бути разом',
  },
  {
    key: 'shelest-kidding',
    image: kidding,
    description:
      'Дитяча кімната для вашої малечі. Грайтеся з дітками або дозвольте їм побешкетувати окремо — малеча буде в захваті!',
    title: 'Бешкетувати',
  },
]

export const doNothingOptionsEng = [
  {
    key: 'shelest-riding',
    image: riding,
    description:
      'Catch a headwind and explore forest trails. Cycling is an excellent stress reliever.',
    title: 'Ride',
  },
  {
    key: 'shelest-reading',
    image: reading,
    description:
      'Look for your favorite authors or discover new ones in our mini library.',
    title: 'Read',
  },
  {
    key: 'shelest-relaxing',
    image: relaxing,
    description:
      'A massage session from a professional therapist restores strength and improves well-being.',
    title: 'Relax',
  },
  {
    key: 'shelest-playing',
    image: playing,
    description:
      'Are you a team or a solo player? In both cases, you will have fun!',
    title: 'Play',
  },
  {
    key: 'shelest-resting',
    image: resting,
    description:
      'Spend time with yourself to clear your mind and escape from reality for a while. Get inspired by a walk by the small river.',
    title: 'Rest',
  },
  {
    key: 'shelest-staring-at',
    image: staringAt,
    description:
      'Every day in the pub, we watch movie masterpieces on the big screen. We will gladly turn on your favorite films - just let us know.',
    title: 'Watch',
  },
  {
    key: 'shelest-walking-out',
    image: walkingOut,
    description:
      'Forest, small river, fresh air, and birds chirping - a cozy atmosphere that calms and inspires.',
    title: 'Walk',
  },
  {
    key: 'shelest-dreaming',
    image: dreaming,
    description:
      'Warm up by the fire and think about future accomplishments. Without dreams, there are no victories, right?',
    title: 'Dream',
  },
  {
    key: 'shelest-reloading',
    image: reloading,
    description:
      'Fill your body with the healing energy of steam. Deep relaxation awaits you in the bathhouse.',
    title: 'Recharge',
  },
  {
    key: 'shelest-entertaining',
    image: entertaining,
    description:
      'Play board games with your loved ones creating fond memories.',
    title: 'Entertain',
  },
  {
    key: 'shelest-eating',
    image: eating,
    description:
      'Favorite dishes, international cuisine, cozy atmosphere, and soothing nature around - Shelest restaurant is about meditation and tasting food simultaneously.',
    title: 'Eat',
  },
  {
    key: 'shelest-swimming',
    image: swimming,
    description:
      'We have an open-air pool, and summer without it isn’t summer. Sunbathe, swim, or just read a book by the water. There is also a small pool for children.',
    title: 'Swim',
  },
  {
    key: 'shelest-competing',
    image: competing,
    description:
      'Have fun playing on the mini-football field. A ball is on us, and a good mood is on you.',
    title: 'Compete',
  },
  {
    key: 'shelest-doing',
    image: doing,
    description:
      'Vacation isn’t an obstacle to your sports routine - exercise in the fresh air and stay in shape.',
    title: 'Exercise',
  },
  {
    key: 'shelest-listen-to',
    image: listenTo,
    description:
      'Bring your favorite records or pick them from our collection. A vinyl record player is a special kind of relaxation for true music lovers.',
    title: 'Listen',
  },
  {
    key: 'shelest-being-together',
    image: beingTogether,
    description:
      'We are pet friendly. We adore dogs, and it’s mutual. Plan a weekend with your beloved pets and relax together.',
    title: 'Be together',
  },
  {
    key: 'shelest-kidding',
    image: kidding,
    description:
      'Play with your children in a playroom or let them entertain with peers - the kids will be delighted!',
    title: 'Have fun',
  },
]
