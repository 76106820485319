import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import Loader from '../../components/Loader'

import { onGetCart } from '../../redux/cart/cart'

import { reverseLocation } from '../../utils/helpers'

import { bookingNavigationItems } from '../../utils/constants'

import './BookingNavigation.scss'

const BookingNavigation = ({
  cartItemsListCount,
  onGetCart,
  isAuthenticated,
  isCartLoading,
  isProcessing,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const [currentRoute, setCurrentRoute] = useState(reverseLocation(location))
  const [shouldAnimate, setShouldAnimate] = useState('')

  const navigateToEntity = (e, route) => {
    e.preventDefault()
    setCurrentRoute(route)
    history.push(route)
  }

  const renderNavItems = bookingNavigationItems => {
    return bookingNavigationItems.map(({ route, key, translation }) => (
      <span
        className={`booking-navigation-list-item${
          currentRoute === route ? ' active' : ''
        }`}
        onClick={e => navigateToEntity(e, route)}
        key={key}
      >
        {t(`Booking.Navigation.${translation}`)}
      </span>
    ))
  }

  // useEffect(() => {
  //   if (cartItemsListCount > 0) {
  //     setShouldAnimate(true)
  //     setTimeout(() => setShouldAnimate(false), 1000)
  //   }
  // }, [cartItemsListCount])

  useEffect(() => {
    setCurrentRoute(reverseLocation(location))
  }, [location])

  useEffect(() => {
    onGetCart({ isAuthenticated })
  }, [isAuthenticated])

  return (
    <div className="booking-navigation-wrapper">
      <div className="booking-navigation">
        <ul className="booking-navigation-list">
          {renderNavItems(bookingNavigationItems)}

        </ul>
      </div>
    </div>
  )
}

// ${
//   shouldAnimate ? 'on-change-animation' : ''
// }

const mapStateToProps = state => ({
  cartItemsListCount: state.cart.cartItemsListCount,
  isAuthenticated: state.auth.isAuthenticated,
  isCartLoading: state.cart.isCartLoading,
  isProcessing: state.cart.isProcessing,
})

const mapDispatchToProps = dispatch => ({
  onGetCart: action => dispatch(onGetCart(action)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BookingNavigation)
