import React from 'react'
import PropTypes from 'prop-types'

import Loader from '../Loader'

const Button = ({
  htmlType,
  children,
  classNames,
  onClick,
  isLoading,
  isDisabled,
  buttonId,
}) => {
  const onButtonClick = () => onClick()

  return (
    <button
      disabled={isLoading || isDisabled}
      onClick={onButtonClick}
      className={`btn ${classNames}`}
      type={htmlType}
      id={buttonId}
    >
      {isLoading ? <Loader /> : children}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  classNames: PropTypes.string,
  onClick: PropTypes.func,
  htmlType: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  temporarySolution: PropTypes.bool,
  buttonId: PropTypes.string,
}

Button.defaultProps = {
  classNames: '',
  onClick: () => {},
  htmlType: '',
  isLoading: false,
  isDisabled: false,
  temporarySolution: false,
  buttonId: null,
}

export default Button
