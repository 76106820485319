import restaurant from '../static/images/restaurant/restaurant.svg'
import restaurantEn from '../static/images/restaurant/restaurantEn.svg'
import restaurantPhoto1 from '../static/images/restaurant/restaurantPhoto1.jpg'
import restaurantPhoto2 from '../static/images/restaurant/restaurantPhoto2.jpg'
import restaurantPhoto3 from '../static/images/restaurant/restaurantPhoto3.jpg'

import poolRu from '../static/images/pool/pool-ru.svg'
import poolUkr from '../static/images/pool/pool-ukr.svg'
import poolEn from '../static/images/pool/pool-en.svg'
import poolPhoto1 from '../static/images/pool/poolPhoto1.png'
import poolPhoto2 from '../static/images/pool/poolPhoto2.jpg'
import poolPhoto3 from '../static/images/pool/poolPhoto3.jpg'

import covidUkr from '../static/images/covid/covid-ukr.svg'
import covidRus from '../static/images/covid/covid-rus.svg'
// import covidEn from '../static/images/covid/covid-en.svg'

import contactsUkr from '../static/images/contacts/contactsUkr.svg'
import contactsEn from '../static/images/contacts/contactsEn.svg'
import contactsRu from '../static/images/contacts/contactsRu.svg'

export const staticPagesProps = {
  restaurant: {
    page: 'restaurant',
    titleImageUkr: restaurant,
    titleImageRu: restaurant,
    titleImageEn: restaurantEn,
    sliderImages: [restaurantPhoto1, restaurantPhoto2, restaurantPhoto3],
  },
  pool: {
    page: 'pool',
    titleImageUkr: poolUkr,
    titleImageRu: poolRu,
    titleImageEn: poolEn,
    sliderImages: [
      poolPhoto2,
      poolPhoto1,
      { img: poolPhoto3, style: { objectPosition: 'top' } },
    ],
  },
  covid: {
    page: 'сovid',
    titleImageUkr: covidUkr,
    titleImageRu: covidRus,
    titleImageEn: covidUkr,
    sliderImages: [],
  },
  contacts: {
    page: 'contacts',
    titleImageUkr: contactsUkr,
    titleImageRu: contactsRu,
    titleImageEn: contactsEn,
    sliderImages: [],
  },
}
