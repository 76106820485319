import React from 'react'
import { Button } from 'antd'
import HeroImage from '../../components/HeroImage'

import TeamBG from '../../static/images/team/team-main.jpg'
import Team1 from '../../static/images/team/team-1.jpg'
import Team2 from '../../static/images/team/team-2.jpg'

import './Team.scss'
import ImagesMasonry from '../../components/ImagesMasonry/ImagesMasonry'

export const teamMasonry = {
  masonryConfig: {
    parentConfig: {
      rows: 1,
      cols: 2,
      maxHeight: 500,
    },
    images: [
      {
        image: Team1,
        classes: 'row-span-1 col-span-1',
      },
      {
        image: Team2,
        classes: 'row-span-1 col-span-1',
      },
    ],
  },
}

const Team = () => {
  return (
    <div className="team-wrapper">
      <HeroImage backgroundImage={TeamBG} heroText="Команда" />
      <div className="team-content-wrapper">
        <p>
          shelest — місце, де зупиняється час і можна забути про буденність.
          ставши членом нашої команди, ти приєднуєшся до спільноти однодумців, в
          якій кожен розвивається, набуває досвіду та ділиться ним, досягає
          поставлених цілей та отримує за це достойну винагороду. відчуваєш свій
          потенціал? радо познайомимось з тобою.
        </p>
        <Button
          type="primary"
          size="large"
          href="https://bit.ly/42fSbcM"
          target="_blank"
        >
          Відправити заявку
        </Button>
      </div>
      <ImagesMasonry masonryConfig={teamMasonry.masonryConfig} />
    </div>
  )
}

export default Team
