import React from 'react'
import PropTypes from 'prop-types'
import { Menu, Dropdown } from 'antd'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'

import { onLogOut } from '../../redux/auth/auth'

import './NavProfile.scss'

const NavProfile = ({ user: { firstName, secondName }, onLogOut }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const navigateToBookingHistory = () =>
    history.push('/profile/booking-history')

  const menu = (
    <Menu>
      <Menu.Item onClick={navigateToBookingHistory}>
        <span>{t('Profile.BookingHistory')}</span>
      </Menu.Item>
      <Menu.Item onClick={onLogOut}>
        <span>{t('Home.Navigation.LogOut')}</span>
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown trigger="click" overlay={menu}>
      <div className="nav-profile-wrapper">
        <div className="nav-profile">
          <div className="nav-profile-circle">
            <span className="name">
              {firstName[0]}
              {secondName[0]}
            </span>
          </div>
          <div className="nav-profile-name">
            <span>{firstName}</span>
            <span>{secondName}</span>
          </div>
        </div>
      </div>
    </Dropdown>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
})

const mapDispatchToProps = dispatch => ({
  onLogOut: () => dispatch(onLogOut()),
})

NavProfile.propTypes = {
  user: PropTypes.object.isRequired,
  onLogOut: PropTypes.func.isRequired,
}

NavProfile.defaultProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(NavProfile)
