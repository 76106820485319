import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { isEmpty, isNil } from 'ramda'

const ProtectedRoute = ({
  whatToCheck,
  whereToRedirect,
  currentRoom,
  ...props
}) => {
  const queryParams = useLocation().search

  const checkValues = () => {
    switch (whatToCheck) {
      case 'currentRoom':
        if (!currentRoom) {
          return false
        } else {
          return true
        }
      case 'queryParams-token':
        if (isNil(new URLSearchParams(queryParams).get('token'))) {
          return false
        } else {
          return true
        }
      case 'queryParams-ubn-ubsign':
        if (
          isNil(new URLSearchParams(queryParams).get('ubn')) ||
          isNil(new URLSearchParams(queryParams).get('ubsign'))
        ) {
          return false
        } else {
          return true
        }
      default:
        return
    }
  }

  const checkWhereToRedirect = () => {
    switch (whereToRedirect) {
      case 'rooms':
        return <Redirect to="/rooms" />
      default:
        return <Redirect to="/" />
    }
  }

  const isChecked = checkValues()

  return isChecked ? (
    <Route key={props.path} {...props} />
  ) : (
    checkWhereToRedirect()
  )
}

const mapStateToProps = state => ({
  currentRoom: state.rooms.currentRoom,
})

const mapDispatchToProps = dispatch => ({})

ProtectedRoute.propTypes = {
  whatToCheck: PropTypes.string.isRequired,
  whereToRedirect: PropTypes.string,
  currentRoom: PropTypes.object,
}

ProtectedRoute.defaultProps = {
  whereToRedirect: '',
  currentRoom: null,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute)
