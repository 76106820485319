import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { uid } from 'react-uid'

import Button from '../Button'
import CarouselSlider from '../CarouselSlider'

import i18n from '../../i18n/i18n'

import living_area from '../../static/icons/icon_gostinnaya.svg'
import dogs_friendly from '../../static/icons/icon_dogs.svg'
import fireplace from '../../static/icons/icon_kamin.svg'
import no_smoking from '../../static/icons/icon_nosmoking.svg'
import bathroom from '../../static/icons/icon_sanuzel.svg'
import two_bedrooms from '../../static/icons/icon_spalnya.svg'
import terrace from '../../static/icons/icon_terrasa.svg'
import bathtub from '../../static/icons/icon_vanna.svg'
import { normalizeIdString } from '../../utils/helpers'

const Icons = (
  <>
    <li key={uid(two_bedrooms)}>
      <img className="option-icon" src={two_bedrooms} alt="" />
    </li>
    <li key={uid(living_area)}>
      <img className="option-icon" src={living_area} alt="" />
    </li>
    <li key={uid(fireplace)}>
      <img className="option-icon" src={fireplace} alt="" />
    </li>
    <li key={uid(terrace)}>
      <img className="option-icon" src={terrace} alt="" />
    </li>
    <li key={uid(no_smoking)}>
      <img className="option-icon" src={no_smoking} alt="" />
    </li>
    <li key={uid(bathtub)}>
      <img className="option-icon" src={bathtub} alt="" />
    </li>
    <li key={uid(bathroom)}>
      <img className="option-icon" src={bathroom} alt="" />
    </li>
  </>
)

const featuresIcons = {
  living_area,
  fireplace,
  two_bedrooms,
  terrace,
  bathtub,
}

const desiredFeatures = [
  'халати',
  'окремий вхід до номеру',
  'панорамні вікна',
  'тераса',
  'гіпоалергенна білизна',
  'дві спальні',
  'гостьова зона',
  'камін',

  'халаты',
  'отдельный вход в номер',
  'панорамные окна',
  'терраса',
  'гипоаллергенное белье',
  'две спальни',
  'зона для гостей',
  'камин',

  'bathrobe',
  'separate entrance to the room',
  'floor-to-ceiling windows',
  'patio',
  'hypoallergenic sheets',
  'two bedrooms',
  'fireplace',
  'living area',
]
//console.log('HELLO=======>3');
//localStorage.setItem('room_code', 'value123')
//console.log('localStorage11=======>',localStorage.getItem('room_code'));
const RoomInfo = ({ room, onSetCurrentRoom, isDetails, currentRoom }) => {
  console.log('room: ', room);
  const history = useHistory()

  const showMoreInfo = room => {
    onSetCurrentRoom(room)
    history.push(`/room/${room.code}`)
  }

  const renderFeatures = () => {
    // if (isDetails) {
    //   return currentRoom.static.amenities.map(
    //     option =>
    //       featuresIcons[option.kind] && (
    //         <li key={option.kind + option.name}>
    //           <img
    //             className="option-icon"
    //             src={featuresIcons[option.kind]}
    //             alt=""
    //           />
    //         </li>
    //       )
    //   )
    // }

    // return room.amenities.map(
    //   option =>
    //     featuresIcons[option.kind] && (
    //       <li key={option.kind + option.name}>
    //         <img
    //           className="option-icon"
    //           src={featuresIcons[option.kind]}
    //           alt=""
    //         />
    //       </li>
    //     )
    // )

    return Icons
  }
  

  const renderPackages = () =>
    currentRoom.squad_packages && currentRoom.squad_packages.length > 0
      ? currentRoom.squad_packages.map(packageitem => (
          <>
            <br />
            <p>{packageitem.title}</p>
            <li key={packageitem.id}>{packageitem.description}</li>
          </>
        ))
      : null

  const navigateToBooking = (param) => {
    return history.push('/booking/hotel')
      /*
    //history.push(`/room/${room.code}`)
    localStorage.setItem('room_code', 'value')
    console.log('localStorage=======>',localStorage.getItem('room_code'));
  
    return history.push({
      pathname: '/booking/hotel',
      state:{  username:"testName"}
    });
*/
  }

  return isDetails ? (
    <div className="room-wrapper">
      <div className="room-wrapper-title">
        <h1>{currentRoom?.static.name}</h1>
      </div>
      <li className="rooms-list-item" key={currentRoom?.static.code}>
        <div className="rooms-slider-wrapper">
          <CarouselSlider
            isRooms
            carouselItems={currentRoom?.static.images}
            dotsPosition="bottom-right"
            className="rooms-list"
          />
        </div>
        <div className="description-wrapper">
          <div className="description">
            <h1 className="description-title">{currentRoom?.static.name}</h1>
            <p
              className="description-info"
              dangerouslySetInnerHTML={{
                __html: currentRoom?.static.description,
              }}
            />
            {currentRoom?.static?.amenities?.length ? (
              <>
                <br />
                <p>{i18n.t('Rooms.SecondParagraph')}</p>
                <ul className="features-list">{renderFeatures()}</ul>
              </>
            ) : null}
            <p style={{ marginTop: 40, maxWidth: 450 }}>
              {i18n.t('Rooms.ThirdParagraph')}
            </p>
            {/* {currentRoom.squad_packages &&
            currentRoom.squad_packages.length > 0 ? (
              <>
                <br />
                <p>Особливості: </p>
                <ul className="features-list">{renderPackages()}</ul>
              </>
            ) : null} */}
          </div>
        </div>
      </li>
    </div>
  ) : (
    <li className="rooms-list-item" key={room.code}>
      <div className="rooms-slider-wrapper">
        <CarouselSlider
          isRooms
          carouselItems={room.images}
          dotsPosition="bottom-right"
          className="rooms-list"
        />
      </div>
      <div className="description-wrapper">
        <div className="description" style={{ maxWidth: 550 }}>
          <h1 className="description-title">{room.name}</h1>
          <p
            className="description-info"
            dangerouslySetInnerHTML={{ __html: room.description }}
          />
        </div>
        <Button
          temporarySolution
          onClick={navigateToBooking}
          classNames="btn-green"
          buttonId={`room_info_book_button_${normalizeIdString(room.name)}`}
        >
          {i18n.t('Button.Book')}
        </Button>
        <p style={{ marginTop: 40 }}>{i18n.t('Rooms.SecondParagraph')} </p>
        <ul
          className="features-list"
          // style={{ listStyle: 'none', padding: 0, margin: 0 }}
        >
          {renderFeatures()}
        </ul>
        <p style={{ marginTop: 40, maxWidth: 450 }}>
          {i18n.t('Rooms.ThirdParagraph')}
        </p>
      </div>
    </li>
  )
}

RoomInfo.propTypes = {
  room: PropTypes.object,
  onSetCurrentRoom: PropTypes.func,
  isDetails: PropTypes.bool,
  currentRoom: PropTypes.object,
}

RoomInfo.defaultProps = {
  room: null,
  isDetails: true,
  currentRoom: null,
  onSetCurrentRoom: () => {},
}

const mapStateToProps = state => ({
  currentRoom: state.rooms.currentRoom,
})

export default connect(mapStateToProps)(RoomInfo)
