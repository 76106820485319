import React from 'react'
import { notification } from 'antd'
import { length, isNil } from 'ramda'

import enLocale from 'antd/es/date-picker/locale/en_GB'
import ukrLocale from 'antd/es/date-picker/locale/uk_UA'
import ruLocale from 'antd/es/date-picker/locale/ru_RU'

import NotificationDescription from '../components/NotificationDescription/NotificationDescription.jsx'
import NotificationTitle from '../components/NotificationTitle/NotificationTitle.jsx'

import {
  notificationTypes,
  titleTypes,
  ukrCurrencyTranslations,
  enCurrencyTranslations,
  ukrDigitVersions,
  ruDigitVersions,
  enDigitVersions,
} from './constants'

export const reverseLocation = location => {
  return location.pathname.split('/').reverse()[0]
}

export const getLocale = locale => {
  switch (locale) {
    case 'ru':
      return ruLocale
    case 'en':
      return enLocale
    default:
      return ukrLocale
  }
}

export const renderNotification = (
  type,
  message,
  descriptionItems = null,
  descriptionType = null
) => {
  const notificationType = notificationTypes[type]

  switch (notificationType) {
    case 'error':
      return notification.error({
        message: <NotificationTitle title={message} />,
        description: (
          <NotificationDescription
            descriptionItems={descriptionItems}
            descriptionType={descriptionType}
          />
        ),
        duration: 5,
      })
    case 'success':
      return notification.success({
        message: <NotificationTitle title={message} />,
        duration: 5,
      })
    default:
      return
  }
}

export const somethingWentWrongNotification = () => {
  return notification.error({
    message: <NotificationTitle title={titleTypes.somethingWentWrong} />,
    duration: 5,
  })
}

export const validateRange = (range, rangeType) => {
  if (length(range) !== 2) {
    throw new Error('Hours range should be an array with length exactly 2')
  }

  const isArrayOfNumbers = range.every(element => typeof element === 'number')

  if (isNil(isArrayOfNumbers)) {
    throw new Error('Array must contain only numbers')
  }

  if (range[0] > range[1]) {
    throw new Error('First element of array must be lesser than second element')
  }

  if (rangeType === 'hours') {
    if (range[0] < 0 || range[1] > 24) {
      throw new Error('Acceptable hours range is between 0 and 24 hours')
    }
  } else if (rangeType === 'minutes') {
    if (range[0] < 0 || range[1] > 60) {
      throw new Error('Acceptable minutes range is between 0 and 60 minutes')
    }
  } else if (rangeType === 'seconds') {
    if (range[0] < 0 || range[1] > 60) {
      throw new Error('Acceptable seconds range is between 0 and 60 seconds')
    }
  }

  return true
}

export const renderCurrency = (currency, systemLanguage) => {
  switch (systemLanguage) {
    case 'ukr':
      return ukrCurrencyTranslations[currency]
    case 'en':
      return enCurrencyTranslations[currency]
    default:
      return ukrCurrencyTranslations[currency]
  }
}

export const renderCountOfDays = (fullDays, systemLanguage) => {
  const fullDaysToString = [...String(fullDays)]
  const lastElement = fullDaysToString.length

  if (
    (fullDays === 0 || fullDays >= 5) &&
    fullDaysToString[lastElement] !== '1'
  ) {
    if (systemLanguage === 'ukr') {
      return ukrDigitVersions.pluralFirst
    }
    if (systemLanguage === 'en') {
      return enDigitVersions.plural
    }
    if (systemLanguage === 'ru') {
      return ruDigitVersions.pluralFirst
    }
  }
  if (
    (fullDays === 1 || fullDaysToString[lastElement] === '1') &&
    fullDays !== 11
  ) {
    if (systemLanguage === 'ukr') {
      return ukrDigitVersions.singular
    }
    if (systemLanguage === 'en') {
      return enDigitVersions.singular
    }
    if (systemLanguage === 'ru') {
      return ruDigitVersions.singular
    }
  }
  if (systemLanguage === 'ukr') {
    return ukrDigitVersions.pluralSecond
  }
  if (systemLanguage === 'en') {
    return enDigitVersions.plural
  }
  if (systemLanguage === 'ru') {
    return ruDigitVersions.pluralSecond
  }
}

export const normalizeIdString = string => {
  return string.split(' ').join('_')
}

export const clearUTMMetaData = () => {
  localStorage.removeItem('utm_source')
  localStorage.removeItem('utm_medium')
  localStorage.removeItem('utm_campaign')
  localStorage.removeItem('utm_content')
}
