import React, { useEffect, useState } from 'react'
import { Modal, Form, Input, DatePicker, notification } from 'antd'
import { NavLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import TextArea from 'antd/lib/input/TextArea'

import Button from '../../../components/Button'
import ImagesMasonry from '../../../components/ImagesMasonry'

import useSystemLanguage from '../../../hooks/useSystemLanguage'
import useAbstractionMediaQueries from '../../../hooks/useAbstractionMediaQueries'

import { getLocale } from '../../../utils/helpers'

import ContactUsModal from '../event/ContactUsModal'

import {
  ukrainianPhoneNumberPattern,
  shortenFormat,
} from '../../../utils/constants'
import events from '../constants'

import './EventStyles.scss'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'

const { RangePicker } = DatePicker

const Event = () => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [endOpen, setEndOpen] = useState(false)
  const { systemLanguage } = useSystemLanguage()
  const { isMobile, isTablet } = useAbstractionMediaQueries()
  const [isVisible, setVisible] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isLoading, setLoading] = useState(false)
  const sendDataToGTM = useGTMDispatch()

  const onSubmit = values => {
    const { startDate, endDate, ...rest } = values

    console.log('(values.eventType: ', values.eventType)

    if (values.eventType !== 'birthdayChild') {
      delete values.isAnimator
    }

    if (values.eventType === 'birthdayChild') {
      delete values.eventTypeCompanyName
      delete values.email
      delete values.eventFormat
    }

    if (values.eventType === 'corporate' || values.eventType === 'conference') {
      delete values.isAppartments
    }

    setLoading(true)

    return fetch('https://api.shelest.ua/etc/new-events', {
      body: JSON.stringify({
        ...rest,
        ...(values?.endDate
          ? {
              date: `${values.startDate.format(
                'DD/MM/YYYY'
              )} - ${values.endDate.format('DD/MM/YYYY')}`,
            }
          : {
              date: values.startDate.format('DD/MM/YYYY'),
            }),
        isAppartments: values?.isAppartments === 'yes' ? true : false,
        ...(values?.isAnimator
          ? values?.isAnimator === 'yes'
            ? { isAnimator: true }
            : { isAnimator: false }
          : values.eventType === 'birthdayChild'
          ? { isAnimator: false }
          : {}),
        eventType:
          values?.eventType === 'other'
            ? `other - ${values?.eventTypeName}`
            : values?.eventType,
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(() => {
        sendDataToGTM({
          event: 'FormSentEvent',
          eventType: 'FormSent',
        })
        setVisible(false)
        notification.success({
          message: 'Success!',
        })
      })
      .catch(err => {
        console.log('err: ', err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  moment.updateLocale('uk', {
    weekdays: [
      'Неділя',
      'Понеділок',
      'Вівторок',
      'Середа',
      'Четвер',
      `П'ятниця`,
      'Субота',
    ],
  })
  moment.updateLocale('ru', {
    weekdays: [
      'Воскресенье',
      'Понедельник',
      'Вторник',
      'Среда',
      'Четверг',
      'Пятница',
      'Суббота',
    ],
  })

  const getPlaceholderText = lang => {
    switch (lang) {
      case 'ukr':
        return ['Заїхати', 'Виїхати']
      case 'en':
        return ['In', 'Out']
      case 'ru':
        return ['Заехать', 'Выехать']
    }
  }

  const disabledEndDate = endValue => {
    if (!endValue || !startDate) {
      return false
    }
    return endValue.valueOf() <= startDate.valueOf() + 3600
  }

  const onStartChange = value => {
    setStartDate(value)
    setEndDate(null)
  }

  const onEndChange = value => {
    setEndDate(value)
  }

  const handleStartOpenChange = open => {
    if (!open) {
      setEndOpen(true)
    }
  }

  const handleEndOpenChange = open => {
    setEndOpen(open)
  }

  const { pathname } = useLocation()
  const { t, i18n } = useTranslation()
  const eventUrl = pathname.split('/').reverse()[0]

  if (events.every(event => event.url !== eventUrl)) {
    return <p>No such event</p>
  }

  const {
    masonryConfig = { parentConfig: {}, images: [] },
    hasMasonry,
    images,
    iframe360,
    i18nField,
    content,
    hasPlan,
    plan = { title: '', image: null },
  } = events.filter(event => event.url === eventUrl)[0]
  const {
    images: masonryConfigImages,
    parentConfig = { rows: 0, cols: 0 },
  } = masonryConfig
  const { title, image } = plan

  const locale = getLocale(systemLanguage)
  const placeholderText = getPlaceholderText(systemLanguage)

  const renderMasonry = () => {
    if (masonryConfig && hasMasonry) {
      return <ImagesMasonry masonryConfig={masonryConfig} />
    }

    return images.map((image, index) => {
      if (hasMasonry) {
        return (
          <div className="event-masonry">
            {image.map(image => {
              if (image.isPlan) {
                return (
                  <div className={image.classes}>
                    <h2 style={{ textAlign: 'center', marginBottom: 10 }}>
                      {image.title}
                    </h2>
                    <a href={image.image} target="_blank">
                      <img src={image.image} alt={image} key={image} />
                    </a>
                  </div>
                )
              }
              return <img src={image.image} className={image.classes} />
            })}
          </div>
        )
      }
      return <img src={image} alt={image} key={image} />
    })
  }
return isTablet ? (
//  return (
    <div className="event-page">

      <div className="event-page-right-panel">
        {content({ t, i18nField, i18n })}
        <div style={{ marginTop: 10 }}>
          {hasPlan ? (
            <div>
              <a href={image} target="_blank" rel="noreferrer">
                <Button
                  classNames="btn-mid-pad small-btn btn-green h-40 w-100"
                  buttonId="event_event_planning_button"
                >
                  {t(`Events.${i18nField}.EventPlanning`)}
                </Button>
              </a>
            </div>
          ) : null}
          <div style={{ marginTop: 10 }}>
            <Button
              classNames="btn-green small-btn w-100"
              onClick={() => setVisible(!isVisible)}
              buttonId="event_contact_us_button"
            >
              {t('Events.ContactUs.ContactUsButton')}
            </Button>
          </div>
        </div>
      </div>
      <div className="event-page-images">
        {iframe360 && <div className="iframe-wrapper">{iframe360}</div>}
        {renderMasonry()}
      </div>

      {/* <Form.Item
            name="secondName"
            rules={[
              {
                required: true,
                message: t('Validation.Required'),
              },
            ]}
          >
            <Input placeholder={t('InputPlaceholders.Surname')} />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: t('Validation.Required'),
              },
              {
                type: 'email',
                message: t('Validation.InvalidEmailFormat'),
              },
            ]}
          >
            <Input placeholder={t('InputPlaceholders.Email')} />
          </Form.Item>
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                message: t('Validation.Required'),
              },
              {
                pattern: ukrainianPhoneNumberPattern,
                message: 'Номер телефону так не виглядає',
              },
            ]}
          >
            <Input
              maxLength={10}
              addonBefore="+38"
              placeholder="Номер телефону"
              onChange={e => setPhoneNumber(e.target.value)}
            />
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="filters-item datepicker event">
              <Form.Item
                name="start_date"
                rules={[{ required: true, message: t('Validation.Required') }]}
              >
                <DatePicker
                  disabledDate={currentDate =>
                    currentDate && currentDate <= moment().subtract(1, 'day')
                  }
                  format={shortenFormat}
                  placeholder="З"
                  onChange={onStartChange}
                  onOpenChange={handleStartOpenChange}
                  locale={locale}
                  showNow={false}
                  showToday={false}
                  showTime={false}
                  inputReadOnly
                />
              </Form.Item>
            </div>
            <div className="filters-item datepicker event">
              <Form.Item
                name="end_date"
                rules={[{ required: true, message: t('Validation.Required') }]}
              >
                <DatePicker
                  disabledDate={disabledEndDate}
                  format={shortenFormat}
                  placeholder="До"
                  onChange={onEndChange}
                  open={endOpen}
                  onOpenChange={handleEndOpenChange}
                  locale={locale}
                  showNow={false}
                  showToday={false}
                  showTime={false}
                  inputReadOnly
                />
              </Form.Item>
            </div>
          </div>
          <Form.Item
            name="comment"
            rules={[
              {
                required: true,
                message: t('Validation.Required'),
              },
            ]}
          >
            <TextArea
              className="textarea"
              placeholder="Хочу святкувати"
              rows={5}
            />
          </Form.Item>
          <Button
            htmlType="submit"
            classNames="btn-mid-pad small-btn btn-green h-40 w-100"
            isLoading={isLoading}
          >
            Відправити
          </Button> */}
      {isVisible ? (
        <ContactUsModal
          onSubmit={onSubmit}
          setVisible={setVisible}
          isLoading={isLoading}
        />
      ) : null}
    </div>
  //)
    ) : (
    <div className="event-page">
      <div className="event-page-images">
        {iframe360 && <div className="iframe-wrapper">{iframe360}</div>}
        {renderMasonry()}
      </div>
      <div className="event-page-right-panel">
        {content({ t, i18nField, i18n })}
        <div style={{ marginTop: 10 }}>
          {hasPlan ? (
            <div>
              <a href={image} target="_blank" rel="noreferrer">
                <Button
                  classNames="btn-mid-pad small-btn btn-green h-40 w-100"
                  buttonId="event_event_planning_button"
                >
                  {t(`Events.${i18nField}.EventPlanning`)}
                </Button>
              </a>
            </div>
          ) : null}
          <div style={{ marginTop: 10 }}>
            <Button
              classNames="btn-green small-btn w-100"
              onClick={() => setVisible(!isVisible)}
              buttonId="event_contact_us_button"
            >
              {t('Events.ContactUs.ContactUsButton')}
            </Button>
          </div>
        </div>
      </div>

      {/* <Form.Item
            name="secondName"
            rules={[
              {
                required: true,
                message: t('Validation.Required'),
              },
            ]}
          >
            <Input placeholder={t('InputPlaceholders.Surname')} />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: t('Validation.Required'),
              },
              {
                type: 'email',
                message: t('Validation.InvalidEmailFormat'),
              },
            ]}
          >
            <Input placeholder={t('InputPlaceholders.Email')} />
          </Form.Item>
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                message: t('Validation.Required'),
              },
              {
                pattern: ukrainianPhoneNumberPattern,
                message: 'Номер телефону так не виглядає',
              },
            ]}
          >
            <Input
              maxLength={10}
              addonBefore="+38"
              placeholder="Номер телефону"
              onChange={e => setPhoneNumber(e.target.value)}
            />
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="filters-item datepicker event">
              <Form.Item
                name="start_date"
                rules={[{ required: true, message: t('Validation.Required') }]}
              >
                <DatePicker
                  disabledDate={currentDate =>
                    currentDate && currentDate <= moment().subtract(1, 'day')
                  }
                  format={shortenFormat}
                  placeholder="З"
                  onChange={onStartChange}
                  onOpenChange={handleStartOpenChange}
                  locale={locale}
                  showNow={false}
                  showToday={false}
                  showTime={false}
                  inputReadOnly
                />
              </Form.Item>
            </div>
            <div className="filters-item datepicker event">
              <Form.Item
                name="end_date"
                rules={[{ required: true, message: t('Validation.Required') }]}
              >
                <DatePicker
                  disabledDate={disabledEndDate}
                  format={shortenFormat}
                  placeholder="До"
                  onChange={onEndChange}
                  open={endOpen}
                  onOpenChange={handleEndOpenChange}
                  locale={locale}
                  showNow={false}
                  showToday={false}
                  showTime={false}
                  inputReadOnly
                />
              </Form.Item>
            </div>
          </div>
          <Form.Item
            name="comment"
            rules={[
              {
                required: true,
                message: t('Validation.Required'),
              },
            ]}
          >
            <TextArea
              className="textarea"
              placeholder="Хочу святкувати"
              rows={5}
            />
          </Form.Item>
          <Button
            htmlType="submit"
            classNames="btn-mid-pad small-btn btn-green h-40 w-100"
            isLoading={isLoading}
          >
            Відправити
          </Button> */}
      {isVisible ? (
        <ContactUsModal
          onSubmit={onSubmit}
          setVisible={setVisible}
          isLoading={isLoading}
        />
      ) : null}
    </div>
    )
}

export default Event
