import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Carousel } from 'react-responsive-carousel'

import useAbstractionMediaQueries from '../../hooks/useAbstractionMediaQueries'

import './CarouselSlider.scss'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

const CarouselSlider = ({
  dotsPosition,
  carouselItems,
  className,
  isRooms,
}) => {
  const { isTablet } = useAbstractionMediaQueries()

  const renderCarouselItems = () =>
    !isRooms
      ? carouselItems.map(item => {
          let src = item

          if (item.img) {
            src = item.img
          }

          return (
            <div key={item} style={{ height: '100%' }}>
              <img
                style={{
                  height: '100%',
                  objectFit: 'cover',
                  maxHeight: '540px',
                  ...item.style,
                }}
                src={src}
                alt={src}
              />
            </div>
          )
        })
      : carouselItems.map(item => (
          <div key={item} style={{ height: '100%' }}>
            <img
              style={{ height: '100%', objectFit: 'cover' }}
              src={item.url}
              alt=""
            />
          </div>
        ))

  return carouselItems ? (
    <Carousel
      showArrows={false}
      showThumbs={false}
      autoPlay={false}
      showStatus={false}
      className={`${className} ${dotsPosition}`}
      showIndicators
    >
      {renderCarouselItems()}
    </Carousel>
  ) : null
}

CarouselSlider.propTypes = {
  dotsPosition: PropTypes.string,
  carouselItems: PropTypes.array.isRequired,
  className: PropTypes.string,
  isRooms: PropTypes.bool,
}

CarouselSlider.defaultProps = {
  dotsPosition: 'up-left',
  className: '',
  isRooms: false,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(CarouselSlider)
