import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import RoomInfo from '../../components/RoomInfo'
import Loader from '../../components/Loader'
import RoomsSwitcher from '../../components/RoomsSwitcher'

import useSystemLanguage from '../../hooks/useSystemLanguage'
import {
  onGetRooms,
  // onClearRooms,
  onSetCurrentRoom,
} from '../../redux/rooms/rooms'

import roomsTitleUkr from '../../static/images/rooms/roomsTitleUkr.svg'
import roomsTitleEn from '../../static/images/rooms/roomsTitleEng.svg'
import roomsTitleRus from '../../static/images/rooms/roomsTitleRus.svg'

import './Rooms.scss'

const Rooms = ({
  onGetRooms,
  // onClearRooms,
  onSetCurrentRoom,
  areRoomsLoading,
  rooms,
}) => {
  const { t } = useTranslation()
  const { systemLanguage } = useSystemLanguage()

  const renderRoomsList = () => {
    return rooms.map(room => (
      <RoomInfo
        room={room}
        isDetails={false}
        onSetCurrentRoom={onSetCurrentRoom}
        key={room.code}
      />
    ))
  }

  const getRoomsTitle = () => {
    switch (systemLanguage) {
      case 'en':
        return <img src={roomsTitleEn} alt="" />
      case 'ru':
        return <img src={roomsTitleRus} alt="" />
      default:
        return <img src={roomsTitleUkr} alt="" />
    }
  }

  useEffect(() => {
    onGetRooms(systemLanguage)
    // return () => onClearRooms()
  }, [systemLanguage])

  return (
    <div className={`rooms-wrapper${areRoomsLoading ? ' loading' : ''}`}>
      {areRoomsLoading ? (
        <div className="loading-wrapper">
          <Loader size={60} />
        </div>
      ) : (
        <div className="rooms">
          {rooms?.length ? (
            <RoomsSwitcher rooms={rooms} />
          ) : (
            <div className="no-data-wrapper">
              <h1>{t('Rooms.NoRoomsFound')}</h1>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

Rooms.propTypes = {
  rooms: PropTypes.array,
  areRoomsLoading: PropTypes.bool,
  onGetRooms: PropTypes.func.isRequired,
  onSetCurrentRoom: PropTypes.func.isRequired,
}

Rooms.defaultProps = {
  rooms: [],
  areRoomsLoading: false,
}

const mapStateToProps = state => ({
  areRoomsLoading: state.rooms.areRoomsLoading,
  rooms: state.rooms.rooms,
})

const mapDispatchToProps = dispatch => ({
  onGetRooms: action => dispatch(onGetRooms(action)),
  // onClearRooms: () => dispatch(onClearRooms()),
  onSetCurrentRoom: action => dispatch(onSetCurrentRoom(action)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Rooms)
