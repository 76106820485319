import React from 'react'
import { CloseOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Button from '../../components/Button'

import { onAddItem, onGetItems } from '../../redux/cart/cart'

import useSystemLanguage from '../../hooks/useSystemLanguage'

import './DialogModal.scss'

const DialogModal = ({
  onHide,
  currentGoodItem,
  setCurrentGoodItem,
  isAuthenticated,
  isProcessing,
  onAddItem,
}) => {
  const body = document.getElementById('body')
  const {
    image: { url },
    description_eng,
    description_rus,
    description_ukr,
    title_eng,
    title_rus,
    title_ukr,
    _id,
    payable,
    price,
  } = currentGoodItem
  const { t } = useTranslation()
  const { systemLanguage } = useSystemLanguage()

  const getDescription = () => {
    switch (systemLanguage) {
      case 'en':
        return description_eng
      case 'ru':
        return description_rus
      default:
        return description_ukr
    }
  }

  const getTitle = () => {
    switch (systemLanguage) {
      case 'en':
        return title_eng
      case 'ru':
        return title_rus
      default:
        return title_ukr
    }
  }

  const description = getDescription()
  const title = getTitle()

  const hideModal = () => {
    body.style.overflow = 'visible'
    onHide()
    setCurrentGoodItem(null)
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="header">
          <span className="title">{title}</span>
          <CloseOutlined onClick={hideModal} className="close" />
        </div>
        <div className="inner-content">
          <div className="image">
            <img src={`https://api.shelest.ua/strapi${url}`} alt="Title" />
          </div>
          <div className="modal-description-wrapper">
            <div
              className="description"
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
            {price > 0 ? (
              <p className="price">
                {t('DialogModal.Price')} {price} {t('DialogModal.HRN')}
              </p>
            ) : null}
          </div>
        </div>
        <div className="footer">
          <Button
            isDisabled={isProcessing}
            onClick={hideModal}
            classNames="btn-red small-btn"
            buttonId="goods_dialog_modal_cancel_button"
          >
            {t('Booking.Cart.Placeholders.Cancel')}
          </Button>
          {payable ? (
            <Button
              isDisabled={isProcessing}
              isLoading={isProcessing}
              onClick={() =>
                payable &&
                onAddItem({
                  product_id: _id,
                  isAuthenticated,
                  hideModal,
                  productType: 'goods',
                })
              }
              classNames="btn-green small-btn"
              buttonId="goods_dialog_modal_add_to_cart_button"
            >
              {t('Booking.Cart.Placeholders.AddToCart')}
            </Button>
          ) : (
            <a
              className="btn btn-green small-btn"
              href="tel:+38 (067) 11 000 67)"
            >
              {t('DialogModal.ByPhone')}
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  isProcessing: state.cart.isProcessing,
})

const mapDispatchToProps = dispatch => ({
  onAddItem: action => dispatch(onAddItem(action)),
  onGetItems: () => dispatch(onGetItems()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DialogModal)
