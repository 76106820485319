import NewYear1 from '../../../../static/images/special-events/new-year/new10.jpeg' // v
import NewYear2 from '../../../../static/images/special-events/new-year/new11.jpeg' // v
import NewYear3 from '../../../../static/images/special-events/new-year/new20.jpg' // v
// import NewYear4 from '../../../../static/images/special-events/new-year/new21.jpg'
// import NewYear5 from '../../../../static/images/special-events/new-year/new22.jpeg' // v
import NewYear6 from '../../../../static/images/special-events/new-year/new23.jpeg' // v
import NewYear7 from '../../../../static/images/special-events/new-year/new24.jpeg' // v
import NewYear8 from '../../../../static/images/special-events/new-year/new25.jpeg' // v

// import NewYear1 from '../../../../static/images/special-events/new-year/newYear1.jpeg'
// import NewYear2 from '../../../../static/images/special-events/new-year/newYear2.jpeg'
// import NewYear3 from '../../../../static/images/special-events/new-year/newYear3.jpeg'
// import NewYear4 from '../../../../static/images/special-events/new-year/newYear4.jpeg'
// import NewYear5 from '../../../../static/images/special-events/new-year/newYear5.jpeg'
// import NewYear6 from '../../../../static/images/special-events/new-year/newYear6.jpeg'

export default {
  masonryConfig: {
    parentConfig: {
      rows: 4,
      cols: 3,
      maxHeight: 1500,
    },
    images: [
      {
        image: NewYear1,
        classes: 'row-span-2 col-span-1 row-start-1 col-start-1',
      },
      {
        image: NewYear2,
        classes: 'row-span-2 col-span-1  row-start-1 col-start-2',
      },
      {
        image: NewYear3,
        classes: 'row-span-2 col-span-1 row-start-1 col-start-3',
      },
      {
        image: NewYear6,
        classes: 'row-span-2 col-span-1 row-start-3 col-start-1',
      },
      {
        image: NewYear7,
        classes: 'row-span-2 col-span-1 row-start-3 col-start-2',
      },
      {
        image: NewYear8,
        classes: 'row-span-2 col-span-1 row-start-3 col-start-3',
      },
    ],
  },
}
