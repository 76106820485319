import React from 'react'
import { useTranslation } from 'react-i18next'

const NotificationTitle = ({ title }) => {
  const { t } = useTranslation()

  return <p>{t(title)}</p>
}

export default NotificationTitle
