import React from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'

import './NotFound.scss'

const NotFound = () => {
  const { pathname } = useLocation()

  const notFoundClassName = `${
    pathname.includes('/booking/')
      ? 'not-found-booking-wrapper'
      : 'notfound-wrapper'
  }`

  return (
    <div className={notFoundClassName}>
      <h1>not found</h1>
    </div>
  )
}

export default NotFound
