import React from 'react'
import { useTranslation } from 'react-i18next'

import './ShelestHome.scss'

const Simplicity = () => {
  const { t } = useTranslation()

  return (
    <div className="simplicity-wrapper">
      <div className="simplicity-title">
        <h1 className="left"> {t('ShelestHome.Simplicity.Title')}</h1>
        <h1>{t('ShelestHome.Simplicity.TitleSecond')}</h1>
        <h1 className="right">{t('ShelestHome.Simplicity.TitleThird')}</h1>
      </div>
      <div className="simplicity-description">
        <p>{t('ShelestHome.Simplicity.Description1')}</p>
        <p>{t('ShelestHome.Simplicity.Description2')}</p>
      </div>
    </div>
  )
}

export default Simplicity
