import HttpService from './http'

const baseUrl = process.env.REACT_APP_BACKEND_API

class CartService {
  constructor() {
    this.request = new HttpService(baseUrl)
  }
/*
  payCheck = (
    { cartId, isAdmin, message, phone, firstName, secondName },
    token
  ) => {
    if (isAdmin) {
      return this.request.post(
        '/admin/reservation',
        JSON.stringify({
          first_name: firstName,
          second_name: secondName,
          phone,
          comment: message,
          // utmSource: localStorage.getItem('utm_source'),
          // utmMedium: localStorage.getItem('utm_medium'),
          // utmCampaign: localStorage.getItem('utm_campaign'),
          // utmContent: localStorage.getItem('utm_content'),
        }),
        false,
        true
      )
    }

    return this.request.post(
      '/payment/form',
      JSON.stringify({
        comment: message,
        token,
      }),
      false,
      true
    )
  }

  getItems = () => this.request.get('/strapi/crossales')

  addItem = (payload, token) => {
    return this.request.controllablePut(
      '/cart',
      JSON.stringify({ ...payload, type: payload.type || 'crossales' }),
      token
    )
  }

  // addSpa = (payload, token) => {
  //   return this.request.controllablePut(
  //     '/cart',
  //     JSON.stringify({ ...payload, type: 'spa' }),
  //     token
  //   )
  // }

  // removeSpa = (payload, token) => {
  //   return this.request.controllableDelete(
  //     '/cart',
  //     JSON.stringify({ ...payload, type: 'spa' }),
  //     token
  //   )
  // }

  addSpa = (payload, token) => {
    return this.request.controllablePut(
      '/cart',
      JSON.stringify({ ...payload, type: 'hotel_addition' }),
      token
    )
  }

  removeSpa = (payload, token) => {
    return this.request.controllableDelete(
      '/cart',
      JSON.stringify({ ...payload, type: 'hotel_addition' }),
      token
    )
  }

  getCart = payload => {
    return this.request.controllableGet('/cart', payload)
  }

  removeItem = (payload, token) => {
    return this.request.controllableDelete(
      '/cart',
      JSON.stringify({ ...payload, type: payload.type || 'crossales' }),
      token
    )
  }

  getBookingHistory = ({ token, isAdmin }) => {
    if (isAdmin) {
      return this.request.controllableGet('/admin/orders', { token })
    }
    return this.request.controllableGet('/order/all', { token })
  }

  verifyReservation = payload => {
    console.log('payload: ', payload)
    // return this.request.
  }
  */
}

export default new CartService()
