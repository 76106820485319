import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import HeroImage from '../../components/HeroImage/HeroImage'
import RelaxBG from '../../static/images/relax/relax-bg.jpg'
import { relaxTypes } from './utils'

import './RelaxStyles.scss'

const Relax = () => {
  const { t } = useTranslation()

  return (
    <div className="relax-wrapper">
      <HeroImage heroText={t('Relax.HeroTitle')} backgroundImage={RelaxBG} />
      <ul className="relax-types">
        {relaxTypes.map(relaxType => (
          <a href={`/relax${relaxType.route}`} key={relaxType.i18nKey}>
            <li className="relax-type">
              <div className="relax-type__img-wrapper">
                <img className="relax-type__img" src={relaxType.img} />
              </div>
              <div className="relax-type__text">
                <h2 className="relax-type__title">
                  {t(`Relax.${relaxType.i18nKey}.Title`)}
                </h2>
                <p className="relax-type__description">
                  {t(`Relax.${relaxType.i18nKey}.Description`)}
                </p>
              </div>
            </li>
          </a>
        ))}
      </ul>
    </div>
  )
}

Relax.defaultProps = {
  heroText: '',
}

Relax.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  heroText: PropTypes.string,
}

export default Relax
