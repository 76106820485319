import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Divider, Space } from 'antd'

import HeroImage from '../../components/HeroImage/HeroImage'

import { relaxTypes } from './utils'

const RelaxType = () => {
  const { id } = useParams()
  const { t } = useTranslation()

  const targetRelaxType = relaxTypes.find(relaxType => relaxType.key === id)

  if (!targetRelaxType) {
    return 'did not find anything'
  }

  const { features, i18nKey, heroImg } = targetRelaxType

  return (
    <Space size={20} direction="vertical">
      <HeroImage
        backgroundImage={heroImg}
        heroText={t(`Relax.${i18nKey}.Title`)}
      />
      <div className="relax-type-wrapper">
        <ul className="relax-type-features">
          {features.map(feature => (
            <li key={feature} className="relax-type-feature">
              <h2>{t(`Relax.${i18nKey}.Features.${feature}.Title`)}</h2>
              <p>{t(`Relax.${i18nKey}.Features.${feature}.Description`)}</p>
              <Divider
                style={{
                  margin: 0,
                  padding: 0,
                  background: 'black',
                }}
              />
              <p className="duration">
                {t(`Relax.${i18nKey}.Features.${feature}.Duration`)}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </Space>
  )
}

export default RelaxType
