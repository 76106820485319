import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isNil, isEmpty } from 'ramda'
import { useTranslation } from 'react-i18next'
import { DatePicker, Select, Form, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import { CSSTransition } from 'react-transition-group'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
//import { useHistory } from 'react-router-dom'
import { useLocation } from "react-router-dom";
//import { useMatch } from "react-router-dom";
//import { withRouter } from 'react-router';
import Button from '../../../components/Button'
import BookingHotelRoom from '../../../components/BookingHotelRoom'
import Loader from '../../../components/Loader'
import queryString from 'query-string';
import {
  onSearchHotelAvailability,
  onClearHotelForm,
} from '../../../redux/booking/hotel/hotel'
import { onAddItem, onGetItems } from '../../../redux/cart/cart'
import { onSetCurrentRoom } from '../../../redux/rooms/rooms'

import {
  dateFormat,
  validateMessages,
  searchStatuses,
} from '../../../utils/constants'
import { getLocale } from '../../../utils/helpers'
import useSystemLanguage from '../../../hooks/useSystemLanguage'
import useAbstractionMediaQueries from '../../../hooks/useAbstractionMediaQueries'

import './BookingHotel.scss'

import 'moment/locale/uk'
import 'moment/locale/ru'
import 'moment/locale/en-gb'

const { RangePicker } = DatePicker
const { Option } = Select

const BookingHotel = ({
  isHotelAvailabilityLoading,
  onSearchHotelAvailability,
  onClearHotelForm,
  searchStatus,
  availableRooms,
  isAuthenticated,
  onAddItem,
  isProcessing,
  onSetCurrentRoom,
  recentlyAddedHotel,
  location
}) => {


  const { isMobile, isTablet } = useAbstractionMediaQueries()
  const sendDataToGTM = useGTMDispatch()

  moment.updateLocale('uk', {
    weekdays: [
      'Неділя',
      'Понеділок',
      'Вівторок',
      'Середа',
      'Четвер',
      `П'ятниця`,
      'Субота',
    ],
  })
  moment.updateLocale('ru', {
    weekdays: [
      'Воскресенье',
      'Понедельник',
      'Вторник',
      'Среда',
      'Четверг',
      'Пятница',
      'Суббота',
    ],
  })

  const { systemLanguage } = useSystemLanguage()
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useState(null)
  const [adultsCount, setAdultsCount] = useState(1)
  const [teensOptionsCount, setTeensOptionsCount] = useState(6)
  const [teensCount, setTeensCount] = useState(0)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [endOpen, setEndOpen] = useState(false)
  const formRef = useRef()

  const onFinish = values => {
    if (isHotelAvailabilityLoading) return null
    const format = 'YYYY-MM-DD'

    sendDataToGTM({ event: 'formSent', eventCategory: 'Book', ...values })

    if (isTablet) {
      if (!values.startDate || !values.endDate) {
        return null
      }

      onSearchHotelAvailability({
        ...values,
        startDate: startDate.format(format),
        endDate: endDate.format(format),
        language: systemLanguage,
        isAuthenticated,
      })
      setSearchParams({
        startDate: startDate,
        endDate: endDate,
      })
    } else {
      if (!values.period || !values.period.length) {
        return null
      }

      const { period } = values
      const startDate = period[0].format(format)
      const endDate = period[1].format(format)

      onSearchHotelAvailability({
        ...values,
        startDate,
        endDate,
        language: systemLanguage,
        isAuthenticated,
      })
      setSearchParams({ startDate: period[0], endDate: period[1] })
    }
  }

  const renderSearchStatus = () => {
    return t(`Booking.Hotel.SearchStatus.${searchStatuses[searchStatus]}`)
  }

  const onRangeChange = dates => {
    if (isNil(dates)) {
      onClearHotelForm()
      setSearchParams(null)
    }
  }

  const renderAvailableRooms = () => {
    return (
      availableRooms?.length &&
      availableRooms.map((availableRoom, index) => (
        <BookingHotelRoom
          key={index}
          roomInfo={availableRoom}
          index={index}
          searchParams={searchParams}
          systemLanguage={systemLanguage}
          onAddItem={onAddItem}
          isProcessing={isProcessing}
          isAuthenticated={isAuthenticated}
          onSetCurrentRoom={onSetCurrentRoom}
          recentlyAddedHotel={recentlyAddedHotel}
        />
      ))
    )
  }

  const getPlaceholderText = lang => {
    switch (lang) {
      case 'ukr':
        return ['Заїзд', 'Виїзд']
      case 'en':
        return ['In', 'Out']
      case 'ru':
        return ['Заехать', 'Выехать']
    }
  }

  const disabledEndDate = endValue => {
    if (!endValue || !startDate) {
      return false
    }
    return endValue.valueOf() <= startDate.valueOf() + 3600
  }

  const onStartChange = value => {
    setStartDate(value)
    setEndDate(null)
  }

  const onEndChange = value => {
    setEndDate(value)
  }

  const handleStartOpenChange = open => {
    if (!open) {
      setEndOpen(true)
    }
  }

  const handleEndOpenChange = open => {
    setEndOpen(open)
  }

  const onAdultsCountChange = value => {
    setAdultsCount(value)
    setTeensOptionsCount(7 - Number(value))
  }

  const onTeensCountChange = value => {
    setTeensCount(value)
  }

  const locale = getLocale(systemLanguage)
  const placeholderText = getPlaceholderText(systemLanguage)

  useEffect(() => {
    return () => onClearHotelForm()
  }, [])

  useEffect(() => {
    if (formRef && formRef.current) {
      onFinish(formRef.current.getFieldsValue())
    }
  }, [systemLanguage])

  useEffect(() => {
    var dt = new Date().getTime();
  const script = document.createElement('script');

  script.src = "https://shelest.ua/servioreservation.js?t="+dt;
  script.async = false;

  document.body.appendChild(script);


console.log("onSetCurrentRoom===>",location)
//const match = useMatch('/booking/hotel/:lastPart')
//const value = match?.props.lastPart;
//let value = location.pathname.slice(location.pathname.lastIndexOf("/") , location.pathname.length) ;
const value = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
if (!!value) {
    console.log("value===>",value)
}

  window.PB_LOCAL_CONFIG = {
        allow_header: false,
        allow_footer: false,
        allow_callback_panel: false,
        allow_carousel: true,
        allow_steps: true,
        search_form: {
            type: 'compact',
            redirect: false,
            // byRoom: '9b3dc072-0051-4b1e-bba3-cba83e9d9a6a'
        },
        LANGUAGE: systemLanguage ,
        lngMapper:[{code: 'uk', input: 'ukr'}],
      }

  return () => {
    document.body.removeChild(script);
  }
}, []);
/*
  const history = useLocation();
  const ap_id = history.location;
  //let params = queryString.parse(this.props.location.search)
  //const { location } = this.props;
  //console.log('history=======>',onSetCurrentRoom);
  console.log("searchParams",this)
    console.log('localStorage=======>',localStorage.getItem('room_code'));
    console.log('history=======>',history.location);
    //console.log('this.props=======>',this.props);
    */
  //console.log("LANG===>",systemLanguage);
  return (
 
    <>

    <h1>{}</h1>
     
     <div id="pb_remote_app" class="r-module min-vh-100"></div>

    </>
  )
}

const mapStateToProps = state => ({
  isHotelAvailabilityLoading: state.hotel.isHotelAvailabilityLoading,
  searchStatus: state.hotel.searchStatus,
  availableRooms: state.hotel.availableRooms,
  isAuthenticated: state.auth.isAuthenticated,
  isProcessing: state.cart.isProcessing,
  recentlyAddedHotel: state.cart.recentlyAddedHotel,
})

const mapDispatchToProps = dispatch => ({
  onSearchHotelAvailability: action =>
    dispatch(onSearchHotelAvailability(action)),
  onClearHotelForm: () => dispatch(onClearHotelForm()),
  onAddItem: action =>
    dispatch(onAddItem({ ...action, action_detail: 'new', type: 'hotel' })),
  onSetCurrentRoom: action => dispatch(onSetCurrentRoom(action)),
})

BookingHotel.propTypes = {
  onSearchHotelAvailability: PropTypes.func.isRequired,
  isHotelAvailabilityLoading: PropTypes.bool.isRequired,
  onClearHotelForm: PropTypes.func.isRequired,
  searchStatus: PropTypes.string.isRequired,
  availableRooms: PropTypes.array,
  isAuthenticated: PropTypes.bool.isRequired,
}

BookingHotel.defaultProps = {
  availableRooms: null,
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingHotel)
