export const appVersion = '0.10.2'

export const languages = {
  // ru: 'ru',
  en: 'en',
  ukr: 'ukr',
}

export const sliderTypeClasses = {
  staticPage: 'static-page-slider',
}

export const staticPages = {
  restaurant: 'restaurant',
  pool: 'pool',
  covid: 'covid',
  contacts: 'contacts',
}

export const bookingNavigationItems = [
  { route: 'hotel', key: 'hotel', translation: 'Hotel' },
  // { route: 'restaurant', key: 'restaurant', translation: 'Restaurant' },
  // { route: 'pool', key: 'pool', translation: 'Pool' },
  // { route: 'transfer', key: 'transfer', translation: 'Transfer' },
]

export const loginNavigationButtons = [
  { route: 'login', key: 'login', translation: 'Login' },
  { route: 'register', key: 'register', translation: 'Register' },
]

export const dateFormat = 'dddd, DD MMMM, YYYY'
export const shortenFormat = 'DD MMMM, YYYY'

export const validateMessages = {
  required: 'This field is required',
}

export const notificationTypes = {
  errorNotification: 'error',
  successNotification: 'success',
}

export const titleTypes = {
  signUpError: 'Notification.Title.SignUpError',
  signInError: 'Notification.Title.SignInError',
  signUpSuccess: 'Notification.Title.SignUpSuccess',
  signInSuccess: 'Notification.Title.SignInSuccess',
  somethingWentWrong: 'Notification.Title.SomethingWentWrong',
}

export const descriptionTypes = {
  errorDescription: 'error',
}

export const ukrainianPhoneNumberPattern = /^0[1-9]\d{8}$/

export const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/

export const hourAndMinutesFormat = 'HH:mm'

export const mediaQueryConstants = {
  max475px: '(max-width: 475px)',
  max768px: '(max-width: 768px)',
  max1024px: '(max-width: 1024px)',
  biggest: '(max-width: 1250px)',
}

export const searchStatuses = {
  beginning: 'Begin',
  searching: 'Searching',
  emptyResult: 'EmptyResult',
  failure: 'Failure',
}

export const ukrCurrencyTranslations = {
  UAH: 'грн',
  RUR: 'рублі',
  USD: 'долари',
}

export const enCurrencyTranslations = {
  UAH: 'UAH',
  RUR: 'RUB',
  USD: 'USD',
}

export const ukrDigitVersions = {
  pluralFirst: 'днів',
  singular: 'день',
  pluralSecond: 'дні',
}

export const enDigitVersions = {
  plural: 'days',
  singular: 'day',
}

export const ruDigitVersions = {
  pluralFirst: 'дней',
  singular: 'день',
  pluralSecond: 'дня',
}
