import React, { useEffect, useState } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { AnimatedSwitch, spring } from 'react-router-transition'

import Goods from '../../components/Goods'
import BookingNavigation from '../../components/BookingNavigation'

import { bookingRoutes } from '../../router'

import wave from '../../static/icons/wave_identic.svg'

const BookingLayout = () => {
  const [whetherToShowGoodsSection, setGoodsSectionVisibility] = useState(true)
  const [identicImage, setIdenticImage] = useState({})
  const location = useLocation()

  const getIdentic = () => {
    switch (location.pathname) {
      case '/booking/hotel':
        return setIdenticImage({})
      case '/booking/pool':
        return setIdenticImage({ url: wave, name: 'wave' })
      default:
        return setIdenticImage({})
    }
  }

  function mapStyles(styles) {
    return {
      opacity: styles.opacity,
      transform: `translateX(${styles.offset}px)`,
    }
  }

  function glide(val) {
    return spring(val, {
      stiffness: 174,
      damping: 19,
    })
  }

  const pageTransitions = {
    atEnter: {
      offset: 200,
      opacity: 0,
    },
    atLeave: {
      offset: glide(-100),
      opacity: glide(0),
    },
    atActive: {
      offset: glide(0),
      opacity: glide(1),
    },
  }

  useEffect(() => {
    getIdentic()
    if (location.pathname.includes('cart')) {
      setGoodsSectionVisibility(false)
    } else {
      setGoodsSectionVisibility(true)
    }
  }, [location])

  return (
    <>
      {/* {identicImage ? (
        <img
          className={`booking-identic-image ${identicImage.name}`}
          src={identicImage.url}
        />
      ) : null} */}
      <BookingNavigation />
      <div className="booking-page-wrapper">
        {/* <AnimatedSwitch
          {...pageTransitions}
          mapStyles={mapStyles}
          className="route-wrapper"
        > */}
        <Switch>
          {bookingRoutes.map(route => (
            <Route key={route.path} {...route} />
          ))}
        </Switch>
        {/* </AnimatedSwitch> */}
        {/* {whetherToShowGoodsSection ? <Goods /> : null} */}
      </div>
    </>
  )
}

export default BookingLayout
