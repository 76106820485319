import React, { lazy } from 'react'
import { Rooms, Events, NotFound, DoNothing, ShelestHome } from './pages'

import { staticPages } from './utils/constants'
import { staticPagesProps } from './utils/staticPages'

import Home from './pages/home/Home'
import StaticPage from './components/StaticPage/StaticPage'
import Login from './pages/login/Login'
import BookingHotel from './pages/booking/hotel/BookingHotel'
import BookingPool from './pages/booking/pool/BookingPool'
import BookingRestaurant from './pages/booking/restaurant/BookingRestaurant'
import BookingTransfer from './pages/booking/transfer/BookingTransfer'
import Cart from './pages/booking/cart/Cart'
import RoomInfo from './components/RoomInfo'
import BookingHistory from './pages/booking/BookingHistory'
import Event from './pages/events/event'
import ToInvest from './pages/to-invest'
import SpecialEvent from './pages/special-event'
import Team from './pages/team/Team'
import Relax from './pages/relax/Relax'
import RelaxType from './pages/relax/RelaxType'

// const Home = lazy(() => import('./pages/home/Home'))
// const StaticPage = lazy(() => import('./components/StaticPage/StaticPage'))
// const Login = lazy(() => import('./pages/login/Login'))
// const BookingHotel = lazy(() => import('./pages/booking/hotel/BookingHotel'))
// const BookingPool = lazy(() => import('./pages/booking/pool/BookingPool'))
// const BookingRestaurant = lazy(() =>
//   import('./pages/booking/restaurant/BookingRestaurant'),
// )
// const BookingTransfer = lazy(() =>
//   import('./pages/booking/transfer/BookingTransfer'),
// )
// const Cart = lazy(() => import('./pages/booking/cart/Cart'))

const getProps = route => staticPagesProps[route]

const { restaurant, pool, covid, contacts } = staticPages

const protectedRoutes = [
  {
    whatToCheck: 'currentRoom',
    whereToRedirect: 'rooms',
    exact: true,
    path: '/room/:id',
    component: RoomInfo,
  },
  {
    whatToCheck: 'queryParams-token',
    whereToRedirect: '/',
    path: '/recover-password',
    component: Login,
  },
  {
    whatToCheck: 'queryParams-ubn-ubsign',
    whereToRedirect: '/',
    path: '/verify-reservation',
    component: Login,
  },
]

const privateRoutes = [
  // {
  //   exact: true,
  //   path: '/profile',
  //   component: BookingHotel,
  // },
  {
    path: '/profile/booking-history',
    component: BookingHistory,
  },
]

const bookingRoutes = [
  {
    exact: true,
    path: '/booking/hotel',
    component: BookingHotel,
    shouldGoodsSectionBeVisible: true,
    path: '/booking/hotel/:id',
  },
  {
    exact: true,
    path: '/booking/hotel',
    component: BookingHotel,
    shouldGoodsSectionBeVisible: true,
  },
  // {
  //   exact: true,
  //   path: '/booking/pool',
  //   component: BookingPool,
  //   shouldGoodsSectionBeVisible: true,
  // },
  // {
  //   exact: true,
  //   path: '/booking/restaurant',
  //   component: BookingRestaurant,
  //   shouldGoodsSectionBeVisible: true,
  // },
  // {
  //   exact: true,
  //   path: '/booking/transfer',
  //   component: BookingTransfer,
  //   shouldGoodsSectionBeVisible: true,
  // },
  {
    exact: true,
    path: '/booking/cart',
    component: Cart,
    shouldGoodsSectionBeVisible: false,
  },
  {
    path: '*',
    component: NotFound,
  },
]

const routes = [
  {
    exact: true,
    path: '/events/:eventName',
    component: Event,
  },
  {
    exact: true,
    path: '/events',
    component: Events,
  },
  {
    exact: true,
    path: '/do-nothing',
    component: DoNothing,
  },
  {
    exact: true,
    path: '/shelest-home',
    component: ShelestHome,
  },
  {
    exact: true,
    path: '/team',
    component: Team,
  },
  // {
  //   exact: true,
  //   path: '/new-year',
  //   render: props => <SpecialEvent event="new_year" />,
  // },
  {
    exact: true,
    path: '/to-invest',
    render: props => <ToInvest />,
  },
  {
    exact: true,
    path: '/pool',
    render: props => <StaticPage {...props} {...getProps(pool)} />,
  },
  {
    exact: true,
    path: '/restaurant',
    render: props => <StaticPage {...props} {...getProps(restaurant)} />,
  },
  {
    exact: true,
    path: '/covid-19',
    render: props => <StaticPage {...props} {...getProps(covid)} />,
  },
  {
    exact: true,
    path: '/contacts',
    render: props => <StaticPage {...props} {...getProps(contacts)} />,
  },
  {
    exact: true,
    path: '/relax',
    component: Relax,
  },
  {
    exact: true,
    path: '/relax/:id',
    component: RelaxType,
  },
  {
    exact: true,
    path: '/rooms',
    component: Rooms,
  },
  {
    exact: true,
    path: '/login',
    component: Login,
  },
  {
    path: '/additional-information',
    component: Login,
  },
  {
    exact: true,
    path: '/register',
    component: Login,
  },
  { exact: true, path: '/forgot-password', component: Login },
  {
    exact: true,
    path: '/',
    component: Home,
  },
  {
    path: '*',
    component: NotFound,
  },
]

export { routes, bookingRoutes, protectedRoutes, privateRoutes }
