import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { uid } from 'react-uid'
import { Carousel } from 'react-responsive-carousel'

import Button from '../../components/Button'
import HerHim from '../../components/HerHim'

import {
  renderCurrency,
  renderCountOfDays,
  getLocale,
  normalizeIdString,
} from '../../utils/helpers'
import useAbstractionMediaQueries from '../../hooks/useAbstractionMediaQueries'

import i18n from '../../i18n/i18n'

import './BookingHotelRoom.scss'

import living_area from '../../static/icons/icon_gostinnaya.svg'
import fireplace from '../../static/icons/icon_kamin.svg'
import no_smoking from '../../static/icons/icon_nosmoking.svg'
import bathroom from '../../static/icons/icon_sanuzel.svg'
import two_bedrooms from '../../static/icons/icon_spalnya.svg'
import terrace from '../../static/icons/icon_terrasa.svg'
import bathtub from '../../static/icons/icon_vanna.svg'
import Amenities from '../Amenities/Amenities'

const Icons = (
  <>
    <li key={uid(two_bedrooms)}>
      <img className="option-icon" src={two_bedrooms} alt="" />
    </li>
    <li key={uid(living_area)}>
      <img className="option-icon" src={living_area} alt="" />
    </li>
    <li key={uid(fireplace)}>
      <img className="option-icon" src={fireplace} alt="" />
    </li>
    <li key={uid(terrace)}>
      <img className="option-icon" src={terrace} alt="" />
    </li>
    <li key={uid(no_smoking)}>
      <img className="option-icon" src={no_smoking} alt="" />
    </li>
    <li key={uid(bathtub)}>
      <img className="option-icon" src={bathtub} alt="" />
    </li>
    <li key={uid(bathroom)}>
      <img className="option-icon" src={bathroom} alt="" />
    </li>
  </>
)

const BookingHotelRoom = ({
  roomInfo,
  recentlyAddedHotel,
  searchParams,
  index,
  systemLanguage,
  onAddItem,
  isProcessing,
  isAuthenticated,
  onSetCurrentRoom,
}) => {
  const { t } = useTranslation()

  const additionalInfoTexts = {
    'Classic Pool/Forest View Room': (
      <>
        {t('Rooms.CheckInInfo')}
        <br />
        {t('Rooms.BreakfastInfo')}
        {/* <br />
        {t('Rooms.BreakfastDiscount')} */}
        <br />
        {t('Rooms.BreakfastTimeframe')}
        <br />
        {t('Rooms.NoDogsFriendly')}
      </>
    ),
    'Classic Forest View With Terrace/Balcony': (
      <>
        {t('Rooms.CheckInInfo')}
        <br />
        {t('Rooms.BreakfastInfo')}
        {/* <br />
        {t('Rooms.BreakfastDiscount')} */}
        <br />
        {t('Rooms.BreakfastTimeframe')}
        <br />
        {t('Rooms.DogsFriendly')}
      </>
    ),
    'Premium Forest View With Terrace/Balcony': (
      <>
        {t('Rooms.CheckInInfo')}
        <br />
        {t('Rooms.BreakfastInfo')}
        {/* <br />
        {t('Rooms.BreakfastDiscount')} */}
        <br />
        {t('Rooms.BreakfastTimeframe')}
        <br />
        {t('Rooms.NoDogsFriendly')}
      </>
    ),
    'Family Forest View Room': (
      <>
        {t('Rooms.CheckInInfo')}
        <br />
        {t('Rooms.BreakfastInfo')}
        {/* <br />
        {t('Rooms.BreakfastDiscount')} */}
        <br />
        {t('Rooms.BreakfastTimeframe')}
        <br />
        {t('Rooms.DogsFriendly')}
      </>
    ),
    'Forest View Suite': (
      <>
        {t('Rooms.CheckInInfo')}
        <br />
        {t('Rooms.BreakfastInfo')}
        {/* <br />
        {t('Rooms.BreakfastDiscount')} */}
        <br />
        {t('Rooms.BreakfastTimeframe')}
        <br />
        {t('Rooms.NoDogsFriendly')}
      </>
    ),
    'Family House': (
      <>
        {t('Rooms.CheckInInfo')}
        <br />
        {t('Rooms.BreakfastInfo')}
        {/* <br />
        {t('Rooms.BreakfastDiscount')} */}
        <br />
        {t('Rooms.BreakfastTimeframe')}
        <br />
        {t('Rooms.DogsFriendlyFree')}
      </>
    ),
  }

  const { isMobile } = useAbstractionMediaQueries()
  const history = useHistory()
  const {
    lang: { locale },
  } = getLocale()
  const {
    static: staticInfo,
    price: { total, currency },
  } = roomInfo
  const { images, name, description, code, amenities } = staticInfo
  // const mainImage = images[0].url

  let { startDate, endDate } = searchParams

  const fullDays = endDate.diff(startDate, 'days')

  startDate = startDate.locale(locale)
  startDate = startDate.format('DD.MM')

  endDate = endDate.locale(locale)
  endDate = endDate.format('DD.MM')

  const navigateToRoomDetails = () => {
    onSetCurrentRoom(roomInfo)
    history.push(`/room/${code}`)
  }

  const isRecentlyAdded = recentlyAddedHotel === code

  return (
    <div className="hotel-room-wrapper">
      <div className="hotel-room">
        <div className="hotel-room-image">
          <Carousel
            showArrows={false}
            showThumbs={false}
            autoPlay={false}
            showStatus={false}
            showIndicators
            className="bottom-right"
          >
            {images.map((item, i) => (
              <div style={{ height: '100%' }} key={i}>
                <img
                  style={{ height: '100%', objectFit: 'cover' }}
                  src={item.url}
                  alt=""
                />
              </div>
            ))}
          </Carousel>
        </div>
        <div className="hotel-room-description">
          <div className="hotel-room-description-left">
            <h3 className="heading">
              {name}
              {name === 'Котедж Сімейний' && (
                <Tooltip
                  placement="right"
                  title={t('Booking.Hotel.Filters.CottageTooltip')}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: 32,
                      width: 32,
                    }}
                  >
                    <QuestionCircleOutlined style={{ fontSize: 18 }} />
                  </div>
                </Tooltip>
              )}
            </h3>
            <p className="paragraph">
              {description}
              <br />
              <br />
              <b>{t('Rooms.SPATitle')}</b>
              <br />
              {t('Rooms.SPAText')}
              <br />
              <HerHim sex="ForHer" />
              <br />
              <HerHim sex="ForHim" />
            </p>
            <br />
            <p>{t('Rooms.SecondParagraph')}</p>
            <Amenities amenities={amenities} renderType="booking" hasTooltip />
            <p style={{ marginTop: 40, maxWidth: 550 }}>
              {additionalInfoTexts[name]}
            </p>
          </div>
          <div className="hotel-room-description-right">
            <span className="full-period">
              {startDate} - {endDate} ({fullDays}{' '}
              {renderCountOfDays(fullDays, systemLanguage)})
            </span>
            <span className="full-price">
              {total} {renderCurrency(currency, systemLanguage)}
            </span>
            <Button
              onClick={() => onAddItem({ product_id: code, isAuthenticated })}
              classNames="btn btn-green btn-mid-pad pv-0 h-40"
              isDisabled={isProcessing || isRecentlyAdded}
              isLoading={isProcessing}
              buttonId={`booking_book_room_button_${normalizeIdString(code)}`}
            >
              {isRecentlyAdded
                ? t('Booking.Hotel.Placeholders.AddedToTheCart')
                : t('Booking.Hotel.Placeholders.Book')}
            </Button>
            {/* <Button
              onClick={navigateToRoomDetails}
              classNames="btn underlined btn-mid-pad pv-0 h-40"
            >
              {t('Booking.Hotel.Placeholders.MoreInfo')}
            </Button> */}
          </div>
        </div>
      </div>
    </div>
  )
}

BookingHotelRoom.propTypes = {
  roomInfo: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  searchParams: PropTypes.object.isRequired,
  systemLanguage: PropTypes.string.isRequired,
  onAddItem: PropTypes.func.isRequired,
}

export default BookingHotelRoom
