import HttpService from './http'

const baseUrl = process.env.REACT_APP_BACKEND_API

class AuthenticationService {
  constructor() {
    this.request = new HttpService(baseUrl)
  }

  signUp = payload => {
    const temporaryToken = localStorage.getItem('temporaryToken')

    return this.request.post(
      '/auth/signup',
      JSON.stringify({ ...payload, token: temporaryToken || null })
    )
  }

  signIn = payload => {
    const temporaryToken = localStorage.getItem('temporaryToken')

    return this.request.post(
      '/auth/signin',
      JSON.stringify({
        ...payload,
        token: temporaryToken || null,
      })
    )
  }

  oAuth = payload =>
    this.request.post(
      '/auth/signin_external',
      JSON.stringify({
        token:
          payload.oAuthType === 'google'
            ? payload.response.id_token
            : payload.response.accessToken,
        type: payload.oAuthType,
      })
    )

  getTemporaryToken = () => this.request.get('/auth/signin_guest')

  forgotPassword = payload => {
    return this.request.post(
      '/auth/forgotPassword',
      JSON.stringify({ ...payload })
    )
  }

  recoverPassword = (payload, token) => {
    return this.request.post(
      `/auth/resetPassword/${token}`,
      JSON.stringify(payload)
    )
  }
}

export default new AuthenticationService()
