import MassageImg from '../../static/images/relax/massage.jpg'
import SPAImg from '../../static/images/relax/spa.jpg'
import SaunaImg from '../../static/images/relax/sauna.jpeg'

import MassageHero from '../../static/images/relax/massage-hero.jpg'
import SPAHero from '../../static/images/relax/spa-hero.jpg'
import SaunaHero from '../../static/images/relax/sauna-hero.jpg'

export const relaxTypes = [
  {
    key: 'massage',
    i18nKey: 'Massage',
    img: MassageImg,
    heroImg: MassageHero,
    route: '/massage',
    features: [
      'General',
      'Relaxing',
      'Limfic',
      'StoneTherapy',
      'WithAromaCandle',
      'Modelling',
      'Sport',
      'AntiCellulite',
      'NeckAndCollarZone',
      'Spine',
      'Legs',
      'ForKids',
      'TimeForYourself',
      'EnergyRecovery',
    ],
  },
  {
    key: 'spa',
    i18nKey: 'SPA',
    img: SPAImg,
    heroImg: SPAHero,
    route: '/spa',
    features: [
      'Pilling',
      'General',
      'WithChocolate',
      'WithOlives',
      'WineTherapy',
      'BodyDetox',
      'LegsAnsArms',
    ],
  },
  {
    key: 'sauna',
    i18nKey: 'Sauna',
    img: SaunaImg,
    heroImg: SaunaHero,
    route: '/sauna',
    features: ['Sauna', 'Tub'],
  },
]
