import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { NavLink, useLocation, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Menu, Dropdown, Tooltip, notification } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'

import NavProfile from '../NavProfile'
import Button from '../Button'
import { onLogOut } from '../../redux/auth/auth'
import useSystemLanguage from '../../hooks/useSystemLanguage'
import useAbstractionMediaQueries from '../../hooks/useAbstractionMediaQueries'

import { languages, appVersion } from '../../utils/constants'

import 'antd/dist/antd.css'
import './NavBar.scss'

import facebook from '../../static/icons/facebook.svg'
import instagram from '../../static/icons/instagram.svg'
import shelestLogo from '../../static/icons/logo-ua.svg'
import burger from '../../static/icons/burger.svg'
import cart from '../../static/icons/cart.svg'
import logout from '../../static/icons/logout.svg'
import Loader from '../Loader'

const NavBar = ({
  isAuthenticated,
  onLogOut,
  user,
  cartItemsListCount,
  isCartLoading,
  isProcessing,
}) => {
  const body = document.getElementsByTagName('body')[0]
  const { en, ukr } = languages

  const history = useHistory()
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const { systemLanguage, setSystemLanguage } = useSystemLanguage()
  const [currentLanguage, setLanguage] = useState(systemLanguage)
  const {
    isMobile,
    isTablet,
    isBigTable,
    isBiggestTable,
  } = useAbstractionMediaQueries()
  const [isMobileMenuOpened, setMobileMenuVisibility] = useState(false)
  const [wasPreviouslyMenuOpened, setPreviouslyMenuOpenedStatus] = useState(
    undefined
  )

  const changeLanguage = lang => {
    if (currentLanguage === lang) {
      return null
    } else {
      localStorage.setItem('prefferedLanguage', lang)
      setLanguage(lang)
      setSystemLanguage(lang)
      return i18n.changeLanguage(lang)
    }
  }

  const getRoute = () => {
    return location.pathname.includes('/booking/')
      ? location.pathname
      : '/booking/hotel'
  }

  const menu = (
    <Menu>
      <Menu.Item onClick={() => changeLanguage(ukr)}>
        <span>UA</span>
      </Menu.Item>
      {/* <Menu.Item onClick={() => changeLanguage(ru)}>
        <span>RU</span>
      </Menu.Item> */}
      <Menu.Item onClick={() => changeLanguage(en)}>
        <span>EN</span>
      </Menu.Item>
    </Menu>
  )

  const toggleMenu = () => {
    if (isMobileMenuOpened) {
      body.style.overflow = 'initial'
    } else {
      body.style.overflow = 'hidden'
    }
    setPreviouslyMenuOpenedStatus(true)
    setMobileMenuVisibility(!isMobileMenuOpened)
  }

  const toggleClassNames = () => {
    if (!isMobileMenuOpened && wasPreviouslyMenuOpened === undefined) {
      return ' initial'
    } else if (isMobileMenuOpened) {
      return ' opened'
    } else {
      return ' closed'
    }
  }

  const getLanguageTitle = () => {
    switch (currentLanguage) {
      case 'en':
        return 'EN'
      default:
        return 'UA'
    }
  }

  const navigateToCart = () => {
    history.push('/booking/cart')
  }

  useEffect(() => {
    if (!isMobile || !isTablet || !isBigTable) {
      setPreviouslyMenuOpenedStatus(undefined)
      setMobileMenuVisibility(false)
      body.style.overflow = 'initial'
    }
  }, [isMobile, isTablet, isBigTable])

  useEffect(() => {
    setTimeout(() => {
      setPreviouslyMenuOpenedStatus(undefined)
    }, 500)
    body.style.overflow = 'initial'
    setMobileMenuVisibility(false)
  }, [location])

  return isBiggestTable ? (
    <div className="navbar-wrapper sticky-to-top">
      <div
        style={{
          position: 'relative',
        }}
      >
        <nav className="navbar">


          <NavLink to="/" style={{ display: 'flex', alignItems: 'flex-end' }}>
            <img className="navbar-logo" src={shelestLogo} alt="Shelest Logo" />
            {/* <p>Ver {appVersion}</p> */}
          </NavLink>
          <div className="actions">
            <div className="actions-item">
              <div className="phone-div">
                  <a href="tel:+380671100067" className="phone-link">+38 (067) 11 000 67</a>
              </div>

              {isAuthenticated ? (
                <img
                  className="top-icon"
                  src={logout}
                  onClick={onLogOut}
                  alt=""
                ></img>
              ) : null}
              {0?(
              <div className="cart-mobile" onClick={navigateToCart}>
                <img className="top-icon" src={cart} alt="" />
                {isCartLoading || isProcessing ? (
                  <Loader
                    className="loader"
                    size={16}
                    iconStyle={{ color: '#fff' }}
                  />
                ) : (
                  <span className="loader">{cartItemsListCount}</span>
                )}
              </div>
              ): null}
              <img
                onClick={toggleMenu}
                src={burger}
                className="burger top-icon"
                alt=""
              ></img>
            </div>
          </div>
        </nav>
        <div
          id="menu-wrapper"
          className={`menu-wrapper${toggleClassNames()}`}
          onAnimationStart={e => {
            e.persist()
            const target = document.getElementById('menu-wrapper')

            if (e.animationName === 'fadeIn') {
              target.classList.remove('initial')
            }
          }}
          onAnimationEnd={e => {
            e.persist()
            const target = document.getElementById('menu-wrapper')

            if (e.animationName === 'fadeOut') {
              target.classList.add('initial')
            }
          }}
        >
          <ul className="navbar-list">
            <NavLink to="/rooms" className="navbar-list-item">
              {t('Home.Navigation.Rooms')}
            </NavLink>
            <NavLink to="/restaurant" className="navbar-list-item">
              {t('Home.Navigation.Restaurant')}
            </NavLink>
            <NavLink to="/pool" className="navbar-list-item">
              {t('Home.Navigation.Pool')}
            </NavLink>
            {/* <NavLink to="/new-year" className="navbar-list-item new-year">
              {t('Home.Navigation.NewYear')}
            </NavLink> */}
            <NavLink to="/events" className="navbar-list-item">
              {t('Home.Navigation.Events')}
            </NavLink>
            <NavLink to="/relax" className="navbar-list-item">
              {t('Home.Navigation.Relax')}
            </NavLink>
            {/*
            <NavLink to="/shelest-home" className="navbar-list-item">
              {t('Home.Navigation.ShelestHome')}
            </NavLink>
            */}
            <a
              href="https://www.shelest-home.com/?utm_source=site&utm_medium=hotel"
              target="_blank"
              className="navbar-list-item"
              rel="noreferrer"
            >
              {t('Home.Navigation.ShelestHome')}
            </a>

            <a
              href=" https://www.shelest-hutir.com/?utm_source=site&utm_medium=hotel"
              target="_blank"
              className="navbar-list-item"
              rel="noreferrer"
            >
              {t('Home.Navigation.ToInvest')}
            </a>
            <NavLink to="/do-nothing" className="navbar-list-item">
              {t('Home.Navigation.DoNothing')}
            </NavLink>
            {/* <NavLink to="/" className="navbar-list-item">
            {t('Home.Navigation.AboutUs')}
          </NavLink> */}
            <NavLink to="/contacts" className="navbar-list-item">
              {t('Home.Navigation.Contacts')}
            </NavLink>
            {currentLanguage === 'ukr'?(
            <NavLink to="/team" className="navbar-list-item">
              {t('Home.Navigation.Team')}
            </NavLink>
            ):''}
            {/* <NavLink to="/covid-19" className="navbar-list-item covid">
              Covid-19
            </NavLink> */}
            <a
              href="https://tourmkr.com/F1qgEP3IcH"
              target="_blank"
              className="navbar-list-item"
              rel="noreferrer"
            >
              {t('Home.Navigation.3DTour')}
            </a>
            {/*!isAuthenticated ? (
              <NavLink to="/login" className="navbar-list-item">
                {t('Home.Navigation.Profile')}
              </NavLink>
            ) : (
              <NavLink
                to="/profile/booking-history"
                className="navbar-list-item"
              >
                {t('Profile.BookingHistory')}
              </NavLink>
            )*/}
            <NavLink to={getRoute()} className="navbar-list-item">
              <Button
                temporarySolution
                classNames="btn-green"
                buttonId="navbar_booking_button"
              >
                {t('Home.Navigation.Booking')}
              </Button>
            </NavLink>
            {/* <Dropdown trigger="click" overlay={menu}>
              <span className="navbar-list-item language-toggle">
                {getLanguageTitle()} <CaretDownOutlined />
              </span>
            </Dropdown> */}
          </ul>
          <div className="toggle-language-block">
            <ul className="languages-list">
              {['ukr', 'en'].map(item => (
                <li
                  className={`languages-list-item ${
                    currentLanguage === item ? 'active' : ''
                  }`}
                  key={item}
                  onClick={() => changeLanguage(item)}
                >
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="navbar-wrapper">
<div className="nav-line-parent">
   <div className="w-layout-blockcontainer basic-container w-container">
      <div className="top-navbar" style={{'justifyContent': 'space-between'}}>
      <NavLink to="/" style={{ display: 'flex', alignItems: 'flex-end' }}>
          <img className="navbar-logo" src={shelestLogo} alt="Shelest Logo" />
        </NavLink>

<div style={{display: 'flex'}}></div>
<div style={{display: 'flex'}}>

         <div className="phone-div" style={{'paddingLeft': '10px', 'paddingRight': '10px'}}>
          <a href="tel:+380671100067" className="phone-link">+38 (067) 11 000 67</a>
         </div>
 {/*----------------------------*/}           
<nav className="navbar">
        
        <ul className="navbar-list" style={{'paddingLeft': '10px', 'paddingRight': '10px'}}>
          <a
            href="https://tourmkr.com/F1qgEP3IcH"
            target="_blank"
            className="navbar-list-item"
            rel="noreferrer"
          >
            {t('Home.Navigation.3DTour')}
          </a>
          <Dropdown trigger="click" overlay={menu}>
            <span className="navbar-list-item language-toggle">
              {getLanguageTitle()} <CaretDownOutlined />
            </span>
          </Dropdown>
          <a
            href="https://www.facebook.com/shelest.hotel"
            target="_blank"
            className="navbar-list-item"
            rel="noopener noreferrer"
          >
            <img src={facebook} alt="Facebook Logo" />
          </a>
          <a
            href="https://www.instagram.com/shelest.hotel"
            target="_blank"
            className="navbar-list-item"
            rel="noopener noreferrer"
          >
            <img src={instagram} alt="Instagram Logo" />
          </a>
        </ul>
        </nav>
 {/*----------------------------*/}    

          <div className="navbar-actions">
          <>
            <NavLink to={getRoute()}>
              <Button
                temporarySolution
                classNames="btn-green"
                buttonId="navbar_booking_button"
              >
                {t('Home.Navigation.Booking')}
              </Button>
            </NavLink>
          </>
        </div>

</div>

      </div>
   </div>
</div>


      <nav className="navbar">
      {/*
        <NavLink to="/" style={{ display: 'flex', alignItems: 'flex-end' }}>
          <img className="navbar-logo" src={shelestLogo} alt="Shelest Logo" />
        </NavLink>
            */}
        <ul className="navbar-list">
          <NavLink to="/rooms" className="navbar-list-item">
            {t('Home.Navigation.Rooms')}
          </NavLink>
          <NavLink to="/restaurant" className="navbar-list-item">
            {t('Home.Navigation.Restaurant')}
          </NavLink>
          <NavLink to="/pool" className="navbar-list-item">
            {t('Home.Navigation.Pool')}
          </NavLink>
          {/* <NavLink to="/new-year" className="navbar-list-item new-year">
            {t('Home.Navigation.NewYear')}
          </NavLink> */}
          <NavLink to="/events" className="navbar-list-item">
            {t('Home.Navigation.Events')}
          </NavLink>
          <NavLink to="/relax" className="navbar-list-item">
            {t('Home.Navigation.Relax')}
          </NavLink>
          {/*
          <NavLink to="/shelest-home" className="navbar-list-item">
            {t('Home.Navigation.ShelestHome')}
          </NavLink>
          */}
          <a
            href="https://www.shelest-home.com/?utm_source=site&utm_medium=hotel"
            target="_blank"
            className="navbar-list-item"
            rel="noreferrer"
          >
            {t('Home.Navigation.ShelestHome')}
          </a>
          <a
            href="https://www.shelest-hutir.com/?utm_source=site&utm_medium=hotel"
            target="_blank"
            className="navbar-list-item"
            rel="noreferrer"
          >
            {t('Home.Navigation.ToInvest')}
          </a>
          <NavLink to="/do-nothing" className="navbar-list-item">
            {t('Home.Navigation.DoNothing')}
          </NavLink>
          {/* <NavLink to="/" className="navbar-list-item">
            {t('Home.Navigation.AboutUs')}
          </NavLink> */}
          <NavLink to="/contacts" className="navbar-list-item">
            {t('Home.Navigation.Contacts')}
          </NavLink>
          {currentLanguage === 'ukr'?(
          <NavLink to="/team" className="navbar-list-item">
            {t('Home.Navigation.Team')}
          </NavLink>
          ):''}

          {/*
          <a
            href="https://tourmkr.com/F1qgEP3IcH"
            target="_blank"
            className="navbar-list-item"
            rel="noreferrer"
          >
            {t('Home.Navigation.3DTour')}
          </a>
          <Dropdown trigger="click" overlay={menu}>
            <span className="navbar-list-item language-toggle">
              {getLanguageTitle()} <CaretDownOutlined />
            </span>
          </Dropdown>
          <a
            href="https://www.facebook.com/shelest.hotel"
            target="_blank"
            className="navbar-list-item"
            rel="noopener noreferrer"
          >
            <img src={facebook} alt="Facebook Logo" />
          </a>
          <a
            href="https://www.instagram.com/shelest.hotel"
            target="_blank"
            className="navbar-list-item"
            rel="noopener noreferrer"
          >
            <img src={instagram} alt="Instagram Logo" />
          </a>
          */}
        </ul>
        {/*
        <div className="navbar-actions">
          <>
            <NavLink to={getRoute()}>
              <Button
                temporarySolution
                classNames="btn-green"
                buttonId="navbar_booking_button"
              >
                {t('Home.Navigation.Booking')}
              </Button>
            </NavLink>
          </>
        </div>*/}
      </nav>
    </div>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,

  cartItemsListCount: state.cart.cartItemsListCount,
  isAuthenticated: state.auth.isAuthenticated,
  isCartLoading: state.cart.isCartLoading,
  isProcessing: state.cart.isProcessing,
})

const mapDispatchToProps = dispatch => ({
  onLogOut: () => dispatch(onLogOut()),
})

NavBar.propTypes = {
  isAuthenticated: PropTypes.bool,
  onLogOut: PropTypes.func.isRequired,
  user: PropTypes.object,
}

NavBar.defaultProps = {
  isAuthenticated: false,
  user: null,
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)
