import React, { useState, useEffect } from 'react'
// import PropTypes from 'prop-types'
import { useLocation, useHistory, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Form, Input, InputNumber } from 'antd'
import MaskedInput from 'antd-mask-input'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login'
import { isNil } from 'ramda'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'

import Loader from '../../components/Loader'
import Button from '../../components/Button'

import {
  onSignUp,
  onSignIn,
  onOAuth,
  onForgotPassword,
  onRecoverPassword,
} from '../../redux/auth/auth'
import { onVerifyReservation } from '../../redux/cart/cart'

import { reverseLocation } from '../../utils/helpers'
import {
  loginNavigationButtons,
  ukrainianPhoneNumberPattern,
  passwordPattern,
} from '../../utils/constants'

import './Login.scss'

import circleIdentic from '../../static/icons/circle_identic.svg'
import triangle1Identic from '../../static/icons/triangle1_identic.svg'
import triangle2Identic from '../../static/icons/triangle2_identic.svg'
import waveIdentic from '../../static/icons/wave_identic.svg'
import google from '../../static/icons/google.svg'
import facebook from '../../static/icons/facebook.svg'
import apple from '../../static/icons/apple.svg'

const VerifyReservationComponent = ({ history, onVerifyReservation }) => {
  const ubn = new URLSearchParams(useLocation().search).get('ubn')
  const ubsign = new URLSearchParams(useLocation().search).get('ubsign')

  useEffect(() => {
    onVerifyReservation({ ubn, ubsign, history })
  }, [])

  return (
    <div className="verify-reservation is-loading">
      <Loader size={60} />
    </div>
  )
}

const SetNewPasswordComponent = ({
  history,
  onRecoverPassword,
  isResetingPassword,
}) => {
  const tokenFromQueryParams = new URLSearchParams(useLocation().search).get(
    'token'
  )

  const { t } = useTranslation()

  const onFinish = values => {
    onRecoverPassword({ values, history, token: tokenFromQueryParams })
  }

  return (
    <div className="form">
      <Form name="basic" onFinish={onFinish}>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: t('Validation.Required'),
            },
            {
              min: 8,
              message: t('Validation.PasswordMinLength'),
            },
            {
              pattern: passwordPattern,
              message: t('Validation.PasswordRules'),
            },
          ]}
        >
          <Input.Password
            visibilityToggle={false}
            placeholder={t('InputPlaceholders.Password')}
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: t('Validation.Required'),
            },

            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(t('Validation.PasswordsAreNotSame'))
              },
            }),
          ]}
          hasFeedback
        >
          <Input.Password
            visibilityToggle={false}
            placeholder={t('InputPlaceholders.ConfirmPassword')}
          />
        </Form.Item>
        <Button
          htmlType="submit"
          classNames="btn-mid-pad small-btn btn-green h-40 w-100"
          isLoading={isResetingPassword}
          isDisabled={isResetingPassword}
          buttonId="login_recover_password_button"
        >
          {t('RecoverPassword.Form.Placeholders.Remind')}
        </Button>
      </Form>
    </div>
  )
}

const ForgotPasswordComponent = ({
  history,
  onForgotPassword,
  isForgotPasswordLoading,
  navigateToLogin,
}) => {
  const { t } = useTranslation()

  const onFinish = values => {
    onForgotPassword({ values, history })
  }

  return (
    <div className="form">
      <Form name="basic" onFinish={onFinish}>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: t('Validation.Required'),
            },
            {
              type: 'email',
              message: t('Validation.InvalidEmailFormat'),
            },
          ]}
          className="last-field"
        >
          <Input placeholder={t('InputPlaceholders.Email')} />
        </Form.Item>
        <div className="forgot-password-block">
          <NavLink to="/login" onClick={navigateToLogin}>
            {t('Login.BackToLogin')}
          </NavLink>
        </div>
        <Button
          htmlType="submit"
          classNames="btn-mid-pad small-btn btn-green h-40 w-100"
          isLoading={isForgotPasswordLoading}
          isDisabled={isForgotPasswordLoading}
          buttonId="login_forgot_password_button"
        >
          {t('ForgotPassword.Form.Placeholders.Remind')}
        </Button>
      </Form>
    </div>
  )
}

const LoginComponent = ({
  onSignIn,
  onOAuth,
  isSignInLoading,
  history,
  isAuthenticated,
  navigateToForgotPassword,
  isRedirectToCart,
}) => {
  const { t } = useTranslation()
  const sendDataToGTM = useGTMDispatch()

  const onFinish = values => {
    sendDataToGTM({
      event: 'formSent',
      eventCategory: 'LogIn',
      ...values,
    })

    onSignIn({ ...values, history, isRedirectToCart })
  }

  const responseFacebook = response => {
    return onOAuth({ response, oAuthType: 'facebook', history })
  }

  const responseGoogle = response => {
    return onOAuth({
      response: response.getAuthResponse(),
      oAuthType: 'google',
      history,
    })
  }

  return (
    <>
      <div className="form">
        <Form name="basic" onFinish={onFinish}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: t('Validation.Required'),
              },
              {
                type: 'email',
                message: t('Validation.InvalidEmailFormat'),
              },
            ]}
          >
            <Input placeholder={t('InputPlaceholders.Email')} />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: t('Validation.Required'),
              },
            ]}
            className="last-field"
          >
            <Input.Password
              visibilityToggle={false}
              placeholder={t('InputPlaceholders.Password')}
            />
          </Form.Item>
          <div className="forgot-password-block">
            <NavLink to="/forgot-password" onClick={navigateToForgotPassword}>
              {t('Login.ForgotPass')}
            </NavLink>
          </div>
          <Button
            htmlType="submit"
            classNames="btn-mid-pad small-btn btn-green h-40 w-100"
            isLoading={isSignInLoading}
            buttonId="login_button"
          >
            {t('Login.Form.Placeholders.ButtonText')}
          </Button>
        </Form>
      </div>
      <div className="other-options">
        <span className="other-options-title">
          {t('Login.OtherOptions.Title')}
        </span>
        <ul className="other-options-list">
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
            render={renderProps => (
              <a
                onClick={renderProps.onClick}
                className="other-options-list-item"
              >
                <img src={google} alt="Google Logo" />
                <span className="underlined">Google</span>
              </a>
            )}
            onSuccess={responseGoogle}
            cookiePolicy={'single_host_origin'}
          />
          {/* <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_OAUTH_CLIENT_ID}
            autoLoad={false}
            fields="name,email,picture"
            scope="public_profile,email"
            callback={responseFacebook}
            render={renderProps => (
              <a
                onClick={renderProps.onClick}
                className="other-options-list-item"
              >
                <img src={facebook} alt="Facebook Logo" />
                <span className="underlined">Facebook</span>
              </a>
            )}
          />
          <a className="other-options-list-item">
            <img src={apple} alt="Apple Logo" />
            <span className="underlined">Apple</span>
          </a> */}
        </ul>
      </div>
    </>
  )
}

const RegisterComponent = ({
  onSignUp,
  isSignUpLoading,
  history,
  isRedirectToCart,
}) => {
  const { t } = useTranslation()
  const [phoneNumber, setPhoneNumber] = useState(null)
  const sendDataToGTM = useGTMDispatch()

  const locationState = history.location?.state?.res?.user

  const onFinish = values => {
    sendDataToGTM({
      event: 'formSent',
      eventCategory: 'Registration',
      ...values,
      phone: `38${phoneNumber}`,
    })

    onSignUp({
      ...values,
      phone: `38${phoneNumber}`,
      history,
      isRedirectToCart,
    })
  }

  return (
    <>
      <div className="form">
        <Form name="basic" onFinish={onFinish}>
          <Form.Item
            name="firstName"
            rules={[
              {
                required: true,
                message: t('Validation.Required'),
              },
            ]}
            initialValue={locationState?.firstName || ''}
          >
            <Input placeholder={t('InputPlaceholders.Name')} />
          </Form.Item>
          <Form.Item
            name="secondName"
            initialValue={locationState?.secondName || ''}
          >
            <Input placeholder={t('InputPlaceholders.Surname')} />
          </Form.Item>
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                message: t('Validation.Required'),
              },
              {
                pattern: ukrainianPhoneNumberPattern,
                message: t('Validation.WrongPhoneFormat'),
              },
            ]}
          >
            <Input
              maxLength={10}
              addonBefore="+38"
              placeholder={t('InputPlaceholders.Phone')}
              onChange={e => setPhoneNumber(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: t('Validation.InvalidEmailFormat'),
              },
              {
                required: true,
                message: t('Validation.Required'),
              },
            ]}
            initialValue={locationState?.email || ''}
          >
            <Input placeholder={t('InputPlaceholders.Email')} />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: t('Validation.Required'),
              },
              {
                min: 8,
                message: t('Validation.PasswordMinLength'),
              },
              {
                pattern: passwordPattern,
                message: t('Validation.PasswordRules'),
              },
            ]}
            hasFeedback
          >
            <Input.Password
              visibilityToggle={false}
              placeholder={t('InputPlaceholders.Password')}
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: t('Validation.Required'),
              },

              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(t('Validation.PasswordsAreNotSame'))
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password
              visibilityToggle={false}
              placeholder={t('InputPlaceholders.ConfirmPassword')}
            />
          </Form.Item>
          <Button
            htmlType="submit"
            classNames="btn-mid-pad small-btn btn-green h-40 w-100"
            isLoading={isSignUpLoading}
            buttonId="register_button"
          >
            {t('Register.Form.Placeholders.Register')}
          </Button>
        </Form>
      </div>
    </>
  )
}

const Login = ({
  onSignUp,
  onSignIn,
  onOAuth,
  onForgotPassword,
  onRecoverPassword,
  onVerifyReservation,
  isSignUpLoading,
  isSignInLoading,
  isExternalLoginLoading,
  isForgotPasswordLoading,
  isResetingPassword,
  isAuthenticated,
  isRedirectToCart,
}) => {
  const { t } = useTranslation()
  const history = useHistory()

  console.log('history: ', history)
  const location = useLocation()
  const [currentRoute, setCurrentRoute] = useState(reverseLocation(location))

  const navigateToEntity = (e, route) => {
    e.preventDefault()
    setCurrentRoute(route)
    history.push(route)
  }

  const navigateToForgotPassword = () => {
    setCurrentRoute('forgot-password')
  }

  const navigateToLogin = () => {
    setCurrentRoute('login')
  }

  const renderLoginItems = loginNavigationButtons => {
    return loginNavigationButtons.map(
      ({ route, key, translation, isNavigateable = true }) => {
        return (
          <span
            className={`login-items-list-item${
              currentRoute === route ? ' active' : ''
            }${!isNavigateable ? ' no-pointer' : ''}`}
            onClick={e => isNavigateable && navigateToEntity(e, route)}
            key={key}
          >
            {t(`${translation}.Title`)}
          </span>
        )
      }
    )
  }

  const componentsConfig = {
    login: (
      <LoginComponent
        onSignIn={onSignIn}
        onOAuth={onOAuth}
        isSignInLoading={isSignInLoading}
        history={history}
        isAuthenticated={isAuthenticated}
        navigateToForgotPassword={navigateToForgotPassword}
        isRedirectToCart={isRedirectToCart}
      />
    ),
    register: (
      <RegisterComponent
        onSignUp={onSignUp}
        isSignUpLoading={isSignUpLoading}
        history={history}
        isRedirectToCart={isRedirectToCart}
      />
    ),
    'forgot-password': (
      <ForgotPasswordComponent
        history={history}
        onForgotPassword={onForgotPassword}
        isForgotPasswordLoading={isForgotPasswordLoading}
        navigateToLogin={navigateToLogin}
      />
    ),
    'recover-password': (
      <SetNewPasswordComponent
        history={history}
        onRecoverPassword={onRecoverPassword}
        isResetingPassword={isResetingPassword}
      />
    ),
    'verify-reservation': (
      <VerifyReservationComponent
        history={history}
        onVerifyReservation={onVerifyReservation}
      />
    ),
  }

  const innerContentConfig = {
    'forgot-password': (
      <ul className="login-items-list">
        {renderLoginItems([
          {
            route: 'forgot-password',
            isNavigateable: false,
            key: 'forgot-password',
            translation: 'ForgotPassword',
          },
        ])}
      </ul>
    ),
    'recover-password': (
      <ul className="login-items-list">
        {renderLoginItems([
          {
            route: 'recover-password',
            isNavigateable: false,
            key: 'recover-password',
            translation: 'RecoverPassword',
          },
        ])}
      </ul>
    ),
    login: (
      <ul className="login-items-list">
        {renderLoginItems(loginNavigationButtons)}
      </ul>
    ),
    register: (
      <ul className="login-items-list">
        {renderLoginItems(loginNavigationButtons)}
      </ul>
    ),
  }

  if (currentRoute === 'verify-reservation') {
    return componentsConfig[currentRoute]
  }

  return isAuthenticated ? (
    <div className="wrapper">
      <div className="login-and-register">
        <div className="form-wrapper">
          <h1>{t('Login.InTheSystem')}</h1>
        </div>
      </div>
    </div>
  ) : (
    <div className="wrapper">
      <div className="login-and-register">
        <div className="form-wrapper">
          {isExternalLoginLoading ? (
            <Loader size={60} />
          ) : (
            <>
              {innerContentConfig[currentRoute]}
              {componentsConfig[currentRoute]}
              {/* {currentRoute === 'login' ? (
                <LoginComponent
                  onSignIn={onSignIn}
                  onOAuth={onOAuth}
                  isSignInLoading={isSignInLoading}
                  history={history}
                  isAuthenticated={isAuthenticated}
                  navigateToForgotPassword={navigateToForgotPassword}
                />
              ) : (
                <RegisterComponent
                  onSignUp={onSignUp}
                  isSignUpLoading={isSignUpLoading}
                  history={history}
                />
              )} */}
            </>
          )}
        </div>
      </div>
      <img src={circleIdentic} className="identic circle" alt="" />
      <img src={triangle1Identic} className="identic tri1" alt="" />
      <img src={triangle2Identic} className="identic tri2" alt="" />
      <img src={waveIdentic} className="identic wave" alt="" />
    </div>
  )
}

const mapStateToProps = state => ({
  isSignUpLoading: state.auth.isSignUpLoading,
  isSignInLoading: state.auth.isSignInLoading,
  isExternalLoginLoading: state.auth.isExternalLoginLoading,
  isAuthenticated: state.auth.isAuthenticated,
  isForgotPasswordLoading: state.auth.isForgotPasswordLoading,
  isResetingPassword: state.auth.isResetingPassword,
  isRedirectToCart: state.cart.isRedirectToCart,
})

const mapDispatchToProps = dispatch => ({
  onSignUp: action => dispatch(onSignUp(action)),
  onSignIn: action => dispatch(onSignIn(action)),
  onOAuth: action => dispatch(onOAuth(action)),
  onForgotPassword: action => dispatch(onForgotPassword(action)),
  onRecoverPassword: action => dispatch(onRecoverPassword(action)),
  onVerifyReservation: action => dispatch(onVerifyReservation(action)),
})

Login.propTypes = {}

Login.defaultProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
