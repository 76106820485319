import { combineReducers } from 'redux'

import auth from './auth/auth'
import rooms from './rooms/rooms'
import goods from './goods/goods'
import cart from './cart/cart'
import hotel from './booking/hotel/hotel'

const appReducer = combineReducers({
  auth,
  rooms,
  goods,
  cart,
  hotel,
})

const rootReducer = (state, action) => {
  let reduxState = state

  return appReducer(reduxState, action)
}

export default rootReducer
