import React from 'react'
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps'

const coords = {
  lat: 50.6652683,
  lng: 30.5243305,
}
const Map = props => (
  <GoogleMap defaultZoom={12} defaultCenter={coords}>
    {props.isMarkerShown && <Marker position={coords}></Marker>}
  </GoogleMap>
)

export default withScriptjs(withGoogleMap(Map))
