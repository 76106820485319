import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Slider from 'react-slick'
import { useModal } from 'react-modal-hook'

import Button from '../Button'
import Loader from '../../components/Loader'
import DialogModal from '../../components/DialogModal'

import { onAddItem, onGetItems } from '../../redux/cart/cart'

import useSystemLanguage from '../../hooks/useSystemLanguage'

import toRightArrow from '../../static/icons/toRightArrow.svg'
import toLeftArrow from '../../static/icons/toLeftArrow.svg'

import './Goods.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { normalizeIdString } from '../../utils/helpers'

const arrowProps = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
}

const arrowDefaultProps = {
  className: '',
  style: {},
  onClick: () => null,
}

const PrevArrow = ({ className, style, onClick }) => {
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <img src={toLeftArrow} alt="Arrow to left" />
    </div>
  )
}

PrevArrow.propTypes = {
  ...arrowProps,
}

PrevArrow.defaultProps = {
  ...arrowDefaultProps,
}

const NextArrow = ({ className, style, onClick }) => {
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <img src={toRightArrow} alt="Arrow to right" />
    </div>
  )
}

NextArrow.propTypes = {
  ...arrowProps,
}

NextArrow.defaultProps = {
  ...arrowDefaultProps,
}

const settings = {
  slidesToShow: 3,
  slidesToScroll: 1,
  // arrows: false,
  infinite: true,
  dots: false,
  centerMode: false,
  // centerPadding: 0,
  nextArrow: <NextArrow className="custom-arrow" />,
  prevArrow: <PrevArrow className="custom-arrow" />,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1050,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

const GoodItem = ({
  onAddItem,
  keyValue,
  price,
  url,
  id,
  isAuthenticated,
  isProcessing,
  onShowModal,
  payable,
  ...rest
}) => {
  const { t } = useTranslation()
  const { systemLanguage } = useSystemLanguage()

  const getTitle = () => {
    switch (systemLanguage) {
      case 'en':
        return rest.title_eng
      case 'ru':
        return rest.title_rus
      default:
        return rest.title_ukr
    }
  }

  const title = getTitle()

  return (
    <div
      className="goods-list-item"
      key={keyValue}
      onClick={() => onShowModal()}
    >
      <h3 className="type">{title}</h3>
      <img src={`https://api.shelest.ua/strapi${url}`} alt="Goods Item" />
      <Button
        isDisabled={isProcessing}
        classNames="btn-green w-100 no-min-width h-40"
        buttonId={`goods_add_item_button_${normalizeIdString(id)}`}
      >
        {t('Goods.AddItem')}
      </Button>
    </div>
  )
}

GoodItem.propTypes = {
  keyValue: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  onAddItem: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
}

const Goods = ({
  areGoodsLoading,
  onAddItem,
  onGetItems,
  goodsList,
  isAuthenticated,
  isProcessing,
}) => {
  const body = document.getElementById('body')

  const { t } = useTranslation()
  const [currentGoodItem, setCurrentGoodItem] = useState(null)

  const [showModal, hideModal] = useModal(
    () => (
      <DialogModal
        onHide={hideModal}
        currentGoodItem={currentGoodItem}
        setCurrentGoodItem={setCurrentGoodItem}
      />
    ),
    [currentGoodItem]
  )

  const renderGoods = () => {
    return goodsList.map(item => {
      const {
        title,
        price,
        image: { url },
        _id,
        ...rest
      } = item

      return (
        <GoodItem
          item={item}
          key={_id}
          keyValue={_id}
          price={price}
          url={url}
          onAddItem={onAddItem}
          id={_id}
          isAuthenticated={isAuthenticated}
          isProcessing={isProcessing}
          onShowModal={() => {
            body.style.overflow = 'hidden'
            setCurrentGoodItem(item)
            showModal()
          }}
          {...rest}
        />
      )
    })
  }

  useEffect(() => {
    onGetItems()
  }, [onGetItems])

  return (
    <div className="goods-wrapper">
      <div className="goods">
        {goodsList?.length ? (
          <div className="animated-wrapper">
            <h2 className="goods-title">{t('Goods.Title')}</h2>
            <Slider {...settings}>{renderGoods()}</Slider>
          </div>
        ) : (
          <div className="loading">
            <Loader size={55} />
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  goodsList: state.cart.goodsList,
  areGoodsLoading: state.cart.areGoodsLoading,
  isAuthenticated: state.auth.isAuthenticated,
  isProcessing: state.cart.isProcessing,
})

const mapDispatchToProps = dispatch => ({
  onAddItem: action => dispatch(onAddItem(action)),
  onGetItems: () => dispatch(onGetItems()),
})

Goods.propTypes = {
  goodsList: PropTypes.array,
  onAddItem: PropTypes.func.isRequired,
  onGetItems: PropTypes.func.isRequired,
  areGoodsLoading: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
}

Goods.defaultProps = {
  goodsList: [],
}

export default connect(mapStateToProps, mapDispatchToProps)(Goods)
