import React from 'react'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

import DefaultImage from '../../static/images/events/banket/banket1.jpg'

import './EventLinkStyles.scss'

const EventLink = ({ event }) => {
  const history = useHistory()

  const { t, i18n } = useTranslation()

  const imageUrl = event.image || DefaultImage

  return (
    <div
      className="event-link-wrapper"
      onClick={() => {
        history.push(`events/${event.url}`)
      }}
    >
      <div className="event-link">
        <img src={imageUrl} />
        <h2>
          <a>{t(`Events.${event.i18nField}.EventName`)}</a>
        </h2>
        <p>{t(`Events.${event.i18nField}.ShortDescription`)}</p>
      </div>
    </div>
  )
}

export default EventLink
