import { clearUTMMetaData } from '../../utils/helpers'

class HttpService {
  constructor(baseUrl) {
    this.baseUrl = baseUrl
  }

  objectToQueryString(object) {
    const temp = Object.keys(object)

    return temp
      .map(
        (param, index) =>
          `${param}=${object[param] === 'ukr' ? 'ua' : object[param]}&`
      )
      .join('')
  }

  async request(
    path,
    method,
    body,
    isFormData = false,
    shouldUseToken = false
  ) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      ...(shouldUseToken && {
        'X-Access-Token': localStorage.getItem('token'),
      }),
    })

    const defaultHeader = new Headers({
      // 'X-Access-Token': localStorage.getItem('token'),
    })

    const request = isFormData
      ? new Request(`${this.baseUrl}${path}`, {
          method,
          headers: defaultHeader,
          body,
        })
      : new Request(`${this.baseUrl}${path}`, {
          method,
          headers,
          body,
        })

    try {
      const response = await fetch(request)

      // clearUTMMetaData()

      if (method === 'POST') {
        let jsonedResponse = await response.json()

        jsonedResponse.status = response.status
        return jsonedResponse
      }
      let jsonedResponse = await response.json()

      return jsonedResponse
    } catch (error) {
      console.log('error', error.message)
    }
  }

  async controllableRequest(url, method, config) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-Access-Token': config.token,
    })

    const request = new Request(`${this.baseUrl}${url}`, {
      method,
      headers,
      body: config?.body,
    })

    try {
      const response = await fetch(request)

      if (method === 'POST') {
        let jsonedResponse = await response.json()

        jsonedResponse.status = response.status
        return jsonedResponse
      }
      let jsonedResponse = await response.json()

      return jsonedResponse
    } catch (error) {
      console.log('error', error.message)
    }
  }

  get(url) {
    return this.request(url, 'GET')
  }

  controllableGet(url, config) {
    return this.controllableRequest(url, 'GET', config)
  }

  controllablePut(url, body, config) {
    return this.controllableRequest(url, 'PUT', { token: config, body })
  }

  controllableDelete(url, body, config) {
    return this.controllableRequest(url, 'DELETE', { token: config, body })
  }

  post(url, body, isFormData = false, shouldUseToken = false) {
    return this.request(url, 'POST', body, isFormData, shouldUseToken)
  }

  put(url, body, isFormData = false) {
    return this.request(url, 'PUT', body, isFormData)
  }

  delete(url, newHeader) {
    return this.request(url, 'DELETE', newHeader)
  }
}

export default HttpService
