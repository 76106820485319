import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

const textLines = {
  ForHer: [
    'Rooms.ForHerText.1',
    'Rooms.ForHerText.2',
    'Rooms.ForHerText.3',
    'Rooms.ForHerText.4',
  ],
  ForHim: ['Rooms.ForHimText.1', 'Rooms.ForHimText.2', 'Rooms.ForHimText.3'],
  Babysitter: [
    'Rooms.BabysitterText.1',
    'Rooms.BabysitterText.2',
    'Rooms.BabysitterText.3',
    'Rooms.BabysitterText.4',
  ],
}

const HerHim = props => {
  const { sex, prefix, prefixOnly } = props

  const { t } = useTranslation()

  const neededTextLines = textLines[sex]

  const renderedTextLines = neededTextLines
    ? neededTextLines.map((textLine, index) => {
        const isLastItem = neededTextLines.length - index === 1

        return (
          <>
            {t(textLine)}
            {!isLastItem && (
              <>
                <br />
                <br />
              </>
            )}
          </>
        )
      })
    : null

  return (
    <>
      {prefix && prefixOnly ? (
        <>{t(`Rooms.${prefix}`)}&nbsp;</>
      ) : prefix ? (
        <>
          {t(`Rooms.${prefix}`)}&nbsp;{t(`Rooms.${sex}`)}&nbsp;
        </>
      ) : (
        <>{t(`Rooms.${sex}`)}&nbsp;</>
      )}
      {neededTextLines ? (
        <Tooltip title={renderedTextLines} placement="bottom">
          <QuestionCircleOutlined />
        </Tooltip>
      ) : null}
    </>
  )
}

HerHim.propTypes = {
  sex: PropTypes.oneOf(['ForHer', 'ForHim']).isRequired,
  prefix: PropTypes.string.isRequired,
  prefixOnly: PropTypes.bool.isRequired,
}

export default HerHim
