import HttpService from './http'

const baseUrl = process.env.REACT_APP_BACKEND_API

class BookingService {
  constructor() {
    this.httpService = new HttpService(baseUrl)
  }

  searchHotelAvailability = async (payload, token) => {
    const queryString = this.httpService.objectToQueryString(payload)

    try {
      const res = await this.httpService.controllableGet(
        `/apartments/availability?${queryString}`,
        { token }
      )

      return res
    } catch (err) {
      console.log('err: ', err)
    }
  }
}

export default new BookingService()
