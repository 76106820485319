import { useMediaQuery } from 'beautiful-react-hooks'

import { mediaQueryConstants } from '../utils/constants'

const useAbstractionMediaQueries = () => {
 const { max475px, max768px, max1024px, biggest } = mediaQueryConstants
 //const { max475px, max768px, max1024px, max1400px } = mediaQueryConstants

  const isMobile = useMediaQuery(max475px)
  const isTablet = useMediaQuery(max768px)
  const isBigTable = useMediaQuery(max1024px)
  const isBiggestTable = useMediaQuery(biggest)
  //const isBiggestTable = useMediaQuery(max1400px)

  return { isMobile, isTablet, isBigTable, isBiggestTable }
}

export default useAbstractionMediaQueries
