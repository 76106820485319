import React from 'react'
import PropTypes from 'prop-types'

import './HeroImageStyles.scss'

const HeroImage = ({ backgroundImage, heroText, children, wrapperStyle }) => {
  const imageStyles = {
    backgroundImage: `url('${backgroundImage}')`,
  }

  return (
    <div className="hero-image-wrapper" style={wrapperStyle}>
      <div className="hero-image" style={imageStyles} />
      <h1 className="hero-text" style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
        {heroText}
        {children}
      </h1>
    </div>
  )
}

HeroImage.defaultProps = {
  heroText: '',
}

HeroImage.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  heroText: PropTypes.string,
}

export default HeroImage
