import { put, call, all, takeLatest } from 'redux-saga/effects'
import jwt from 'jwt-decode'
import { notification } from 'antd'

import { AuthenticationService } from '../../services/api'
import {
  renderNotification,
  somethingWentWrongNotification,
} from '../../utils/helpers'

import { setRedirectToCart } from '../../redux/cart/cart'

import { descriptionTypes, titleTypes } from '../../utils/constants'
import { SUCCESS, FAILURE, START } from '../constants'

const SIGN_UP = 'shelest/auth/SIGN_UP'
const SIGN_IN = 'shelest/auth/SIGN_IN'
const O_AUTH = 'shelest/auth/O_AUTH'
const SIGN_IN_WITH_TOKEN = 'shelest/auth/SIGN_IN_WITH_TOKEN'
const LOG_OUT = 'shelest/auth/LOG_OUT'
const GET_TEMPORARY_TOKEN = 'shelest/auth/GET_TEMPORARY_TOKEN'
const FORGOT_PASSWORD = 'shelest/auth/FORGOT_PASSWORD'
const RECOVER_PASSWORD = 'shelest/auth/RECOVER_PASSWORD'

const initialState = {
  errors: null,
  isSignUpLoading: false,
  isSignInLoading: false,
  isForgotPasswordLoading: false,
  isExternalLoginLoading: false,
  isResetingPassword: false,
  token: null,
  user: null,
  isAuthenticated: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${SIGN_UP}${START}`:
      return {
        ...state,
        isSignUpLoading: true,
      }

    case `${SIGN_UP}${SUCCESS}`:
      localStorage.setItem('token', action.payload.token)
      return {
        ...state,
        isSignUpLoading: false,
        token: action.payload.token,
        user: action.payload.user,
        isAuthenticated: true,
      }

    case `${SIGN_UP}${FAILURE}`:
      return {
        ...state,
        isSignUpLoading: false,
      }

    //

    case `${SIGN_IN}${START}`:
      return {
        ...state,
        isSignInLoading: true,
      }

    case `${SIGN_IN}${SUCCESS}`:
      localStorage.setItem('token', action.payload.token)
      return {
        ...state,
        isSignInLoading: false,
        isExternalLoginLoading: false,
        token: action.payload.token,
        user: action.payload.user,
        isAuthenticated: true,
        isAdmin: action.payload.role === 'admin' || false,
      }

    case `${SIGN_IN}${FAILURE}`:
      return {
        ...state,
        isSignInLoading: false,
        isExternalLoginLoading: false,
      }

    //

    case `${O_AUTH}${START}`:
      return {
        ...state,
        isExternalLoginLoading: true,
      }

    case SIGN_IN_WITH_TOKEN:
      const decodedToken = jwt(action.payload.token)

      return {
        ...state,
        token: action.payload.token,
        user: decodedToken.user,
        isAuthenticated: true,
        isAdmin: decodedToken.user.role === 'admin' || false,
      }

    //

    case `${GET_TEMPORARY_TOKEN}${SUCCESS}`:
      return {
        ...state,
      }

    //

    case `${FORGOT_PASSWORD}${START}`:
      return {
        ...state,
        isForgotPasswordLoading: true,
      }

    case `${FORGOT_PASSWORD}${SUCCESS}`:
      return {
        ...state,
        isForgotPasswordLoading: false,
      }

    case `${FORGOT_PASSWORD}${FAILURE}`:
      return {
        ...state,
        isForgotPasswordLoading: false,
      }

    //

    case `${RECOVER_PASSWORD}${START}`:
      return {
        ...state,
        isResetingPassword: true,
      }

    case `${RECOVER_PASSWORD}${SUCCESS}`:
      return {
        ...state,
        isResetingPassword: false,
      }

    case `${RECOVER_PASSWORD}${FAILURE}`:
      return {
        ...state,
        isResetingPassword: false,
      }

    //

    case LOG_OUT:
      localStorage.removeItem('token')
      return initialState
    default:
      return state
  }
}

export const onSignUp = action => ({
  type: `${SIGN_UP}${START}`,
  payload: action,
})

const onSignUpSuccess = res => ({
  type: `${SIGN_UP}${SUCCESS}`,
  payload: res,
})

const onSignUpFailure = () => ({
  type: `${SIGN_UP}${FAILURE}`,
})

export const onSignIn = action => ({
  type: `${SIGN_IN}${START}`,
  payload: action,
})

const onSignInSuccess = res => ({
  type: `${SIGN_IN}${SUCCESS}`,
  payload: res,
})

const onSignInFailure = () => ({
  type: `${SIGN_IN}${FAILURE}`,
})

export const onGetTemporaryToken = () => ({
  type: `${GET_TEMPORARY_TOKEN}${START}`,
})

const onGetTemporaryTokenSuccess = action => ({
  type: `${GET_TEMPORARY_TOKEN}${SUCCESS}`,
  payload: action,
})

export const onOAuth = res => ({
  type: `${O_AUTH}${START}`,
  payload: res,
})

export const onTokenRefreshSession = token => ({
  type: SIGN_IN_WITH_TOKEN,
  payload: token,
})

export const onForgotPassword = action => ({
  type: `${FORGOT_PASSWORD}${START}`,
  payload: action,
})

const onForgotPasswordSuccess = () => ({
  type: `${FORGOT_PASSWORD}${SUCCESS}`,
})

const onForgotPasswordFailure = () => ({
  type: `${FORGOT_PASSWORD}${FAILURE}`,
})

export const onRecoverPassword = action => ({
  type: `${RECOVER_PASSWORD}${START}`,
  payload: action,
})

const onRecoverPasswordSuccess = () => ({
  type: `${RECOVER_PASSWORD}${SUCCESS}`,
})

const onRecoverPasswordFailure = () => ({
  type: `${RECOVER_PASSWORD}${FAILURE}`,
})

export const onLogOut = () => ({
  type: LOG_OUT,
})

function* signUp(action) {
  try {
    const res = yield AuthenticationService.signUp(action.payload)

    if (res.status === 200 || res.status === 201) {
      if (action.payload.isRedirectToCart) {
        action.payload.history.push('/booking/cart')
        yield put(setRedirectToCart(false))
      } else {
        action.payload.history.push('/')
      }

      localStorage.removeItem('temporaryToken')
      localStorage.removeItem('temporaryTokenExp')
      yield renderNotification('successNotification', titleTypes.signUpSuccess)
      yield put(onSignUpSuccess(res))
    } else {
      yield renderNotification(
        'errorNotification',
        titleTypes.signUpError,
        res.errors,
        descriptionTypes.errorDescription
      )
      yield put(onSignUpFailure())
    }
  } catch (err) {
    yield yield somethingWentWrongNotification()
    yield put(onSignUpFailure())
  }
}

function* signIn(action) {
  try {
    const res = yield AuthenticationService.signIn(action.payload)

    if (res.status === 200 || res.status === 201) {
      if (action.payload.isRedirectToCart) {
        action.payload.history.push('/booking/cart')
        yield put(setRedirectToCart(false))
      } else {
        action.payload.history.push('/')
      }

      localStorage.removeItem('temporaryToken')
      localStorage.removeItem('temporaryTokenExp')
      yield renderNotification('successNotification', titleTypes.signInSuccess)
      yield put(onSignInSuccess(res))
    } else {
      yield renderNotification(
        'errorNotification',
        titleTypes.signInError,
        res.errors,
        descriptionTypes.errorDescription
      )
      yield put(onSignInFailure())
    }
  } catch (err) {
    yield yield somethingWentWrongNotification()
    yield put(onSignInFailure())
  }
}

function* oAuth(action) {
  try {
    const res = yield AuthenticationService.oAuth(action.payload)

    if (!res.user.phone) {
      yield put(onSignInFailure())
      return action.payload.history.push('/register', { res })
    }

    if (res.status === 200 || res.status === 201) {
      action.payload.history.push('/')
      yield renderNotification('successNotification', titleTypes.signInSuccess)
      yield put(onSignInSuccess(res))
    } else {
      yield renderNotification(
        'errorNotification',
        titleTypes.signInError,
        res.errors,
        descriptionTypes.errorDescription
      )
      yield put(onSignInFailure())
    }
  } catch (err) {
    yield somethingWentWrongNotification()
    yield put(onSignInFailure())
  }
}

function* getTemporaryToken() {
  try {
    const res = yield AuthenticationService.getTemporaryToken()

    localStorage.setItem('temporaryToken', res.token)
    localStorage.setItem('temporaryTokenExp', res.exp)
    yield put(onGetTemporaryTokenSuccess(res))
  } catch (err) {
    console.log('err: ', err)
  }
}

function* forgotPassword(action) {
  try {
    const res = yield AuthenticationService.forgotPassword(
      action.payload.values
    )

    if (res.status === 200) {
      action.payload.history.push('/')
      notification.success({
        message: 'Success',
        description: 'Check your email!',
      })
      yield put(onForgotPasswordSuccess())
    }

    // yield put(onGetTemporaryTokenSuccess(res))
  } catch (err) {
    yield put(onForgotPasswordFailure())
    console.log('err: ', err)
  }
}

function* recoverPassword(action) {
  try {
    const res = yield AuthenticationService.recoverPassword(
      action.payload.values,
      action.payload.token
    )

    if (res.status === 200) {
      action.payload.history.push('/')
      notification.success({
        message: 'Success',
        description: 'Password reset!',
      })
      yield put(onRecoverPasswordSuccess())
    }
  } catch (err) {
    yield put(onRecoverPasswordFailure())
    console.log('err: ', err)
  }
}

export function* AuthSagas() {
  yield all([
    takeLatest(`${SIGN_UP}${START}`, signUp),
    takeLatest(`${SIGN_IN}${START}`, signIn),
    takeLatest(`${O_AUTH}${START}`, oAuth),
    takeLatest(`${GET_TEMPORARY_TOKEN}${START}`, getTemporaryToken),
    takeLatest(`${FORGOT_PASSWORD}${START}`, forgotPassword),
    takeLatest(`${RECOVER_PASSWORD}${START}`, recoverPassword),
  ])
}

export default reducer
