import { put, call, all, takeLatest, delay } from 'redux-saga/effects'
import { isEmpty } from 'ramda'

import { RoomsService } from '../../services/api'
import { somethingWentWrongNotification } from '../../utils/helpers'

import { SUCCESS, FAILURE, START } from '../constants'

const GET_ROOMS = 'shelest/rooms/GET_ROOMS'
const CLEAR_ROOMS = 'shelest/rooms/CLEAR_ROOMS'
const SET_CURRENT_ROOM = 'shelest/rooms/SET_CURRENT_ROOM'

const initialState = {
  rooms: null,
  areRoomsLoading: true,
  currentRoom: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_ROOMS}${START}`:
      return {
        ...state,
        areRoomsLoading: true,
      }

    case `${GET_ROOMS}${SUCCESS}`:
      return {
        ...state,
        areRoomsLoading: false,
        rooms: action.payload.data,
      }

    case `${GET_ROOMS}${FAILURE}`:
      return {
        ...state,
        areRoomsLoading: false,
      }

    case CLEAR_ROOMS:
      return { ...state, areRoomsLoading: true }

    case SET_CURRENT_ROOM:
      return { ...state, currentRoom: action.payload }
    default:
      return state
  }
}

export const onGetRooms = action => ({
  type: `${GET_ROOMS}${START}`,
  payload: action,
})

const onGetRoomsSuccess = res => ({
  type: `${GET_ROOMS}${SUCCESS}`,
  payload: res,
})

const onGetRoomsFailure = () => ({
  type: `${GET_ROOMS}${FAILURE}`,
})

export const onClearRooms = () => ({
  type: CLEAR_ROOMS,
})

export const onSetCurrentRoom = action => ({
  type: SET_CURRENT_ROOM,
  payload: action,
})

function* getRooms(action) {
  try {
    yield delay(500)

    const res = yield RoomsService.getRooms(action.payload)

    if (isEmpty(res) || !isEmpty(res)) {
      yield put(onGetRoomsSuccess(res))
    } else {
      yield put(onGetRoomsFailure())
    }
  } catch (err) {
    yield somethingWentWrongNotification()
    yield put(onGetRoomsFailure())
  }
}

export function* RoomsSagas() {
  yield all([takeLatest(`${GET_ROOMS}${START}`, getRooms)])
}

export default reducer
