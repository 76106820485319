/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react'
import {
  Modal,
  Form,
  Input,
  DatePicker,
  notification,
  Select,
  Checkbox,
} from 'antd'
import { NavLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import TextArea from 'antd/lib/input/TextArea'

import Button from '../../../components/Button'
import ImagesMasonry from '../../../components/ImagesMasonry'

import useSystemLanguage from '../../../hooks/useSystemLanguage'
import useAbstractionMediaQueries from '../../../hooks/useAbstractionMediaQueries'

import { getLocale } from '../../../utils/helpers'

import {
  ukrainianPhoneNumberPattern,
  shortenFormat,
} from '../../../utils/constants'
import events from '../constants'

import './EventStyles.scss'

const { Option } = Select

const SelectFormatStep = props => {
  const { formData, setFormData, t } = props

return ( <> </> )
}

const SelectDateStep = props => {
  const { formData, setFormData, t } = props

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [endOpen, setEndOpen] = useState(false)
  const { systemLanguage } = useSystemLanguage()

  const locale = getLocale(systemLanguage)

  const disabledEndDate = endValue => {
    if (!endValue || !startDate) {
      return false
    }
    return endValue.valueOf() <= startDate.valueOf()
  }

  const onStartChange = value => {
    setStartDate(value)
    setEndDate(null)
    setFormData({ ...formData, startDate: value })
  }

  const onEndChange = value => {
    setEndDate(value)
    setFormData({ ...formData, endDate: value })
  }

  // const handleStartOpenChange = open => {
  //   console.log('open: ', open)
  //   if (!open) {
  //     if (formData.startDate) {
  //       setEndOpen(true)
  //     }
  //   }
  // }

  const handleEndOpenChange = open => {
    setEndOpen(open)
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
      }}
    >
      <div className="filters-item datepicker event">
        <Form.Item
          label={t('InputLabels.EventStartDate')}
          name="startDate"
          rules={[{ required: true, message: t('Validation.Required') }]}
        >
          <DatePicker
            disabledDate={currentDate =>
              currentDate && currentDate <= moment().subtract(1, 'day')
            }
            format={shortenFormat}
            placeholder={t('InputPlaceholders.From')}
            onChange={onStartChange}
            value={startDate}
            // onOpenChange={handleStartOpenChange}
            locale={locale}
            showNow={false}
            showToday={false}
            showTime={false}
            inputReadOnly
          />
        </Form.Item>
      </div>
      <div className="filters-item datepicker event">
        <Form.Item label={t('InputLabels.EventEndDate')} name="endDate">
          <DatePicker
            disabledDate={disabledEndDate}
            format={shortenFormat}
            placeholder={t('InputPlaceholders.Till')}
            onChange={onEndChange}
            value={endDate}
            onOpenChange={handleEndOpenChange}
            locale={locale}
            showNow={false}
            showToday={false}
            showTime={false}
            inputReadOnly
          />
        </Form.Item>
      </div>
    </div>
  )
}

const GeneralDataStep = props => {
  const { formData, setFormData, t } = props

  console.log('formData.phone: ', formData.phone)
  console.log('formData.email: ', formData.email)
  const [phoneNumber, setPhoneNumber] = useState(formData.phone || '')
  /*const [emailString, setEmail] = useState(formData.email || '')*/

  useEffect(() => {
    if (!formData.phone) {
      if (ukrainianPhoneNumberPattern.test(phoneNumber)) {
        setFormData({ ...formData, phone: `+38${phoneNumber}` })
      } else {
        setFormData({ ...formData, phone: '' })
      }
    }
  }, [phoneNumber])

/* for date picker */
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [endOpen, setEndOpen] = useState(false)
  const { systemLanguage } = useSystemLanguage()

  const locale = getLocale(systemLanguage)

  const disabledEndDate = endValue => {
    if (!endValue || !startDate) {
      return false
    }
    return endValue.valueOf() <= startDate.valueOf()
  }

  const onStartChange = value => {
    setStartDate(value)
    setEndDate(null)
    setFormData({ ...formData, startDate: value })
  }

  const onEndChange = value => {
    setEndDate(value)
    setFormData({ ...formData, endDate: value })
  }

  // const handleStartOpenChange = open => {
  //   console.log('open: ', open)
  //   if (!open) {
  //     if (formData.startDate) {
  //       setEndOpen(true)
  //     }
  //   }
  // }

  const handleEndOpenChange = open => {
    setEndOpen(open)
  }
  /* end date picker */


  return (
    <>
      <Form.Item
        name="firstName"
        rules={[
          {
            required: true,
            message: t('Validation.Required'),
          },
        ]}
        label={t('InputLabels.Name')}
      >
        <Input
          placeholder={t('InputPlaceholders.Name')}
          onChange={e => {
            setFormData({ ...formData, name: e.target.value })
          }}
        />
      </Form.Item>
      {formData?.eventType === 'conference' ||
      formData?.eventType === 'corporate' ? (
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: t('Validation.Required'),
            },
            {
              type: 'email',
              message: t('Validation.InvalidEmailFormat'),
            },
          ]}
          label={t('InputLabels.Email')}
        >
          <Input
            placeholder={t('InputPlaceholders.Email')}
            onChange={e => {
              setFormData({ ...formData, email: e.target.value })
            }}
          />
        </Form.Item>
      ) : null}
      <Form.Item
        name="phoneNumber"
        rules={[
          {
            required: true,
            message: t('Validation.Required'),
          },
          {
            pattern: ukrainianPhoneNumberPattern,
            message: t('Validation.WrongPhoneFormat'),
          },
        ]}
        label={t('InputPlaceholders.Phone')}
      >
        <Input
          maxLength={10}
          addonBefore="+38"
          placeholder={t('InputPlaceholders.Phone')}
          onChange={e => setPhoneNumber(e.target.value)}
        />
      </Form.Item>

              <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: t('Validation.Required'),
            },
            {
              type: 'email',
              message: t('Validation.InvalidEmailFormat'),
            },
          ]}
          label={t('InputLabels.Email')}
        >
          <Input
            placeholder={t('InputPlaceholders.Email')}
            onChange={e => {
              setFormData({ ...formData, email: e.target.value })
            }}
          />
        </Form.Item>






      <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
      }}
    >
      <div className="filters-item datepicker event">
        <Form.Item
          label={t('InputLabels.EventStartDate')}
          name="startDate"
          rules={[{ required: true, message: t('Validation.Required') }]}
        >
          <DatePicker
            disabledDate={currentDate =>
              currentDate && currentDate <= moment().subtract(1, 'day')
            }
            format={shortenFormat}
            placeholder={t('InputPlaceholders.From')}
            onChange={onStartChange}
            value={startDate}
            // onOpenChange={handleStartOpenChange}
            locale={locale}
            showNow={false}
            showToday={false}
            showTime={false}
            inputReadOnly
          />
        </Form.Item>
      </div>
      <div className="filters-item datepicker event">
        <Form.Item label={t('InputLabels.EventEndDate')} name="endDate">
          <DatePicker
            disabledDate={disabledEndDate}
            format={shortenFormat}
            placeholder={t('InputPlaceholders.Till')}
            onChange={onEndChange}
            value={endDate}
            onOpenChange={handleEndOpenChange}
            locale={locale}
            showNow={false}
            showToday={false}
            showTime={false}
            inputReadOnly
          />
        </Form.Item>
      </div>
    </div>






          <Form.Item
        name="guestsNumber"
        rules={[
          {
            required: true,
            message: t('Validation.Required'),
          },
        ]}
        label={t('InputPlaceholders.EventGuestsNumber')}
        onChange={e => {
          setFormData({
            ...formData,
            guestsNumber: e.target.value,
          })
        }}
      >
        <Input
          placeholder={t('InputPlaceholders.EventGuestsNumberPlaceholder')}
        />
      </Form.Item>



    </>
  )
}

// name
// phone
// startDate
// endDate
// email
// isAppartments
// guestsNumber
// eventFormat
// eventType
// isAnimator
// companyName

const formSteps = (props) => {

    const { formData, setFormData, t } = props

    return  (
      <>
      <GeneralDataStep {...props} />
        <Form.Item
          name="eventType"
          rules={[
            {
              required: true,
              message: t('Validation.Required'),
            },
          ]}
          label={t('InputLabels.EventType')}
        >
          <Select
            onChange={e => {
              setFormData({ ...formData, eventType: e })
            }}
            placeholder={t('InputPlaceholders.EventType')}
            
          >
            <Option value="wedding">{t('InputPlaceholders.Wedding')}</Option>
            <Option value="birthday">{t('InputPlaceholders.Birthday')}</Option>
            <Option value="birthdayChild">
              {t('InputPlaceholders.BirthdayChild')}
            </Option>
            <Option value="corporate">
              {t('InputPlaceholders.CorporateParty')}
            </Option>
            <Option value="conference">
              {t('InputPlaceholders.Conference')}
            </Option>
            <Option value="other">{t('InputPlaceholders.OtherEvent')}</Option>
          </Select>
        </Form.Item>


      </>
    )
}

const ContactUsModal = ({ onSubmit, setVisible, isLoading }) => {
  const [formData, setFormData] = useState({})
  const [formStep, setFormStep] = useState(0)

  const { t } = useTranslation()

  const whetherButtonIsDisabled = () => {


        if ( !formData?.eventType ) {
          return true
        }

        if (!formData?.startDate) {
          return true
        }

        if (
          !formData?.name ||
          !formData.phone ||
          !formData.email
        ) {
          return true
        }
        return false

  }

  //const currentStep = formSteps[formStep]({
  const currentStep = formSteps({
    formStep,
    formData,
    setFormData,
    setFormStep,
    t,
  })

  return (
    <Modal
      title={t('ContactUsEventModal.ContactUsHeading')}
      visible
      onOk={() => {}}
      onCancel={() => {
        setVisible(false)
      }}
      footer={[]}
    >
      <Form onFinish={onSubmit} layout="vertical" className="contact-us-form">
        {currentStep}
      </Form>
      <div className="buttons-block">
  
        <Button
          htmlType="submit"
          classNames="btn-mid-pad small-btn btn-green h-40 w-100"
          onClick={() => {onSubmit(formData)}}
          isDisabled={whetherButtonIsDisabled()}
          isLoading={isLoading}
          buttonId="event_contact_us_modal_next_step_button"
        >
          {t('ContactUsEventModal.FinishForm')}
        </Button>
      </div>
    </Modal>
  )
}

export default ContactUsModal
