import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Input, Form, notification, Collapse, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'

import Button from '../../components/Button'
import ImagesMasonry from '../../components/ImagesMasonry'
import HeroImage from '../../components/HeroImage'

import useAbstractionMediaQueries from '../../hooks/useAbstractionMediaQueries'

import ToInvestBG from '../../static/images/to-invest/ToInvestBG.png'
import Town1 from '../../static/images/to-invest/Town1.jpg'
import Town2 from '../../static/images/to-invest/Town2.jpg'

import {
  descriptionTypes,
  ukrainianPhoneNumberPattern,
} from '../../utils/constants'
import { slides, toInvest } from './constants'

import './ToInvestStyles.scss'
import { Carousel } from 'react-responsive-carousel'

const { Panel } = Collapse

const ToInvest = () => {
  const [form] = Form.useForm()
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isLoading, setLoading] = useState(false)
  const sendDataToGTM = useGTMDispatch()

  const history = useHistory()
  const { t, i18n } = useTranslation()
  const { language } = i18n

  const {
    isMobile,
    isTablet,
    isBigTable,
    isBiggestTable,
  } = useAbstractionMediaQueries()

  const onFinish = values => {
    const phoneFinal = `38${values.phone}`

    setLoading(true)

    return fetch('https://api.shelest.ua/etc/investments', {
      body: JSON.stringify({
        ...values,
        phone: +phoneFinal,
        // utmSource: localStorage.getItem('utm_source'),
        // utmMedium: localStorage.getItem('utm_medium'),
        // utmCampaign: localStorage.getItem('utm_campaign'),
        // utmContent: localStorage.getItem('utm_content'),
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(() => {
        sendDataToGTM({
          event: 'FormSentHutir',
          eventType: 'FormSent',
        })
        notification.success({
          message: t('Notification.Description.InvestRequestSuccess'),
        })
        form.resetFields()
      })
      .catch(err => {
        console.log('err: ', err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const carouselSlides = slides.map(slide => (
    <div key={slide} className="to-invest-carousel-slide">
      <img src={slide} />
    </div>
  ))

  const CarouselComponent = (
    <Carousel
      swipeable
      emulateTouch
      showIndicators={false}
      showThumbs={false}
      showStatus={false}
      className="to-invest-carousel"
      autoPlay={false}
      showArrows={false}
      centerMode
      centerSlidePercentage={80}
    >
      {carouselSlides}
    </Carousel>
  )

  const informationBlocks = {
    ukr: (
      <div className="to-invest-wrapper">
        <div className="to-invest">
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                marginTop: 35,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div style={{ flex: 2 }}>
                <p className="to-invest-description">
                  Улюблене місце відпочинку відтепер ще й інвестиційний проєкт.
                  Уявіть величезний простір без парканів з басейном, баром,
                  рестораном і майданчиками для дітлахів. Це SHELEST Hutir.
                </p>
                <p className="to-invest-description">
                  Ми зводимо 41 великих будинків, що складаються з 88 юнітів.
                  Кожен юніт — це 75 м² зі спальнею, дитячою та ванною
                  кімнатами, вітальнею-кухнею, терасою та зоною гриль.
                </p>
                <p className="to-invest-description">
                  Ви купуєте й отримуєте у власність земельну ділянку та юніт з
                  ремонтом і меблями. Як використовувати нерухомість, вирішуєте
                  ви: як особисту заміську дачу або ж під короткострокову
                  оренду. SHELEST бере на себе всі зобов’язання з керування й
                  залучення гостей. Дохідність проєкту — 9% річних.
                </p>
                <p className="to-invest-description">
                  Інвестуйте та не партесь — ми про все потурбуємось. Станьте
                  частиною SHELEST Hutir.
                </p>
              </div>
            </div>
          </div>
          {/* <ImagesMasonry masonryConfig={toInvest.masonryConfig} /> */}
          {/* <div className="to-invest-represent-images">
            <img src={Town1} />
            <img src={Town2} />
          </div> */}
          {CarouselComponent}
          <div>
            <strong className="to-invest-description">
              Не всі можуть дивитися в завтрашній день. А ви можете.
            </strong>
            <div className="to-invest-form-wrapper">
              <div className="to-invest-form">
                <Form onFinish={onFinish} form={form}>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: t('Validation.Required'),
                      },
                    ]}
                  >
                    <Input placeholder={t('InputPlaceholders.FullNameShort')} />
                  </Form.Item>
                  <Form.Item
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: t('Validation.Required'),
                      },
                      {
                        pattern: ukrainianPhoneNumberPattern,
                        message: t('Validation.WrongPhoneFormat'),
                      },
                    ]}
                  >
                    <Input
                      maxLength={10}
                      addonBefore="+38"
                      placeholder={t('InputPlaceholders.Phone')}
                      onChange={e => setPhoneNumber(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: t('Validation.Required'),
                      },
                      {
                        type: 'email',
                        message: t('Validation.InvalidEmailFormat'),
                      },
                    ]}
                  >
                    <Input placeholder={t('InputPlaceholders.Email')} />
                  </Form.Item>
                  <div className="actions-wrapper">
                    <Button classNames="little-btn btn-green">
                      {t('ShelestHome.Form.SubmitButton')}
                    </Button>
                    <a
                      href="https://shelest.ua/SHELEST_town.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        classNames="btn-mid-pad little-btn btn-blue"
                        htmlType="button"
                      >
                        {t('ShelestHome.Form.DownloadPDFButton')}
                      </Button>
                    </a>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
    ru: (
      <div className="to-invest-wrapper">
        <div className="to-invest">
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                marginTop: 35,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div style={{ flex: 2 }}>
                <p className="to-invest-description">
                  Приглашаем стать инвестором комплекса Shelest под
                  гарантированные 8,5% годовых в иностранной валюте на срок до 3
                  лет.
                </p>
                <br />
                <p className="to-invest-description">
                  Средства инвесторов будут направлены на застройку комплекса
                  инфраструктурными объектами, коттеджами и улучшение
                  благоустройства территории с целью развития и популяризации
                  комплекса.
                </p>
                <br />
                <p className="to-invest-description">
                  Развивайте любимое место отдыха вместе, получая от этого
                  прибыль!
                </p>
                <br />
                <p className="to-invest-description">
                  Для дополнительной информации&nbsp;
                  <br />
                  <a href="tel:380986972884" className="to-invest-description">
                    +380 98 697 28 84
                  </a>
                </p>
              </div>
              <div>
                <strong className="to-invest-description">
                  Оставить заявку
                </strong>
                <div className="to-invest-form-wrapper">
                  <div className="to-invest-form">
                    <Form onFinish={onFinish} form={form}>
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: t('Validation.Required'),
                          },
                        ]}
                      >
                        <Input
                          placeholder={t('InputPlaceholders.FullNameShort')}
                        />
                      </Form.Item>
                      <Form.Item
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: t('Validation.Required'),
                          },
                          {
                            pattern: ukrainianPhoneNumberPattern,
                            message: t('Validation.WrongPhoneFormat'),
                          },
                        ]}
                      >
                        <Input
                          maxLength={10}
                          addonBefore="+38"
                          placeholder={t('InputPlaceholders.Phone')}
                          onChange={e => setPhoneNumber(e.target.value)}
                        />
                      </Form.Item>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: t('Validation.Required'),
                          },
                          {
                            type: 'email',
                            message: t('Validation.InvalidEmailFormat'),
                          },
                        ]}
                      >
                        <Input placeholder={t('InputPlaceholders.Email')} />
                      </Form.Item>
                      <Form.Item
                        name="investments"
                        rules={[
                          {
                            required: true,
                            message: t('Validation.Required'),
                          },
                          {
                            pattern: /^\d+$/,
                            message: t('Validation.OnlyNumbers'),
                          },
                        ]}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Input
                            maxLength={10}
                            placeholder={t(
                              'InputPlaceholders.InvestmentsAmount'
                            )}
                          />
                          <span style={{ paddingLeft: 10 }}>USD</span>
                        </div>
                      </Form.Item>
                      <Button
                        // htmlType="submit"
                        classNames="btn-mid-pad small-btn btn-green h-40 w-100"
                        isLoading={isLoading}
                        buttonId="to_invest_button"
                      >
                        {t('ToInvest.ToInvestButton')}
                      </Button>
                    </Form>
                    <div style={{ marginTop: 15 }}>
                      <a
                        href="https://shelest.ua/Investments.pdf"
                        target="_blank"
                      >
                        <Button
                          classNames="btn-mid-pad small-btn btn-blue h-40 w-100"
                          buttonId="to_invest_presentation_button"
                          buttonId="to_invest_presentation_button"
                        >
                          {t('ToInvest.PresentationForInvestorsButton')}
                        </Button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <ImagesMasonry masonryConfig={toInvest.masonryConfig} /> */}
        </div>
      </div>
    ),
    en: (
      <div className="to-invest-wrapper">
        <div className="to-invest">
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                marginTop: 35,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div style={{ flex: 2 }}>
                <p className="to-invest-description">
                  Your favored getaway spot, now an investment opportunity.
                </p>
                <p className="to-invest-description">
                  Imagine an expansive, fenceless space with a pool, bar,
                  restaurant, and playgrounds for children. Welcome to SHELEST
                  Hutir.
                </p>
                <p className="to-invest-description">
                  We're constructing 41 large buildings, totaling 88 units. Each
                  75 m2 unit includes a bedroom, children's area, bathroom,
                  living room-kitchen, terrace, and grill zone. Upon purchase,
                  you gain ownership of a plot of land and a fully renovated,
                  furnished unit. You dictate its use: a personal country house
                  or for short-term rentals. SHELEST assumes responsibility for
                  property management and guest attraction, ensuring a 9% annual
                  project profitability.
                </p>
                <p className="to-invest-description">
                  Invest worry-free – we handle it all. Join the SHELEST Hutir
                  community. Predicting the future isn't everyone's forte. But
                  it can be yours
                </p>
              </div>
              {/* <div className="to-invest-represent-images">
                <img src={Town1} />
                <img src={Town2} />
              </div> */}
              {CarouselComponent}
              <div>
                <strong className="to-invest-description">
                  invest in shelest and earn
                </strong>
                <div className="to-invest-form-wrapper">
                  <div className="to-invest-form">
                    <Form onFinish={onFinish} form={form}>
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: t('Validation.Required'),
                          },
                        ]}
                      >
                        <Input
                          placeholder={t('InputPlaceholders.FullNameShort')}
                        />
                      </Form.Item>
                      <Form.Item
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: t('Validation.Required'),
                          },
                          {
                            pattern: ukrainianPhoneNumberPattern,
                            message: t('Validation.WrongPhoneFormat'),
                          },
                        ]}
                      >
                        <Input
                          maxLength={10}
                          addonBefore="+38"
                          placeholder={t('InputPlaceholders.Phone')}
                          onChange={e => setPhoneNumber(e.target.value)}
                        />
                      </Form.Item>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: t('Validation.Required'),
                          },
                          {
                            type: 'email',
                            message: t('Validation.InvalidEmailFormat'),
                          },
                        ]}
                      >
                        <Input placeholder={t('InputPlaceholders.Email')} />
                      </Form.Item>
                      <div className="actions-wrapper">
                        <Button classNames="little-btn btn-green">
                          {t('ShelestHome.Form.SubmitButton')}
                        </Button>
                        <a
                          href="https://shelest.ua/SHELEST_town.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button
                            classNames="btn-mid-pad little-btn btn-blue"
                            htmlType="button"
                          >
                            {t('ShelestHome.Form.DownloadPDFButton')}
                          </Button>
                        </a>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <ImagesMasonry masonryConfig={toInvest.masonryConfig} /> */}
        </div>
      </div>
    ),
  }

  return (
    <>
      <HeroImage
        backgroundImage={ToInvestBG}
        heroText={t('Home.Navigation.ToInvestHeroTitle')}
      >
        <a
          href="https://shelest.ua/SHELEST_town.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            classNames="btn-mid-pad little-btn btn-blue"
            htmlType="button"
          >
            {t('ToInvest.MoreAboutProject')}
          </Button>
        </a>
      </HeroImage>
      {informationBlocks[language]}
    </>
  )
}

export default ToInvest
