import ToInvest1 from '../../static/images/to-invest/ToInvest1.jpg'
import ToInvest2 from '../../static/images/to-invest/ToInvest2.jpg'
import ToInvest3 from '../../static/images/to-invest/ToInvest3.jpg'
import ToInvest4 from '../../static/images/to-invest/ToInvest4.jpg'
import ToInvest5 from '../../static/images/to-invest/ToInvest5.jpg'
import ToInvest6 from '../../static/images/to-invest/ToInvest6.jpg'

import Slide1 from '../../static/images/to-invest/car-1.jpg'
import Slide2 from '../../static/images/to-invest/car-2.jpg'
import Slide3 from '../../static/images/to-invest/car-3.jpg'
import Slide4 from '../../static/images/to-invest/car-4.jpg'
import Slide5 from '../../static/images/to-invest/car-5.jpg'
import Slide6 from '../../static/images/to-invest/car-6.jpg'
import Slide7 from '../../static/images/to-invest/car-7.jpg'
import Slide8 from '../../static/images/to-invest/car-8.jpg'

import { ukrainianPhoneNumberPattern } from '../../utils/constants'

export const slides = [
  Slide1,
  Slide2,
  Slide3,
  Slide4,
  Slide5,
  Slide6,
  Slide7,
  Slide8,
]

export const toInvest = {
  masonryConfig: {
    parentConfig: {
      rows: 4,
      cols: 3,
      maxHeight: 1500,
    },
    images: [
      {
        image: ToInvest1,
        classes: 'row-span-1 col-span-2',
      },
      {
        image: ToInvest2,
        classes: 'row-span-1 col-span-2 row-start-2 col-start-1',
      },
      { image: ToInvest3, classes: 'row-span-2 col-span-1 col-start-3' },
      { image: ToInvest4, classes: 'row-span-2 col-span-1 row-start-1' },
      { image: ToInvest6, classes: 'row-span-1 col-span-2 row-start-1' },
      { image: ToInvest5, classes: 'row-span-1 col-span-2 row-start-2' },
    ],
  },
}

export const formItems = [
  { label: 'YourName', name: 'name', placeholder: 'YourName', type: 'text' },
  {
    label: 'EmailEng',
    name: 'email',
    placeholder: 'EmailEng',
    type: 'email',
    rules: [{ type: 'email', message: 'InvalidEmailFormat' }],
  },
  {
    label: 'Phone',
    name: 'phone',
    placeholder: 'Phone',
    type: 'phone',
    rules: [
      {
        type: 'phone',
        message: 'InvalidPhoneFormat',
        pattern: ukrainianPhoneNumberPattern,
      },
    ],
  },
  {
    label: 'InvestmentsAmount',
    name: 'investments',
    placeholder: 'InvestmentsAmount',
    type: 'number',
  },
]
