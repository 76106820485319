import { put, all, takeLatest, delay, take } from 'redux-saga/effects'
import { uid } from 'react-uid'
import { notification } from 'antd'
import { isEmpty, isNil, takeLast } from 'ramda'

import { onGetTemporaryToken } from '../auth/auth'
import { CartService } from '../../services/api'

import { SUCCESS, FAILURE, START } from '../constants'

const ADD_ITEM = 'shelest/cart/ADD_ITEM'
const REMOVE_ITEM = 'shelest/cart/REMOVE_ITEM'
const PAY_CHECK = 'shelest/cart/PAY_CHECK'
const GET_ITEMS = 'shelest/cart/GET_ITEMS'
const GET_CART = 'shelest/cart/GET_CART'
const ADD_HER_HIS = 'shelest/cart/ADD_HER_HIS'
const REMOVE_HER_HIS = 'shelest/cart/REMOVE_HER_HIS'
const GET_USER_BOOKING_HISTORY = 'shelest/auth/GET_USER_BOOKING_HISTORY'
const VERIFY_RESERVATION = 'shelest/cart/VERIFY_RESERVATION'
const RESET_RECENTLY_ADDED_HOTEL = 'shelest/cart/RESET_RECENTLY_ADDED_HOTEL'
const SET_REDIRECT_TO_CART = 'shelest/cart/SET_REDIRECT_TO_CART'

const initialState = {
  cartItemsList: [],
  cartItemsListCount: 0,
  totalPrice: 0,
  paymentAmount: 0,
  goodsList: [],
  areGoodsLoading: false,
  isCartLoading: true,
  isProcessing: false,
  cartId: null,
  isBookingHistoryLoading: true,
  bookingHistoryList: null,
  recentlyAddedHotel: null,
  isRedirectToCart: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REDIRECT_TO_CART:
      return {
        ...state,
        isRedirectToCart: action.payload,
      }
    case `${ADD_ITEM}${START}`:
      return {
        ...state,
        isProcessing: true,
        ...(action.payload.productType !== 'goods'
          ? { recentlyAddedHotel: action.payload.product_id }
          : {}),
      }

    case `${ADD_ITEM}${SUCCESS}`:
      return {
        ...state,
        isProcessing: false,
        cartItemsList: action.payload.cart.products,
        cartItemsListCount: action.payload.cart.payment_data.total_amount,
        totalPrice: action.payload.cart.payment_data.total_price,
        paymentAmount: action.payload.cart.payment_data.payment_amount,
        cartId: action.payload.cart._id,
      }

    case `${ADD_ITEM}${FAILURE}`:
      return {
        ...state,
        isProcessing: false,
      }

    case `${REMOVE_ITEM}${START}`:
      return {
        ...state,
        isProcessing: true,
      }

    case `${REMOVE_ITEM}${SUCCESS}`:
      return {
        ...state,
        isProcessing: false,
        cartItemsList: action.payload.cart.products,
        cartItemsListCount: action.payload.cart.payment_data.total_amount,
        totalPrice: action.payload.cart.payment_data.total_price,
        paymentAmount: action.payload.cart.payment_data.payment_amount,
        cartId: action.payload.cart._id,
      }

    case `${REMOVE_ITEM}${FAILURE}`:
      return {
        ...state,
        isProcessing: false,
      }

    case `${ADD_HER_HIS}${START}`:
      return {
        ...state,
        isProcessing: true,
      }

    case `${ADD_HER_HIS}${SUCCESS}`:
      return {
        ...state,
        isProcessing: false,
        cartItemsList: action.payload.cart.products,
        cartItemsListCount: action.payload.cart.payment_data.total_amount,
        totalPrice: action.payload.cart.payment_data.total_price,
        paymentAmount: action.payload.cart.payment_data.payment_amount,
        cartId: action.payload.cart._id,
      }

    case `${ADD_HER_HIS}${FAILURE}`:
      return {
        ...state,
        isProcessing: false,
      }

    case `${REMOVE_HER_HIS}${START}`:
      return {
        ...state,
        isProcessing: true,
      }

    case `${REMOVE_HER_HIS}${SUCCESS}`:
      return {
        ...state,
        isProcessing: false,
        cartItemsList: action.payload.cart.products,
        cartItemsListCount: action.payload.cart.payment_data.total_amount,
        totalPrice: action.payload.cart.payment_data.total_price,
        paymentAmount: action.payload.cart.payment_data.payment_amount,
        cartId: action.payload.cart._id,
      }

    case `${REMOVE_HER_HIS}${FAILURE}`:
      return {
        ...state,
        isProcessing: false,
      }

    case `${GET_ITEMS}${START}`:
      return {
        ...state,
        areGoodsLoading: true,
      }

    case `${GET_ITEMS}${SUCCESS}`:
      return {
        ...state,
        goodsList: action.payload.sort(
          (a, b) => Number(a.order) - Number(b.order)
        ),
        areGoodsLoading: false,
      }

    case `${GET_CART}${START}`:
      return {
        ...state,
        cartItemsList: [],
        cartItemsListCount: 0,
        totalPrice: 0,
        paymentAmount: 0,
        cartId: null,
        isCartLoading: true,
      }

    case `${GET_CART}${SUCCESS}`:
      return {
        ...state,
        isCartLoading: false,
        cartItemsList: action.payload.cart.products,
        cartItemsListCount: action.payload.cart.payment_data.total_amount,
        totalPrice: action.payload.cart.payment_data.total_price,
        paymentAmount: action.payload.cart.payment_data.payment_amount,
        cartId: action.payload.cart._id,
      }

    case `${GET_CART}${FAILURE}`:
      return { ...state, isCartLoading: false }

    case `${GET_USER_BOOKING_HISTORY}${START}`:
      return {
        ...state,
        isBookingHistoryLoading: true,
      }

    case `${GET_USER_BOOKING_HISTORY}${SUCCESS}`:
      return {
        ...state,
        isBookingHistoryLoading: false,
        bookingHistoryList: action.payload.orders.sort(
          (a, b) => Number(b.invoice_id) - Number(a.invoice_id)
        ),
      }

    case `${GET_USER_BOOKING_HISTORY}${FAILURE}`:
      return {
        ...state,
        isBookingHistoryLoading: false,
      }

    case RESET_RECENTLY_ADDED_HOTEL:
      return {
        ...state,
        recentlyAddedHotel: null,
      }

    default:
      return state
  }
}

export const onAddItem = action => ({
  type: `${ADD_ITEM}${START}`,
  payload: action,
})

const onAddItemSuccess = action => ({
  type: `${ADD_ITEM}${SUCCESS}`,
  payload: action,
})

const onAddItemFailure = () => ({
  type: `${ADD_ITEM}${FAILURE}`,
})

export const onRemoveItem = action => ({
  type: `${REMOVE_ITEM}${START}`,
  payload: action,
})

const onRemoveItemSuccess = action => ({
  type: `${REMOVE_ITEM}${SUCCESS}`,
  payload: action,
})

const onRemoveItemFailure = () => ({
  type: `${REMOVE_ITEM}${FAILURE}`,
})

export const onAddHerHisItem = action => ({
  type: `${ADD_HER_HIS}${START}`,
  payload: action,
})

const onAddHerHisSuccess = action => ({
  type: `${ADD_HER_HIS}${SUCCESS}`,
  payload: action,
})

const onAddHerHisFailure = () => ({
  type: `${ADD_HER_HIS}${FAILURE}`,
})

export const onRemoveHerHisItem = action => ({
  type: `${REMOVE_HER_HIS}${START}`,
  payload: action,
})

const onRemoveHerHisSuccess = action => ({
  type: `${REMOVE_HER_HIS}${SUCCESS}`,
  payload: action,
})

const onRemoveHerHisFailure = () => ({
  type: `${REMOVE_HER_HIS}${FAILURE}`,
})

export const onPayCheck = action => ({
  type: `${PAY_CHECK}${START}`,
  payload: action,
})

export const onGetItems = () => ({
  type: `${GET_ITEMS}${START}`,
})

const onGetItemsSuccess = res => ({
  type: `${GET_ITEMS}${SUCCESS}`,
  payload: res,
})

export const onGetCart = action => ({
  type: `${GET_CART}${START}`,
  payload: action,
})

const onGetCartSuccess = action => ({
  type: `${GET_CART}${SUCCESS}`,
  payload: action,
})

const onGetCartError = () => ({
  type: `${GET_CART}${FAILURE}`,
})

export const onGetBookingHistory = action => ({
  type: `${GET_USER_BOOKING_HISTORY}${START}`,
  payload: action,
})

const onGetBookingHistorySuccess = res => ({
  type: `${GET_USER_BOOKING_HISTORY}${SUCCESS}`,
  payload: res,
})

const onGetBookingHistoryFailure = () => ({
  type: `${GET_USER_BOOKING_HISTORY}${FAILURE}`,
})

export const onVerifyReservation = action => ({
  type: `${VERIFY_RESERVATION}${START}`,
  payload: action,
})

const onVerifyReservationSuccess = () => ({
  type: `${VERIFY_RESERVATION}${SUCCESS}`,
})

const onVerifyReservationFailure = () => ({
  type: `${VERIFY_RESERVATION}${FAILURE}`,
})

export const resetRecentlyAddedHotel = () => ({
  type: RESET_RECENTLY_ADDED_HOTEL,
})

export const setRedirectToCart = action => ({
  type: SET_REDIRECT_TO_CART,
  payload: action,
})

function* payCheck(action) {
  const { t } = action.payload

  if (!action.payload.user) {
    action.payload.history.push('/register')

    yield put(setRedirectToCart(true))

    return notification.error({
      message: t('Notification.Description.LoginBeforeProceed'),
    })
  }

  if (!action.payload.user.phone) {
    return notification.error({
      message: t('Notification.Description.AddPhoneBeforeProceed'),
    })
  }

  if (action.payload.paymentAmount >= 29990 || !action.payload.paymentAmount) {
    return notification.error({
      message: t('Notification.Description.ExceedPrice'),
    })
  }

  try {
    const tokenType = action.payload.isAuthenticated
      ? 'token'
      : 'temporaryToken'
    const token = localStorage.getItem(tokenType)
    const res = yield CartService.payCheck(action.payload, token)

    if (res) {
      const div = document.createElement('div')

      div.innerHTML = res.html
      div.style.display = 'none'
      document.body.appendChild(div)
      const form = div.childNodes[0]

      // form.setAttribute('target', '_blank')
      form.submit()
      div.remove()
    }
  } catch (err) {
    console.log('err: ', err)
  }
}

function* addItem(action) {
  try {
    yield delay(500)
    const tokenType = action.payload.isAuthenticated
      ? 'token'
      : 'temporaryToken'

    let token = localStorage.getItem(tokenType)

    if (isEmpty(token) || isNil(token)) {
      yield put(onGetTemporaryToken())
      yield take('shelest/auth/GET_TEMPORARY_TOKEN_SUCCESS')
    }

    token = localStorage.getItem(tokenType)

    const res = yield CartService.addItem(action.payload, token)

    if (res) {
      if (action.payload.hideModal) {
        action.payload.hideModal()
      }
      yield put(
        onAddItemSuccess({ ...res, product_id: action.payload.product_id })
      )
    }
  } catch (err) {
    yield put(onAddItemFailure())
  }
}

function* removeItem(action) {
  try {
    yield delay(500)
    const tokenType = action.payload.isAuthenticated
      ? 'token'
      : 'temporaryToken'

    const token = localStorage.getItem(tokenType)

    const res = yield CartService.removeItem(action.payload, token)

    yield put(onRemoveItemSuccess(res))
  } catch (err) {
    yield put(onRemoveItemFailure())
  }
}

function* getItems() {
  try {
    const res = yield CartService.getItems()

    yield delay(1000)
    yield put(onGetItemsSuccess(res))
  } catch (err) {
    console.log('err: ', err)
  }
}

function* getCart(action) {
  try {
    yield delay(1000)
    const tokenType = action.payload.isAuthenticated
      ? 'token'
      : 'temporaryToken'
    const token = localStorage.getItem(tokenType)

    if (isEmpty(token) || isNil(token)) {
      yield put(onGetCartError())
    }

    const res = yield CartService.getCart({ token })

    yield put(onGetCartSuccess(res))
  } catch (err) {
    localStorage.removeItem('temporaryToken')
    localStorage.removeItem('temporaryTokenExp')
    yield put(onGetCartError())
  }
}

function* getBookingHistory(action) {
  try {
    yield delay(1000)
    const token = localStorage.getItem('token')
    const res = yield CartService.getBookingHistory({
      token,
      isAdmin: action.payload.isAdmin,
    })

    yield put(onGetBookingHistorySuccess(res))
  } catch (err) {
    yield put(onGetBookingHistoryFailure())
  }
}

function* verifyReservation(action) {
  try {
    yield delay(1000)
    yield CartService.verifyReservation(action.payload)

    action.payload.history.push('/')
    notification.success({
      message: `Verified`,
    })

    yield put(onVerifyReservationSuccess())
  } catch (err) {
    notification.error({
      message: `Something went wrong`,
    })
    yield put(onVerifyReservationFailure())
  }
}

function* addHerHis(action) {
  try {
    const tokenType = action.payload.isAuthenticated
      ? 'token'
      : 'temporaryToken'

    let token = localStorage.getItem(tokenType)

    if (isEmpty(token) || isNil(token)) {
      yield put(onGetTemporaryToken())
      yield take('shelest/auth/GET_TEMPORARY_TOKEN_SUCCESS')
    }

    token = localStorage.getItem(tokenType)

    const res = yield CartService.addSpa(action.payload, token)

    yield put(onAddHerHisSuccess(res))
  } catch (err) {
    notification.error({
      message: `Something went wrong`,
    })

    yield put(onAddHerHisFailure())
  }
}

function* removeHerHis(action) {
  try {
    yield delay(500)
    const tokenType = action.payload.isAuthenticated
      ? 'token'
      : 'temporaryToken'

    const token = localStorage.getItem(tokenType)

    const res = yield CartService.removeSpa(action.payload, token)

    yield put(onRemoveHerHisSuccess(res))
  } catch (err) {
    notification.error({
      message: `Something went wrong`,
    })

    yield put(onRemoveHerHisFailure())
  }
}

export function* CartSagas() {
  yield all([
    takeLatest(`${PAY_CHECK}${START}`, payCheck),
    takeLatest(`${GET_ITEMS}${START}`, getItems),
    takeLatest(`${ADD_ITEM}${START}`, addItem),
    takeLatest(`${ADD_HER_HIS}${START}`, addHerHis),
    takeLatest(`${REMOVE_HER_HIS}${START}`, removeHerHis),
    takeLatest(`${GET_CART}${START}`, getCart),
    takeLatest(`${REMOVE_ITEM}${START}`, removeItem),
    takeLatest(`${GET_USER_BOOKING_HISTORY}${START}`, getBookingHistory),
    takeLatest(`${VERIFY_RESERVATION}${START}`, verifyReservation),
  ])
}

export default reducer
