import React from 'react'
import { useTranslation } from 'react-i18next'

const NotificationDescription = ({ descriptionItems, descriptionType }) => {
  const { t } = useTranslation()

  const renderDescription = () => {
    switch (descriptionType) {
      case 'error':
        return descriptionItems && descriptionItems.length
          ? descriptionItems.map(({ msg }) => (
              <p key={msg}>
                {msg.includes(' ') ? msg : t(`Notification.Description.${msg}`)}
              </p>
            ))
          : null
      case 'success':
        return
      default:
        return
    }
  }

  return renderDescription()
}

export default NotificationDescription
