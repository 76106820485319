import React from 'react'

import './DoNothing.scss'

const DoNothingItem = ({ item }) => {
  const imageUrl = item.image
  const title = item.title
  const desc = item.description
  const key = item.key

  return (
    <div className="do-nothing-link" key={key}>
      <img src={imageUrl} />
      <h2>{title}</h2>
      <p>{desc}</p>
    </div>
  )
}

export default DoNothingItem
