import React from 'react'
import { useTranslation } from 'react-i18next'

const Loading = () => {
  const { t } = useTranslation()

  return <span>{t('General.Loading')}</span>
}

export default Loading
