import { all } from 'redux-saga/effects'

import { GoodsSagas } from './goods/goods'
import { RoomsSagas } from './rooms/rooms'
import { CartSagas } from './cart/cart'
import { AuthSagas } from './auth/auth'
import { BookingHotelSagas } from './booking/hotel/hotel'

export default function* rootSaga() {
  yield all([
    AuthSagas(),
    RoomsSagas(),
    GoodsSagas(),
    CartSagas(),
    BookingHotelSagas(),
  ])
}
