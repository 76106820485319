import { all, takeLatest } from 'redux-saga/effects'

// import { SUCCESS, FAILURE } from '../constants'

const GET_GOODS = 'shelest/goods/GET_GOODS'

const initialState = {
  goodsList: null,
  areGoodsLoading: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GOODS:
      return {
        ...state,
        areGoodsLoading: true,
      }
    default:
      return state
  }
}

// export const setGalleryFilter = action => ({
//   type: SET_GALLERY_FILTER,
//   payload: action,
// })

function* getGoods(action) {
  try {
  } catch (err) {}
}

export function* GoodsSagas() {
  yield all([takeLatest(GET_GOODS, getGoods)])
}

export default reducer
