import React, { useEffect, Suspense } from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'
import { useGA4React } from 'ga-4-react'
import { connect } from 'react-redux'
// import { ThemeProvider } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'

import BookingLayout from './BookingLayout/BookingLayout'
import ProtectedRoute from '../components/ProtectedRoute/ProtectedRoute'
import PrivateRoute from '../components/PrivateRoute/PrivateRoute'
import NavBar from '../components/Navbar'
import Loading from '../components/Loading'
import Footer from '../components/Footer'
import Disclaimer from '../components/Disclaimer'

import { onTokenRefreshSession } from '../redux/auth/auth'
import { onGetCart } from '../redux/cart/cart'

// import { lightTheme, darkTheme } from '../style/theme'
// import GlobalStyles from '../style/global_theme'
// import useDarkMode from '../style/useDarkMode'
import { routes, privateRoutes, protectedRoutes } from '../router'

const Container = ({ onTokenRefreshSession, onGetCart, isAuthenticated }) => {
  const token = localStorage.getItem('token')
  const language = localStorage.getItem('prefferedLanguage')

  //const gtmParams = { id: 'GTM-PB8DJCGG', dataLayerName: 'ShelestDataLayer' }
  const gtmParams = { id: 'GTM-PB8DJCGG', dataLayerName: 'dataLayer' }
  // const [theme, toggleTheme] = useDarkMode()
  const { t, i18n } = useTranslation()
  const { pathname } = useLocation()
  // const ga = useGA4React('G-YYV43NHGRL')

  useEffect(() => {
    if (!language) {
      localStorage.setItem('prefferedLanguage', 'ukr')
    }
    i18n.changeLanguage(language)
  }, [language])

  useEffect(() => {
    if (token) onTokenRefreshSession({ token })
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    onGetCart({ isAuthenticated })
  }, [isAuthenticated])

  // useEffect(() => {
  //   if (ga) {
  //     ga.pageview(pathname)
  //   }
  // }, [pathname])

  const isPopupClicked = Boolean(
    Number(sessionStorage.getItem('isPopupClicked'))
  )

  return (
    // <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
    //   <>
    //     <GlobalStyles />
    <GTMProvider state={gtmParams}>
      <NavBar />
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path="/booking">
            <BookingLayout />
          </Route>
          {protectedRoutes.map(route => (
            <ProtectedRoute key={route.path} {...route} />
          ))}
          {privateRoutes.map(route => (
            <PrivateRoute key={route.path} {...route} />
          ))}
          {routes.map(route => (
            <Route key={route.path} {...route} />
          ))}
        </Switch>
      </Suspense>
      {<Footer />}
      {!isPopupClicked ? <Disclaimer /> : null}
    </GTMProvider>
    // </ThemeProvider>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
})

const mapDispatchToProps = dispatch => ({
  onTokenRefreshSession: action => dispatch(onTokenRefreshSession(action)),
  onGetCart: action => dispatch(onGetCart(action)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Container)
