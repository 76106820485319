import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { isEmpty, isNil } from 'ramda'
import { useTranslation } from 'react-i18next'
import { Table, notification } from 'antd'
import moment from 'moment'
import { useLocation } from 'react-router-dom'

import Loader from '../../../components/Loader'

import { onGetBookingHistory } from '../../../redux/cart/cart'

import './BookingHistoryStyles.scss'

const statusesStylenames = {
  success: 'green',
  payment_waiting: 'yellow',
  canceled: 'red',
  failure: 'red',
}

const BookingHistoryList = ({ isLoading, list }) => {
  const { t, i18n } = useTranslation()

  const statusesTranslations = {
    success: t('BookingHistory.Statuses.Success'),
    payment_waiting: t('BookingHistory.Statuses.Pending'),
    canceled: t('BookingHistory.Statuses.Canceled'),
    failure: t('BookingHistory.Statuses.Failure'),
  }

  const columns = [
    {
      title: '№',
      key: 'invoiceId',
      dataIndex: 'invoice_id',
      align: 'center',
      fixed: 'left',
    },
    {
      title: t('BookingHistory.Table.OrderDate'),
      key: 'payDay',
      align: 'center',
      render: (text, record, index) => {
        return moment.unix(record.created_date).format('DD/MM/YYYY HH:mm')
      },
    },
    {
      title: t('BookingHistory.Table.PaidFullPrice'),
      key: 'fullSum',
      align: 'center',
      render: (text, record, index) => {
        return isNil(record.payment_data.paid_amount)
          ? t('BookingHistory.Statuses.Unpaid')
          : `${record.payment_data.paid_amount} ${t('Currency.UAH')} / ${
              record.payment_data.total_price
            } ${t('Currency.UAH')}`
      },
    },
    {
      title: t('BookingHistory.Table.GoIn'),
      key: 'startDate',
      align: 'center',
      render: (text, record, index) => {
        const startDate = record.products.filter(
          product => product.type === 'hotel'
        )

        if (isEmpty(startDate) || isNil(startDate)) {
          return '-'
        }

        const {
          stay_dates: { start_date },
        } = startDate[0]

        return moment(start_date).format('DD/MM/YYYY')
      },
    },
    {
      title: t('BookingHistory.Table.GoOut'),
      key: 'endDate',
      align: 'center',
      render: (text, record, index) => {
        const endDate = record.products.filter(
          product => product.type === 'hotel'
        )

        if (isEmpty(endDate) || isNil(endDate)) {
          return ' - '
        }

        const {
          stay_dates: { end_date },
        } = endDate[0]

        return moment(end_date).format('DD/MM/YYYY')
      },
    },
    {
      title: t('BookingHistory.Table.AdditionalService'),
      key: 'additionalStuff',
      align: 'center',
      render: (text, record, index) => {
        const { products } = record
        const productsArray = products.filter(
          product => product.type === 'crossales'
        )

        const langs = {
          ukr: 'ua',
          ru: 'ru',
          eng: 'en',
        }

        return (
          <ul className="product-history-list">
            {!isEmpty(productsArray) ? (
              productsArray.map((product, index) => (
                <li key={product._id} className="product-history-list-item">
                  {`${product[langs[i18n.language]].title} - ${
                    product.amount
                  } шт`}
                </li>
              ))
            ) : (
              <li key={index}>-</li>
            )}
          </ul>
        )
      },
    },
    {
      title: t('BookingHistory.Table.PaymentStatus'),
      key: 'status',
      align: 'center',
      render: (text, record, index) => {
        const status = record.status

        return (
          <span className={statusesStylenames[status]}>
            {statusesTranslations[status] || status}
          </span>
        )
      },
    },
  ]

  if (isLoading) {
    return (
      <div className="is-loading">
        <Loader size={60} />
      </div>
    )
  }

  if (isEmpty(list) || isNil(list)) {
    return (
      <div className="empty">
        <span className="empty-title">{t('BookingHistory.NoResults')}</span>
      </div>
    )
  }

  return (
    <Table
      className="table-wrapper"
      columns={columns}
      dataSource={list}
      pagination={false}
      scroll={{ x: true }}
      bordered
      ellipsis
    />
  )
}

const BookingHistory = ({
  onGetBookingHistory,
  bookingHistoryList,
  isBookingHistoryLoading,
  isAdmin,
}) => {
  const queryParams = useLocation().search

  const showNotification = () => {
    const orderId = new URLSearchParams(queryParams).get('order_id')

    if (orderId) {
      notification.success({
        message: 'Успішна оплата',
        description:
          'Бронювання надіслано на Вашу електронну адресу. Якщо Ви не бачите його серед листів, перевірте, будь ласка, розділи «Спам» та «Промоакції».',
      })
    }
  }

  useEffect(() => {
    onGetBookingHistory({ isAdmin })
  }, [isAdmin])

  useEffect(() => {
    showNotification()
  }, [])

  return (
    <div className="booking-history-wrapper">
      <div className="booking-history">
        <BookingHistoryList
          isLoading={isBookingHistoryLoading}
          list={bookingHistoryList}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  bookingHistoryList: state.cart.bookingHistoryList,
  isBookingHistoryLoading: state.cart.isBookingHistoryLoading,
  isAdmin: state.auth.isAdmin,
})

const mapDispatchToProps = dispatch => ({
  onGetBookingHistory: action => dispatch(onGetBookingHistory(action)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BookingHistory)
