import React, { useMemo } from 'react'

import useAbstractionMediaQueries from '../../hooks/useAbstractionMediaQueries'

import './ImagesMasonryStyles.scss'

const ImagesMasonry = ({ masonryConfig }) => {
  const {
    isMobile,
    isTablet,
    isBigTable,
    isBiggestTable,
  } = useAbstractionMediaQueries()

  const {
    images,
    parentConfig: { rows: configRows, cols: configCols, maxHeight = 2750 },
  } = masonryConfig

  const renderMasonry = () => {
    return images.map(imageConfig => {
      const {
        image,
        classes: configClasses,
        isPlan,
        title,
        isVertical = false,
      } = imageConfig

      const classes = isBigTable ? '' : configClasses

      if (!image) {
        return <div className={classes} />
      }

      if (isPlan) {
        return
        // return (
        //   <div className={`${classes} clickable-image-wrapper`} key={image}>
        //     <h2 className="clickable-image-title">{title}</h2>
        //     <a
        //       href={image}
        //       target="_blank"
        //       rel="noreferrer"
        //       className="clickable-image-link"
        //     >
        //       <img src={image} alt={image} className="clickable-image" />
        //     </a>
        //   </div>
        // )
      }

      return (
        <img
          src={image}
          className={classes}
          key={image}
          // style={
          //   isTablet && isVertical
          //     ? { objectFit: 'contain' }
          //     : { objectFit: 'cover' }
          // }
        />
      )
    })
  }

  const renderedMasonry = useMemo(() => renderMasonry(), [
    masonryConfig,
    isBigTable,
  ])


  const img_count = images.filter(image => image.isPlan === true).length;

  console.log("images.length",isBigTable, images.length,images,img_count)
  const rows = isBigTable ? images.length-img_count : configRows
  const cols = isBigTable ? 1 : configCols
  //rows == 7? rows-1:rows
  return (
    <div className="images-masonry-wrapper">
      <div
        className="images-masonry"
        style={{
          gridTemplateColumns: `repeat(${cols}, 1fr)`,
          gridTemplateRows: `repeat(${rows}, minmax(0, 1fr))`,
          maxHeight,
        }}
      >
        {renderedMasonry}
      </div>
    </div>
  )
}

export default ImagesMasonry
