import HttpService from './http'

import apartments_en from '../../static/images/shelest-home/apartments_en.json'
import apartments_ua from '../../static/images/shelest-home/apartments_ua.json'

const baseUrl = process.env.REACT_APP_BACKEND_API

class RoomsService {
  constructor() {
    this.request = new HttpService(baseUrl)
  }

//getRooms = {apartments_en}

  getRooms = async lang => {
    try {
      /* 
      const res = await fetch(
        `https://api.shelest.ua/apartments?language=${lang === 'ukr' ? 'ua' : lang}`,
        //`${lang === 'ukr' ? {apartments_ua} : {apartments_en} }`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-TravelLine-ApiKey': 'd691e3452824276e477b37f5e4420976',
            Accept: 'application/json, text/plain, * /*',
          },
        }
      )

      return res.json()*/
      return lang === 'ukr' ? apartments_ua : apartments_en 
    } catch (err) {}
  }
  /*
  */
}

export default new RoomsService()
