import React from 'react'
// import PropTypes from 'prop-types'
// import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
// import { useTranslation } from 'react-i18next'

import './Contacts.scss'

const Contacts = () => {
  // const { t, i18n } = useTranslation()

  return (
    <div className="contacts-wrapper">
      <h1>Contacts</h1>
    </div>
  )
}

Contacts.propTypes = {}

Contacts.defaultProps = {}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Contacts)
