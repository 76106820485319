import React from 'react'
import Icon from '@ant-design/icons'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { ReactComponent as Minus } from '../../../static/icons/minus.svg'
import { ReactComponent as Plus } from '../../../static/icons/plus.svg'

import './SPAList.scss'

const SPAList = props => {
  const { titleComponent, onAdd, onRemove, amount, maxAmount, total } = props

  const { t } = useTranslation()

  return (
    <ul className="spa-list">
      <li key="name" className={`inner-list-item name`}>
        <span className="title-wrapper">{titleComponent}</span>
      </li>
      <li key="count" className={`inner-list-item count`}>
        {amount ? (
          <Icon
            component={Minus}
            style={{ marginRight: 10, fontSize: 20 }}
            onClick={onRemove}
          />
        ) : (
          <div style={{ width: 20, marginLeft: 10 }} />
        )}
        <span>{amount}</span>
        {amount < maxAmount ? (
          <Icon
            component={Plus}
            style={{
              marginLeft: 10,
              fontSize: 20,
            }}
            onClick={onAdd}
          />
        ) : null}
      </li>
      <li key="date" className={`inner-list-item date`}>
        <span>-</span>
      </li>
      <li key="price" className={`inner-list-item price`}>
        <span>
          {total} {t('Currency.UAH')}
        </span>
      </li>
    </ul>
  )
}

SPAList.propTypes = {
  price: PropTypes.number.isRequired,
  titleComponent: PropTypes.element.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  amount: PropTypes.number,
  total: PropTypes.number.isRequired,
  maxAmount: PropTypes.number.isRequired,
}

SPAList.defaultProps = {
  amount: 0,
}

export default SPAList
