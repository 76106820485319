import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { CloseOutlined } from '@ant-design/icons'

import gsap, { Power3 } from 'gsap/dist/gsap'
import useSystemLanguage from '../../hooks/useSystemLanguage'
import './Footer.scss'

const iconStyles = {
  color: '#4c6852',
  fontSize: 30,
  marginLeft: 25,
}

const EngFooter = (
  <>
    <p>
      BECOME A CO-OWNER OF THE NEW PROJECT FROM SHELEST <a href="https://www.shelest-hutir.com/?utm_source=site&utm_medium=hotel">INVEST</a> IN SHELEST HUTIR.
      Mon is a shortened day, Tues and Wed are days off.
    </p>
  </>
)

const Footer = () => {
  const { i18n } = useTranslation()
  const { push } = useHistory()
  const { systemLanguage } = useSystemLanguage()
  const descriptions = {
    ukr: (
      <>
        <p>
          СТАНЬТЕ СПІВВЛАСНИКОМ НОВОГО ПРОЄКТУ ВІД SHELEST
          <br />
          <a href="https://www.shelest-hutir.com/?utm_source=site&utm_medium=hotel">ІНВЕСТУЙТЕ</a> В SHELEST HUTIR
        </p>
        
      </>
    ),
    en: EngFooter,
  }

 const footerContent = () => {
    switch (systemLanguage) {
      case 'en':
        return (
        <div id="footer" className="footer">
          <div className="footer-col-1" >
              <p><a href="https://shelest.ua/shelest-public-offer.pdf" target="_blank" >Accession Agreement</a></p>
              <p><a href="https://shelest.ua/shelest-pravyla-perebuvannia.pdf" target="_blank" >Rules of stay</a></p>
              <p><a href="https://www.shelest-hutir.com/?utm_source=site&utm_medium=hotel" target="_blank" >Invest</a></p>
          </div>
          <div className="footer-col-2" >
              <p><a href="tel:+380671100067">380671100067</a></p>
              <p><a href="https://maps.app.goo.gl/827zjcYAAiXfpfBi7" target="_blank" >Kyiv region, Vyshgorod district, Pirniv territorial community, <br/>ST "Mayak+", Primorska 1 street</a></p>
              <p>
                <a href="https://www.facebook.com/shelest.hotel" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ico" fill="currentColor" viewBox="0 0 24 24"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path></svg>
                </a>
                <a href="https://www.instagram.com/shelest.hotel?igsh=Y2t6b25sdXJxZXBk" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ico" fill="currentColor" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"></path></svg>
                </a>
              </p>
            
          </div>
      </div>
        )

      default:
        return (
        <div id="footer" className="footer">
          <div className="footer-col-1" >
              <p><a href="https://shelest.ua/shelest-public-offer.pdf" target="_blank" >Публічна Оферта</a></p>
              <p><a href="https://shelest.ua/shelest-pravyla-perebuvannia.pdf" target="_blank" >Правила перебування</a></p>
              <p><a href="https://shelest.ua/team" target="_blank" >Стати частиною команди</a></p>
              <p><a href="https://www.shelest-hutir.com/?utm_source=site&utm_medium=hotel" target="_blank" >Інвестувати</a></p>
          </div>
          <div className="footer-col-2" >
              <p><a href="tel:+380671100067">380671100067</a></p>
              <p><a href="https://maps.app.goo.gl/827zjcYAAiXfpfBi7" target="_blank" >Київська область, Вишгородський район, Пірнівська <br/>територіальна громада, СТ "Маяк+", вул. Приморська, 1.</a></p>
              <p>
                <a href="https://www.facebook.com/shelest.hotel" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ico" fill="currentColor" viewBox="0 0 24 24"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path></svg>
                </a>
                <a href="https://www.instagram.com/shelest.hotel?igsh=Y2t6b25sdXJxZXBk" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ico" fill="currentColor" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"></path></svg>
                </a>
              </p>
            
          </div>
      </div>
        )
      }
    }

  const ref = useRef(null)

  const onPanelClick = () => {
    gsap.to(ref.current, {
      yPercent: 100,
      duration: 0.5,
      display: 'none',
    })

    sessionStorage.setItem('isPopupClicked', '1')
  }

  useEffect(() => {
    if (ref && ref.current) {
      gsap.from(ref.current, {
        yPercent: 100,
        duration: 0.5,
        delay: 0.5,
        ease: Power3.easeOut,
      })
    }
  }, [])

  return (
    <div className="footer-wrapper" ref={ref}>
      {footerContent()}
    </div>
  )
}

export default Footer
