import React, { useState } from 'react'
// import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TextArea from 'antd/lib/input/TextArea'
import Icon, {
  QuestionCircleOutlined,
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import moment from 'moment'
import { uid } from 'react-uid'
import { DatePicker, Select, Form, Checkbox, Tooltip, Input } from 'antd'
import { useLottie } from 'lottie-react'

import Button from '../../../components/Button'
import Loader from '../../../components/Loader'
import HerHim from '../../../components/HerHim'

import {
  onRemoveItem,
  onPayCheck,
  onAddItem,
  onAddHerHisItem,
  onRemoveHerHisItem,
} from '../../../redux/cart/cart'

import useSystemLanguage from '../../../hooks/useSystemLanguage'
import useAbstractionMediaQueries from '../../../hooks/useAbstractionMediaQueries'
import {
  validateMessages,
  ukrainianPhoneNumberPattern,
} from '../../../utils/constants'

import './Cart.scss'

import { ReactComponent as Minus } from '../../../static/icons/minus.svg'
import { ReactComponent as Plus } from '../../../static/icons/plus.svg'
import { ReactComponent as ArrowRight } from '../../../static/icons/arrow-right.svg'
import { ReactComponent as Visa } from '../../../static/icons/visa.svg'
import { ReactComponent as MasterCard } from '../../../static/icons/mastercard.svg'
import SPAList from './SPAList'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'

const cartHeaderName = {
  0: 'name',
  1: 'date',
  2: 'count',
  3: 'price',
}

const sexToI18NString = {
  spa_her: 'ForHer',
  spa_him: 'ForHim',
  babysitter: 'Babysitter',
}

const sexToPrefix = {
  spa_her: 'SPAForHer',
  spa_him: 'SPAForHim',
  babysitter: 'Babysitter',
}

const Cart = ({
  cartItemsList,
  onRemoveItem,
  totalPrice,
  paymentAmount,
  onPayCheck,
  user,
  isCartLoading,
  onAddItem,
  isProcessing,
  cartId,
  isAuthenticated,
  isAdmin,
  onAddSpaDispatch,
  onRemoveSpaDispatch,
}) => {
  const { t } = useTranslation()
  // const [message, setMessage] = useState('')
  const [isUserAgree, setAgreeState] = useState(false)
  const history = useHistory()
  const { systemLanguage } = useSystemLanguage()
  const { isTablet } = useAbstractionMediaQueries()
  const [isTooltipVisible, setTooltipVisibility] = useState(false)
  const sendDataToGTM = useGTMDispatch()

  const options = {
    animationData: {
      v: '4.8.0',
      meta: { g: 'LottieFiles AE 1.0.0', a: '', k: '', d: '', tc: '#FFFFFF' },
      fr: 60,
      ip: 0,
      op: 94,
      w: 500,
      h: 500,
      nm: 'left swipe ',
      ddd: 0,
      assets: [],
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'hand 2',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 0,
                  s: [0],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 8,
                  s: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 77,
                  s: [100],
                },
                { t: 84, s: [0] },
              ],
              ix: 11,
            },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.312], y: [1] },
                  o: { x: [0.572], y: [0] },
                  t: 20,
                  s: [0],
                },
                {
                  i: { x: [0.419], y: [1] },
                  o: { x: [0.572], y: [0] },
                  t: 39,
                  s: [11],
                },
                { t: 59, s: [0] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.312, y: 0.312 },
                  o: { x: 0.572, y: 0.572 },
                  t: 0,
                  s: [338.315, 236.36, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.419, y: 1 },
                  o: { x: 0.572, y: 0 },
                  t: 20,
                  s: [338.315, 236.36, 0],
                  to: [-29.583, 0, 0],
                  ti: [29.583, 0, 0],
                },
                { t: 59, s: [160.815, 236.36, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [87.75, -13, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.708, 0.708, 0.333], y: [0, 0, 0] },
                  t: 0,
                  s: [112, 112, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 11,
                  s: [100, 100, 100],
                },
                {
                  i: { x: [0.78, 0.78, 0.667], y: [1, 1, 1] },
                  o: { x: [0.76, 0.76, 0.333], y: [0, 0, 0] },
                  t: 70,
                  s: [100, 100, 100],
                },
                { t: 83, s: [103, 103, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[0, 0]],
                      o: [[0, 0]],
                      v: [[110.214, 81.929]],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [126.263, 48.249],
                            [126.263, 73.855],
                          ],
                          c: false,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 6.231, ix: 5 },
                      lc: 2,
                      lj: 2,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [0, 0], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 1',
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [100.656, 40.455],
                            [100.656, 71.072],
                          ],
                          c: false,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 6.231, ix: 5 },
                      lc: 2,
                      lj: 2,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [0, 0], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 2',
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 2,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [151.87, 56.599],
                            [151.87, 76.639],
                          ],
                          c: false,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 6.231, ix: 5 },
                      lc: 2,
                      lj: 2,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [0, 0], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 3',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 3,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            i: { x: 0.833, y: 0.833 },
                            o: { x: 0.167, y: 0.167 },
                            t: 4,
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [-0.292, 3.932],
                                  [-0.417, 2.851],
                                  [0, 43.977],
                                  [0, 0],
                                  [2.306, 2.323],
                                  [3.539, 0],
                                  [0, -7.068],
                                  [0, 0],
                                  [2.308, 2.328],
                                  [3.522, 0],
                                  [0, -7.083],
                                  [0, 0],
                                  [2.328, 2.328],
                                  [3.542, 0],
                                  [0, -7.083],
                                  [0, 0],
                                  [2.326, 2.323],
                                  [4.204, -0.53],
                                  [0, -6.47],
                                  [0, 0],
                                  [0, 0],
                                  [4.791, 0],
                                  [1.769, -0.834],
                                  [-3.022, -6.393],
                                  [-0.915, -1.57],
                                  [-2.138, -20.311],
                                  [-3.889, 0],
                                ],
                                o: [
                                  [3.942, 0],
                                  [0.164, -2.203],
                                  [2.783, -19.085],
                                  [0.278, -1.67],
                                  [0, -3.534],
                                  [-2.326, -2.303],
                                  [-7.058, 0],
                                  [0, 0],
                                  [0, -3.542],
                                  [-2.328, -2.308],
                                  [-7.083, 0],
                                  [0, 0],
                                  [0, -3.542],
                                  [-2.308, -2.308],
                                  [-7.063, 0],
                                  [0, 0],
                                  [0, -3.534],
                                  [-2.688, -2.662],
                                  [-6.42, 0.809],
                                  [0, 0],
                                  [0, 0],
                                  [-2.187, -4.606],
                                  [-1.829, 0],
                                  [-6.382, 3.038],
                                  [0, 0],
                                  [10.885, 21.535],
                                  [0.407, 3.867],
                                  [0, 0],
                                ],
                                v: [
                                  [147.109, 165.705],
                                  [154.626, 158.746],
                                  [155.488, 151.112],
                                  [177.476, 76.36],
                                  [177.476, 56.387],
                                  [173.739, 47.333],
                                  [164.673, 43.601],
                                  [151.87, 56.387],
                                  [151.87, 48.459],
                                  [148.129, 39.386],
                                  [139.076, 35.645],
                                  [126.263, 48.459],
                                  [126.263, 40.517],
                                  [122.502, 31.444],
                                  [113.45, 27.703],
                                  [100.656, 40.517],
                                  [100.735, -11.503],
                                  [96.977, -20.556],
                                  [86.255, -24.183],
                                  [75.128, -11.242],
                                  [75.05, 85.811],
                                  [62.385, 59.087],
                                  [50.795, 51.761],
                                  [45.347, 52.992],
                                  [39.264, 70.027],
                                  [56.043, 105.407],
                                  [80.665, 158.875],
                                  [88.17, 165.705],
                                ],
                                c: true,
                              },
                            ],
                          },
                          {
                            i: { x: 0.833, y: 0.833 },
                            o: { x: 0.167, y: 0.167 },
                            t: 15,
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [-0.292, 3.932],
                                  [-0.417, 2.851],
                                  [0, 43.977],
                                  [0, 0],
                                  [2.306, 2.323],
                                  [3.539, 0],
                                  [0, -7.068],
                                  [0, 0],
                                  [2.308, 2.328],
                                  [3.522, 0],
                                  [0, -7.083],
                                  [0, 0],
                                  [2.328, 2.328],
                                  [3.542, 0],
                                  [0, -7.083],
                                  [0, 0],
                                  [2.326, 2.323],
                                  [4.204, -0.53],
                                  [0, -6.47],
                                  [0, 0],
                                  [0, 0],
                                  [4.791, 0],
                                  [1.769, -0.834],
                                  [-3.022, -6.393],
                                  [-0.915, -1.57],
                                  [-2.138, -20.311],
                                  [-3.889, 0],
                                ],
                                o: [
                                  [3.942, 0],
                                  [0.164, -2.203],
                                  [2.783, -19.085],
                                  [0.278, -1.67],
                                  [0, -3.534],
                                  [-2.326, -2.303],
                                  [-7.058, 0],
                                  [0, 0],
                                  [0, -3.542],
                                  [-2.328, -2.308],
                                  [-7.083, 0],
                                  [0, 0],
                                  [0, -3.542],
                                  [-2.308, -2.308],
                                  [-7.063, 0],
                                  [0, 0],
                                  [0, -3.534],
                                  [-2.688, -2.662],
                                  [-6.42, 0.809],
                                  [0, 0],
                                  [0, 0],
                                  [-2.187, -4.606],
                                  [-1.829, 0],
                                  [-6.382, 3.038],
                                  [0, 0],
                                  [10.885, 21.535],
                                  [0.407, 3.867],
                                  [0, 0],
                                ],
                                v: [
                                  [147.109, 165.705],
                                  [154.626, 158.746],
                                  [155.488, 151.112],
                                  [177.476, 76.36],
                                  [177.476, 56.387],
                                  [173.739, 47.333],
                                  [164.673, 43.601],
                                  [151.87, 56.387],
                                  [151.87, 48.459],
                                  [148.129, 39.386],
                                  [139.076, 35.645],
                                  [126.263, 48.459],
                                  [126.263, 40.517],
                                  [122.502, 31.444],
                                  [113.45, 27.703],
                                  [100.656, 40.517],
                                  [100.656, -0.198],
                                  [96.899, -9.252],
                                  [86.176, -12.879],
                                  [75.05, 0.062],
                                  [75.05, 85.811],
                                  [62.385, 59.087],
                                  [50.795, 51.761],
                                  [45.347, 52.992],
                                  [39.264, 70.027],
                                  [56.043, 105.408],
                                  [80.665, 158.875],
                                  [88.17, 165.705],
                                ],
                                c: true,
                              },
                            ],
                          },
                          {
                            i: { x: 0.833, y: 0.833 },
                            o: { x: 0.167, y: 0.167 },
                            t: 59,
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [-0.292, 3.932],
                                  [-0.417, 2.851],
                                  [0, 43.977],
                                  [0, 0],
                                  [2.306, 2.323],
                                  [3.539, 0],
                                  [0, -7.068],
                                  [0, 0],
                                  [2.308, 2.328],
                                  [3.522, 0],
                                  [0, -7.083],
                                  [0, 0],
                                  [2.328, 2.328],
                                  [3.542, 0],
                                  [0, -7.083],
                                  [0, 0],
                                  [2.326, 2.323],
                                  [4.204, -0.53],
                                  [0, -6.47],
                                  [0, 0],
                                  [0, 0],
                                  [4.791, 0],
                                  [1.769, -0.834],
                                  [-3.022, -6.393],
                                  [-0.915, -1.57],
                                  [-2.138, -20.311],
                                  [-3.889, 0],
                                ],
                                o: [
                                  [3.942, 0],
                                  [0.164, -2.203],
                                  [2.783, -19.085],
                                  [0.278, -1.67],
                                  [0, -3.534],
                                  [-2.326, -2.303],
                                  [-7.058, 0],
                                  [0, 0],
                                  [0, -3.542],
                                  [-2.328, -2.308],
                                  [-7.083, 0],
                                  [0, 0],
                                  [0, -3.542],
                                  [-2.308, -2.308],
                                  [-7.063, 0],
                                  [0, 0],
                                  [0, -3.534],
                                  [-2.688, -2.662],
                                  [-6.42, 0.809],
                                  [0, 0],
                                  [0, 0],
                                  [-2.187, -4.606],
                                  [-1.829, 0],
                                  [-6.382, 3.038],
                                  [0, 0],
                                  [10.885, 21.535],
                                  [0.407, 3.867],
                                  [0, 0],
                                ],
                                v: [
                                  [147.109, 165.705],
                                  [154.626, 158.746],
                                  [155.488, 151.112],
                                  [177.476, 76.36],
                                  [177.476, 56.387],
                                  [173.739, 47.333],
                                  [164.673, 43.601],
                                  [151.87, 56.387],
                                  [151.87, 48.459],
                                  [148.129, 39.386],
                                  [139.076, 35.645],
                                  [126.263, 48.459],
                                  [126.263, 40.517],
                                  [122.502, 31.444],
                                  [113.45, 27.703],
                                  [100.656, 40.517],
                                  [100.656, -0.198],
                                  [96.899, -9.252],
                                  [86.176, -12.879],
                                  [75.05, 0.062],
                                  [75.05, 85.811],
                                  [62.385, 59.087],
                                  [50.795, 51.761],
                                  [45.347, 52.992],
                                  [39.264, 70.027],
                                  [56.043, 105.408],
                                  [80.665, 158.875],
                                  [88.17, 165.705],
                                ],
                                c: true,
                              },
                            ],
                          },
                          {
                            t: 70,
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [-0.292, 3.932],
                                  [-0.417, 2.851],
                                  [0, 43.977],
                                  [0, 0],
                                  [2.306, 2.323],
                                  [3.539, 0],
                                  [0, -7.068],
                                  [0, 0],
                                  [2.308, 2.328],
                                  [3.522, 0],
                                  [0, -7.083],
                                  [0, 0],
                                  [2.328, 2.328],
                                  [3.542, 0],
                                  [0, -7.083],
                                  [0, 0],
                                  [2.326, 2.323],
                                  [4.204, -0.53],
                                  [0, -6.47],
                                  [0, 0],
                                  [0, 0],
                                  [4.791, 0],
                                  [1.769, -0.834],
                                  [-3.022, -6.393],
                                  [-0.915, -1.57],
                                  [-2.138, -20.311],
                                  [-3.889, 0],
                                ],
                                o: [
                                  [3.942, 0],
                                  [0.164, -2.203],
                                  [2.783, -19.085],
                                  [0.278, -1.67],
                                  [0, -3.534],
                                  [-2.326, -2.303],
                                  [-7.058, 0],
                                  [0, 0],
                                  [0, -3.542],
                                  [-2.328, -2.308],
                                  [-7.083, 0],
                                  [0, 0],
                                  [0, -3.542],
                                  [-2.308, -2.308],
                                  [-7.063, 0],
                                  [0, 0],
                                  [0, -3.534],
                                  [-2.688, -2.662],
                                  [-6.42, 0.809],
                                  [0, 0],
                                  [0, 0],
                                  [-2.187, -4.606],
                                  [-1.829, 0],
                                  [-6.382, 3.038],
                                  [0, 0],
                                  [10.885, 21.535],
                                  [0.407, 3.867],
                                  [0, 0],
                                ],
                                v: [
                                  [147.109, 165.705],
                                  [154.626, 158.746],
                                  [155.488, 151.112],
                                  [177.476, 76.36],
                                  [177.476, 56.387],
                                  [173.739, 47.333],
                                  [164.673, 43.601],
                                  [151.87, 56.387],
                                  [151.87, 48.459],
                                  [148.129, 39.386],
                                  [139.076, 35.645],
                                  [126.263, 48.459],
                                  [126.263, 40.517],
                                  [122.502, 31.444],
                                  [113.45, 27.703],
                                  [100.656, 40.517],
                                  [100.735, -11.503],
                                  [96.977, -20.556],
                                  [86.255, -24.183],
                                  [75.128, -11.242],
                                  [75.05, 85.811],
                                  [62.385, 59.087],
                                  [50.795, 51.761],
                                  [45.347, 52.992],
                                  [39.264, 70.027],
                                  [56.043, 105.407],
                                  [80.665, 158.875],
                                  [88.17, 165.705],
                                ],
                                c: true,
                              },
                            ],
                          },
                        ],
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'st',
                      c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 6.231, ix: 5 },
                      lc: 2,
                      lj: 2,
                      bm: 0,
                      nm: 'Stroke 1',
                      mn: 'ADBE Vector Graphic - Stroke',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [0, 0], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 4',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 4,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 191,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'hand',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 0,
                  s: [0],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 8,
                  s: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 77,
                  s: [100],
                },
                { t: 84, s: [0] },
              ],
              ix: 11,
            },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.312], y: [1] },
                  o: { x: [0.572], y: [0] },
                  t: 20,
                  s: [0],
                },
                {
                  i: { x: [0.419], y: [1] },
                  o: { x: [0.572], y: [0] },
                  t: 39,
                  s: [11],
                },
                { t: 59, s: [0] },
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.312, y: 0.312 },
                  o: { x: 0.572, y: 0.572 },
                  t: 0,
                  s: [338.315, 236.36, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.419, y: 1 },
                  o: { x: 0.572, y: 0 },
                  t: 20,
                  s: [338.315, 236.36, 0],
                  to: [-29.583, 0, 0],
                  ti: [29.583, 0, 0],
                },
                { t: 59, s: [160.815, 236.36, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [87.75, -13, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.708, 0.708, 0.333], y: [0, 0, 0] },
                  t: 0,
                  s: [112, 112, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 11,
                  s: [100, 100, 100],
                },
                {
                  i: { x: [0.78, 0.78, 0.667], y: [1, 1, 1] },
                  o: { x: [0.76, 0.76, 0.333], y: [0, 0, 0] },
                  t: 70,
                  s: [100, 100, 100],
                },
                { t: 83, s: [103, 103, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[0, 0]],
                      o: [[0, 0]],
                      v: [[110.214, 81.929]],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'gr',
              it: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [126.263, 48.249],
                            [126.263, 73.855],
                          ],
                          c: false,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [0, 0], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 1',
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [100.656, 40.455],
                            [100.656, 71.072],
                          ],
                          c: false,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [0, 0], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 2',
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 2,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [151.87, 56.599],
                            [151.87, 76.639],
                          ],
                          c: false,
                        },
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [0, 0], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 3',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 3,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 1,
                        k: [
                          {
                            i: { x: 0.833, y: 0.833 },
                            o: { x: 0.167, y: 0.167 },
                            t: 4,
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [-0.292, 3.932],
                                  [-0.417, 2.851],
                                  [0, 43.977],
                                  [0, 0],
                                  [2.306, 2.323],
                                  [3.539, 0],
                                  [0, -7.068],
                                  [0, 0],
                                  [2.308, 2.328],
                                  [3.522, 0],
                                  [0, -7.083],
                                  [0, 0],
                                  [2.328, 2.328],
                                  [3.542, 0],
                                  [0, -7.083],
                                  [0, 0],
                                  [2.326, 2.323],
                                  [4.204, -0.53],
                                  [0, -6.47],
                                  [0, 0],
                                  [0, 0],
                                  [4.791, 0],
                                  [1.769, -0.834],
                                  [-3.022, -6.393],
                                  [-0.915, -1.57],
                                  [-2.138, -20.311],
                                  [-3.889, 0],
                                ],
                                o: [
                                  [3.942, 0],
                                  [0.164, -2.203],
                                  [2.783, -19.085],
                                  [0.278, -1.67],
                                  [0, -3.534],
                                  [-2.326, -2.303],
                                  [-7.058, 0],
                                  [0, 0],
                                  [0, -3.542],
                                  [-2.328, -2.308],
                                  [-7.083, 0],
                                  [0, 0],
                                  [0, -3.542],
                                  [-2.308, -2.308],
                                  [-7.063, 0],
                                  [0, 0],
                                  [0, -3.534],
                                  [-2.688, -2.662],
                                  [-6.42, 0.809],
                                  [0, 0],
                                  [0, 0],
                                  [-2.187, -4.606],
                                  [-1.829, 0],
                                  [-6.382, 3.038],
                                  [0, 0],
                                  [10.885, 21.535],
                                  [0.407, 3.867],
                                  [0, 0],
                                ],
                                v: [
                                  [147.109, 165.705],
                                  [154.626, 158.746],
                                  [155.488, 151.112],
                                  [177.476, 76.36],
                                  [177.476, 56.387],
                                  [173.739, 47.333],
                                  [164.673, 43.601],
                                  [151.87, 56.387],
                                  [151.87, 48.459],
                                  [148.129, 39.386],
                                  [139.076, 35.645],
                                  [126.263, 48.459],
                                  [126.263, 40.517],
                                  [122.502, 31.444],
                                  [113.45, 27.703],
                                  [100.656, 40.517],
                                  [100.735, -11.503],
                                  [96.977, -20.556],
                                  [86.255, -24.183],
                                  [75.128, -11.242],
                                  [75.05, 85.811],
                                  [62.385, 59.087],
                                  [50.795, 51.761],
                                  [45.347, 52.992],
                                  [39.264, 70.027],
                                  [56.043, 105.407],
                                  [80.665, 158.875],
                                  [88.17, 165.705],
                                ],
                                c: true,
                              },
                            ],
                          },
                          {
                            i: { x: 0.833, y: 0.833 },
                            o: { x: 0.167, y: 0.167 },
                            t: 15,
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [-0.292, 3.932],
                                  [-0.417, 2.851],
                                  [0, 43.977],
                                  [0, 0],
                                  [2.306, 2.323],
                                  [3.539, 0],
                                  [0, -7.068],
                                  [0, 0],
                                  [2.308, 2.328],
                                  [3.522, 0],
                                  [0, -7.083],
                                  [0, 0],
                                  [2.328, 2.328],
                                  [3.542, 0],
                                  [0, -7.083],
                                  [0, 0],
                                  [2.326, 2.323],
                                  [4.204, -0.53],
                                  [0, -6.47],
                                  [0, 0],
                                  [0, 0],
                                  [4.791, 0],
                                  [1.769, -0.834],
                                  [-3.022, -6.393],
                                  [-0.915, -1.57],
                                  [-2.138, -20.311],
                                  [-3.889, 0],
                                ],
                                o: [
                                  [3.942, 0],
                                  [0.164, -2.203],
                                  [2.783, -19.085],
                                  [0.278, -1.67],
                                  [0, -3.534],
                                  [-2.326, -2.303],
                                  [-7.058, 0],
                                  [0, 0],
                                  [0, -3.542],
                                  [-2.328, -2.308],
                                  [-7.083, 0],
                                  [0, 0],
                                  [0, -3.542],
                                  [-2.308, -2.308],
                                  [-7.063, 0],
                                  [0, 0],
                                  [0, -3.534],
                                  [-2.688, -2.662],
                                  [-6.42, 0.809],
                                  [0, 0],
                                  [0, 0],
                                  [-2.187, -4.606],
                                  [-1.829, 0],
                                  [-6.382, 3.038],
                                  [0, 0],
                                  [10.885, 21.535],
                                  [0.407, 3.867],
                                  [0, 0],
                                ],
                                v: [
                                  [147.109, 165.705],
                                  [154.626, 158.746],
                                  [155.488, 151.112],
                                  [177.476, 76.36],
                                  [177.476, 56.387],
                                  [173.739, 47.333],
                                  [164.673, 43.601],
                                  [151.87, 56.387],
                                  [151.87, 48.459],
                                  [148.129, 39.386],
                                  [139.076, 35.645],
                                  [126.263, 48.459],
                                  [126.263, 40.517],
                                  [122.502, 31.444],
                                  [113.45, 27.703],
                                  [100.656, 40.517],
                                  [100.656, -0.198],
                                  [96.899, -9.252],
                                  [86.176, -12.879],
                                  [75.05, 0.062],
                                  [75.05, 85.811],
                                  [62.385, 59.087],
                                  [50.795, 51.761],
                                  [45.347, 52.992],
                                  [39.264, 70.027],
                                  [56.043, 105.408],
                                  [80.665, 158.875],
                                  [88.17, 165.705],
                                ],
                                c: true,
                              },
                            ],
                          },
                          {
                            i: { x: 0.833, y: 0.833 },
                            o: { x: 0.167, y: 0.167 },
                            t: 59,
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [-0.292, 3.932],
                                  [-0.417, 2.851],
                                  [0, 43.977],
                                  [0, 0],
                                  [2.306, 2.323],
                                  [3.539, 0],
                                  [0, -7.068],
                                  [0, 0],
                                  [2.308, 2.328],
                                  [3.522, 0],
                                  [0, -7.083],
                                  [0, 0],
                                  [2.328, 2.328],
                                  [3.542, 0],
                                  [0, -7.083],
                                  [0, 0],
                                  [2.326, 2.323],
                                  [4.204, -0.53],
                                  [0, -6.47],
                                  [0, 0],
                                  [0, 0],
                                  [4.791, 0],
                                  [1.769, -0.834],
                                  [-3.022, -6.393],
                                  [-0.915, -1.57],
                                  [-2.138, -20.311],
                                  [-3.889, 0],
                                ],
                                o: [
                                  [3.942, 0],
                                  [0.164, -2.203],
                                  [2.783, -19.085],
                                  [0.278, -1.67],
                                  [0, -3.534],
                                  [-2.326, -2.303],
                                  [-7.058, 0],
                                  [0, 0],
                                  [0, -3.542],
                                  [-2.328, -2.308],
                                  [-7.083, 0],
                                  [0, 0],
                                  [0, -3.542],
                                  [-2.308, -2.308],
                                  [-7.063, 0],
                                  [0, 0],
                                  [0, -3.534],
                                  [-2.688, -2.662],
                                  [-6.42, 0.809],
                                  [0, 0],
                                  [0, 0],
                                  [-2.187, -4.606],
                                  [-1.829, 0],
                                  [-6.382, 3.038],
                                  [0, 0],
                                  [10.885, 21.535],
                                  [0.407, 3.867],
                                  [0, 0],
                                ],
                                v: [
                                  [147.109, 165.705],
                                  [154.626, 158.746],
                                  [155.488, 151.112],
                                  [177.476, 76.36],
                                  [177.476, 56.387],
                                  [173.739, 47.333],
                                  [164.673, 43.601],
                                  [151.87, 56.387],
                                  [151.87, 48.459],
                                  [148.129, 39.386],
                                  [139.076, 35.645],
                                  [126.263, 48.459],
                                  [126.263, 40.517],
                                  [122.502, 31.444],
                                  [113.45, 27.703],
                                  [100.656, 40.517],
                                  [100.656, -0.198],
                                  [96.899, -9.252],
                                  [86.176, -12.879],
                                  [75.05, 0.062],
                                  [75.05, 85.811],
                                  [62.385, 59.087],
                                  [50.795, 51.761],
                                  [45.347, 52.992],
                                  [39.264, 70.027],
                                  [56.043, 105.408],
                                  [80.665, 158.875],
                                  [88.17, 165.705],
                                ],
                                c: true,
                              },
                            ],
                          },
                          {
                            t: 70,
                            s: [
                              {
                                i: [
                                  [0, 0],
                                  [-0.292, 3.932],
                                  [-0.417, 2.851],
                                  [0, 43.977],
                                  [0, 0],
                                  [2.306, 2.323],
                                  [3.539, 0],
                                  [0, -7.068],
                                  [0, 0],
                                  [2.308, 2.328],
                                  [3.522, 0],
                                  [0, -7.083],
                                  [0, 0],
                                  [2.328, 2.328],
                                  [3.542, 0],
                                  [0, -7.083],
                                  [0, 0],
                                  [2.326, 2.323],
                                  [4.204, -0.53],
                                  [0, -6.47],
                                  [0, 0],
                                  [0, 0],
                                  [4.791, 0],
                                  [1.769, -0.834],
                                  [-3.022, -6.393],
                                  [-0.915, -1.57],
                                  [-2.138, -20.311],
                                  [-3.889, 0],
                                ],
                                o: [
                                  [3.942, 0],
                                  [0.164, -2.203],
                                  [2.783, -19.085],
                                  [0.278, -1.67],
                                  [0, -3.534],
                                  [-2.326, -2.303],
                                  [-7.058, 0],
                                  [0, 0],
                                  [0, -3.542],
                                  [-2.328, -2.308],
                                  [-7.083, 0],
                                  [0, 0],
                                  [0, -3.542],
                                  [-2.308, -2.308],
                                  [-7.063, 0],
                                  [0, 0],
                                  [0, -3.534],
                                  [-2.688, -2.662],
                                  [-6.42, 0.809],
                                  [0, 0],
                                  [0, 0],
                                  [-2.187, -4.606],
                                  [-1.829, 0],
                                  [-6.382, 3.038],
                                  [0, 0],
                                  [10.885, 21.535],
                                  [0.407, 3.867],
                                  [0, 0],
                                ],
                                v: [
                                  [147.109, 165.705],
                                  [154.626, 158.746],
                                  [155.488, 151.112],
                                  [177.476, 76.36],
                                  [177.476, 56.387],
                                  [173.739, 47.333],
                                  [164.673, 43.601],
                                  [151.87, 56.387],
                                  [151.87, 48.459],
                                  [148.129, 39.386],
                                  [139.076, 35.645],
                                  [126.263, 48.459],
                                  [126.263, 40.517],
                                  [122.502, 31.444],
                                  [113.45, 27.703],
                                  [100.656, 40.517],
                                  [100.735, -11.503],
                                  [96.977, -20.556],
                                  [86.255, -24.183],
                                  [75.128, -11.242],
                                  [75.05, 85.811],
                                  [62.385, 59.087],
                                  [50.795, 51.761],
                                  [45.347, 52.992],
                                  [39.264, 70.027],
                                  [56.043, 105.407],
                                  [80.665, 158.875],
                                  [88.17, 165.705],
                                ],
                                c: true,
                              },
                            ],
                          },
                        ],
                        ix: 2,
                      },
                      nm: 'Path 1',
                      mn: 'ADBE Vector Shape - Group',
                      hd: false,
                    },
                    {
                      ty: 'fl',
                      c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      nm: 'Fill 1',
                      mn: 'ADBE Vector Graphic - Fill',
                      hd: false,
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [0, 0], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 },
                      nm: 'Transform',
                    },
                  ],
                  nm: 'Group 4',
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 4,
                  mn: 'ADBE Vector Group',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 2',
              np: 4,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 191,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'click ',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 59,
                  s: [100],
                },
                { t: 68, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.419, y: 1 },
                  o: { x: 0.572, y: 0 },
                  t: 20,
                  s: [338.354, 235.391, 0],
                  to: [-29.333, 0, 0],
                  ti: [29.333, 0, 0],
                },
                { t: 59, s: [162.354, 235.391, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.235, 0.235, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 12,
                  s: [0, 0, 100],
                },
                { t: 20, s: [100, 100, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -11.7],
                        [11.7, 0],
                        [0, 11.7],
                        [-11.7, 0],
                      ],
                      o: [
                        [0, 11.7],
                        [-11.7, 0],
                        [0, -11.7],
                        [11.7, 0],
                      ],
                      v: [
                        [21.185, 0],
                        [0, 21.185],
                        [-21.185, 0],
                        [0, -21.185],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.3137254901960784, 1, 0.5411764705882353, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 21,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'end 2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [162.023, 235.391, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 71,
                  s: [100, 100, 100],
                },
                { t: 84, s: [0, 0, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -11.7],
                        [11.7, 0],
                        [0, 11.7],
                        [-11.7, 0],
                      ],
                      o: [
                        [0, 11.7],
                        [-11.7, 0],
                        [0, -11.7],
                        [11.7, 0],
                      ],
                      v: [
                        [21.185, 0],
                        [0, 21.185],
                        [-21.185, 0],
                        [0, -21.185],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.3137254901960784, 1, 0.5411764705882353, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 71,
          op: 77,
          st: 9,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'stroke',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [249.877, 235.391, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-87.854, 0],
                        [87.854, 0],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.3137254901960784, 1, 0.5411764705882353, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 43.615, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.419], y: [1] },
                    o: { x: [0.572], y: [0] },
                    t: 20,
                    s: [100],
                  },
                  { t: 59, s: [0] },
                ],
                ix: 1,
              },
              e: {
                a: 1,
                k: [
                  {
                    i: { x: [0.419], y: [1] },
                    o: { x: [0.572], y: [0] },
                    t: 33,
                    s: [100],
                  },
                  { t: 72, s: [0] },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
          ],
          ip: 0,
          op: 72,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'b',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 17,
                  s: [59],
                },
                { t: 31, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [339.6, 235.391, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 8,
                  s: [0, 0, 100],
                },
                { t: 23, s: [117, 117, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -17.894],
                        [17.894, 0],
                        [0, 17.894],
                        [-17.894, 0],
                      ],
                      o: [
                        [0, 17.894],
                        [-17.894, 0],
                        [0, -17.894],
                        [17.894, 0],
                      ],
                      v: [
                        [32.4, 0],
                        [0, 32.4],
                        [-32.4, 0],
                        [0, -32.4],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.3137254901960784, 1, 0.5411764705882353, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform',
                },
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 191,
          st: 0,
          bm: 0,
        },
      ],
      markers: [],
    },
    loop: 2,
  }

  // const { View } = useLottie(options)

  const getTitle = item => {
    switch (systemLanguage) {
      case 'en':
        return item.en.title
      case 'ru':
        return item.ru.title
      default:
        return item.ua.title
    }
  }

  const getDescription = item => {
    switch (systemLanguage) {
      case 'en':
        return item.en.short_description
      case 'ru':
        return item.ru.short_description
      default:
        return item.ua.short_description
    }
  }

  const renderItemsList = list => {
    return list.length > 0 ? (
      list.map((item, index) => {
        const {
          en: { title },
          spa,
          hotel_additions,
        } = item

        const maxAmount = title === 'Family House' ? 2 : 1

        return (
          <div className="list-item" key={uid(item)}>
            <ul className="inner-list">
              <li key="name" className={`inner-list-item name`}>
                <span>{getTitle(item)}</span>
                <span className="description">{getDescription(item)}</span>
              </li>
              <li key="count" className={`inner-list-item count`}>
                {/* <MinusOutlined
              style={{ marginRight: 10, fontSize: 20 }}
              onClick={() =>
                onRemoveItem({
                  product_id: item._id,
                  type: item.type,
                  action_detail: item.orderNum,
                  isAuthenticated,
                })
              }
            /> */}
                <Icon
                  component={Minus}
                  style={{ marginRight: 10, fontSize: 20 }}
                  onClick={() =>
                    onRemoveItem({
                      product_id: item._id,
                      type: item.type,
                      action_detail: item.orderNum,
                      isAuthenticated,
                    })
                  }
                />
                <span>{item.amount}</span>
                {item.type === 'crossales' ? (
                  // <PlusOutlined
                  //   style={{ marginLeft: 10, fontSize: 20 }}
                  //   onClick={() =>
                  //     onAddItem({
                  //       product_id: item._id,
                  //       type: item.type,
                  //       action_detail: item.orderNum,
                  //       isAuthenticated,
                  //     })
                  //   }
                  // />
                  <Icon
                    component={Plus}
                    style={{
                      marginLeft: 10,
                      fontSize: 20,
                    }}
                    onClick={() =>
                      onAddItem({
                        product_id: item._id,
                        type: item.type,
                        action_detail: item.orderNum,
                        isAuthenticated,
                      })
                    }
                  />
                ) : null}
              </li>
              <li key="date" className={`inner-list-item date`}>
                <span>
                  {item.type === 'hotel'
                    ? `${moment(item.stay_dates.start_date).format(
                        'DD.MM.YYYY'
                      )} / ${moment(item.stay_dates.end_date).format(
                        'DD.MM.YYYY'
                      )}`
                    : '-'}
                </span>
              </li>
              <li key="price" className={`inner-list-item price`}>
                <span>
                  {item.total_price} {t('Currency.UAH')}
                </span>
              </li>
            </ul>
            <ul className="inner-list-spa">
              {hotel_additions.map(spaItem => {
                console.log('spaItem: ', spaItem)
                const {
                  amount,
                  name,
                  price,
                  spa_id,
                  total_price,
                  _id,
                  hotel_addition_id,
                } = spaItem

                const onAddSpa = () => {
                  onAddSpaDispatch({
                    product_id: _id,
                    hotel_addition_id,
                    isAuthenticated,
                  })
                }

                const onRemoveSpa = () => {
                  onRemoveSpaDispatch({
                    product_id: _id,
                    hotel_addition_id,
                    isAuthenticated,
                  })
                }

                const titleComponent =
                  name === 'spa_her' ? (
                    <HerHim
                      sex={sexToI18NString[name]}
                      prefix={sexToPrefix[name]}
                      prefixOnly
                    />
                  ) : (
                    <HerHim sex="ForHim" prefix="SPAForHim" prefixOnly />
                  )

                return (
                  <SPAList
                    key={spa_id}
                    titleComponent={
                      <HerHim
                        sex={sexToI18NString[name]}
                        prefix={sexToPrefix[name]}
                        prefixOnly
                      />
                    }
                    price={price}
                    total={total_price}
                    onAdd={onAddSpa}
                    onRemove={onRemoveSpa}
                    amount={amount}
                    maxAmount={maxAmount}
                  />
                )
              })}
            </ul>
          </div>
        )
      })
    ) : (
      <li>{t('Booking.Cart.Empty')}</li>
    )
  }

  const onFinish = values => {
    sendDataToGTM({
      event: 'FormSentCart',
      eventType: 'FormSent',
    })

    return onPayCheck({
      user,
      cartId,
      isAuthenticated,
      history,
      totalPrice,
      paymentAmount,
      isAdmin,
      t,
      ...values,
    })
  }

  if (isCartLoading) {
    return (
      <div className="loader-wrapper">
        <Loader size={60} />
      </div>
    )
  }

  return cartItemsList.length === 0 ? (
    <div className="cart-empty-wrapper">
      <h1 className="empty">{t('Booking.Cart.Empty')}</h1>
      <Button
        onClick={() => history.push('hotel')}
        classNames="underlined"
        buttonId="booking_cart_orders_history_button"
      >
        {t('Booking.Cart.Placeholders.OrdersHistory')}
      </Button>
    </div>
  ) : (
    <div className="cart-wrapper">
      <div className="cart">
        <div className="cart-table-wrapper">
          <div className="cart-table">
            <div className="cart-table-header">
              <div className="inner-left">
                <div className="additional-wrapper">
                  <ul className="header-list">
                    <li className="list-item name">
                      {t('Booking.Cart.TableTitles.Name')}
                    </li>
                    <li className="list-item count">
                      {t('Booking.Cart.TableTitles.Count')}
                    </li>{' '}
                    <li className="list-item date">
                      {t('Booking.Cart.TableTitles.Date')}
                    </li>
                    <li className="list-item price">
                      {t('Booking.Cart.TableTitles.Price')}
                    </li>
                  </ul>
                  <ul className="items-list">
                    {renderItemsList(cartItemsList)}
                    {/* {View} */}
                  </ul>
                </div>
                <div className="rules-block">
                  <div className="rule">
                    <h3>{t('Booking.Cart.Rules.Guarantees.Title')}</h3>
                    <p>{t('Booking.Cart.Rules.Guarantees.Description')}</p>
                    <div className="link">
                      <a
                        href="https://shelest.ua/shelest-pravyla-perebuvannia.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('Booking.Cart.Rules.Guarantees.ButtonTitle')}
                        <Icon component={ArrowRight} />
                      </a>
                    </div>
                  </div>
                  <div className="rule">
                    <h3>
                      {t('Booking.Cart.Rules.Cancelling.Title')}
                      {/* </a> */}
                    </h3>
                    <p>{t('Booking.Cart.Rules.Cancelling.Description')}</p>
                    <div className="link">
                      <a
                        href="https://shelest.ua/shelest-dogovir-pryyednannia.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('Booking.Cart.Rules.Cancelling.ButtonTitle')}
                        <Icon component={ArrowRight} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="inner-right">
                <ul className="header-list">
                  <li className="list-item whole-amount">
                    {t('Booking.Cart.TableTitles.WholeAmount')}
                  </li>
                </ul>
                <h2 className="amount whole">
                  {t('Booking.Cart.CheckOut.WholeOrder')}{' '}
                  <span>
                    {totalPrice} {t('Currency.UAH')}
                  </span>
                </h2>
                <h2 className="amount payment">
                  {t('Booking.Cart.CheckOut.PayTo')}{' '}
                  <span>
                    {paymentAmount} {t('Currency.UAH')}
                  </span>
                </h2>
                <p className="quote">
                  <Tooltip
                    placement="right"
                    title={
                      <div>
                        {t('Booking.Cart.CheckOut.PayQuestion.Title')}
                        <br />
                        {t('Booking.Cart.CheckOut.PayQuestion.Formula')}
                      </div>
                    }
                  >
                    <QuestionCircleOutlined
                      style={{ fontSize: 18, marginRight: 10 }}
                    />
                  </Tooltip>{' '}
                  {t('Booking.Cart.CheckOut.PayDisclaimer')}
                </p>
                <Form
                  onFinish={onFinish}
                  validateMessages={validateMessages}
                  initialValues={{
                    message: '',
                  }}
                >
                  {isAdmin ? (
                    <>
                      <div className="form-row">
                        <Form.Item
                          name="firstName"
                          rules={[
                            {
                              required: true,
                              message: t('Validation.Required'),
                            },
                          ]}
                        >
                          <Input placeholder={t('InputPlaceholders.Name')} />
                        </Form.Item>
                        <Form.Item name="secondName">
                          <Input placeholder={t('InputPlaceholders.Surname')} />
                        </Form.Item>
                      </div>
                      <div className="form-row">
                        <Form.Item
                          name="phone"
                          rules={[
                            {
                              required: true,
                              message: t('Validation.Required'),
                            },
                            {
                              pattern: ukrainianPhoneNumberPattern,
                              message: t('Validation.WrongPhoneFormat'),
                            },
                          ]}
                        >
                          <Input
                            addonBefore="+38"
                            maxLength={10}
                            placeholder={t('InputPlaceholders.Phone')}
                          />
                        </Form.Item>
                        <Form.Item
                          name="email"
                          rules={[
                            {
                              type: 'email',
                              message: t('Validation.InvalidEmailFormat'),
                            },
                            {
                              required: true,
                              message: t('Validation.Required'),
                            },
                          ]}
                        >
                          <Input placeholder={t('InputPlaceholders.Email')} />
                        </Form.Item>
                      </div>
                    </>
                  ) : null}
                  <Form.Item name="message">
                    <TextArea
                      className="textarea"
                      placeholder={t(
                        'Booking.Cart.CheckOut.MessagePlaceholder'
                      )}
                      rows={5}
                    />
                  </Form.Item>
                  <div>
                    <Checkbox onChange={e => setAgreeState(e.target.checked)}>
                      <span style={{ color: 'red' }}>*</span>{' '}
                      {t('Booking.Cart.CheckOut.AgreeWithRules')}
                    </Checkbox>
                  </div>
                  <div className="pay-block">
                    <Button
                      isDisabled={!isUserAgree}
                      classNames="btn-green fsz-20"
                      buttonId="booking_cart_pay_button"
                    >
                      {t('Booking.Cart.CheckOut.PayButtonTitle')}
                    </Button>
                    <div className="pay-systems">
                      <Icon component={Visa} />
                      <Icon component={MasterCard} />
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            {isProcessing ? (
              <div className="processing-loader visible">
                <Loader size={60} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

Cart.propTypes = {}

const mapStateToProps = state => ({
  cartItemsList: state.cart.cartItemsList,
  totalPrice: state.cart.totalPrice,
  paymentAmount: state.cart.paymentAmount,
  user: state.auth.user,
  isCartLoading: state.cart.isCartLoading,
  isProcessing: state.cart.isProcessing,
  cartId: state.cart.cartId,
  isAuthenticated: state.auth.isAuthenticated,
  isAdmin: state.auth.isAdmin,
})

const mapDispatchToProps = dispatch => ({
  onRemoveItem: action => dispatch(onRemoveItem(action)),
  onPayCheck: action => dispatch(onPayCheck(action)),
  onAddItem: action => dispatch(onAddItem(action)),
  onAddSpaDispatch: action => dispatch(onAddHerHisItem(action)),
  onRemoveSpaDispatch: action => dispatch(onRemoveHerHisItem(action)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Cart)
