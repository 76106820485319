import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import SwitcherContent from './SwitcherContent'
import SwitcherNavigation from './SwitcherNavigation'

import './RoomsSwitcher.scss'

const RoomsSwitcher = ({ rooms }) => {
  const firstRoom = rooms[0]

  const [activeRoom, setActiveRoom] = useState(firstRoom)
  const [currentSlide, setCurrentSlide] = useState(0)

  useEffect(() => {
    setActiveRoom(firstRoom)
  }, [])

  useEffect(() => {
    setCurrentSlide(0)
  }, [activeRoom])

  return (
    <div className="switcher-wrapper">
      <SwitcherContent
        activeRoom={activeRoom}
        currentSlide={currentSlide}
        setCurrentSlide={setCurrentSlide}
      />
      <SwitcherNavigation
        rooms={rooms}
        activeRoom={activeRoom}
        setActiveRoom={setActiveRoom}
      />
    </div>
  )
}

RoomsSwitcher.propTypes = {
  rooms: PropTypes.array.isRequired,
}

export default RoomsSwitcher
