import ShelestHomeSlide1 from '../../static/images/shelest-home/shelest-home-1.jpg'
import ShelestHomeSlide2 from '../../static/images/shelest-home/shelest-home-2.jpg'
import ShelestHomeSlide3 from '../../static/images/shelest-home/shelest-home-3.jpg'
import ShelestHomeSlide4 from '../../static/images/shelest-home/shelest-home-4.jpg'
import ShelestHomeSlide5 from '../../static/images/shelest-home/shelest-home-5.jpg'
import ShelestHomeSlide6 from '../../static/images/shelest-home/shelest-home-6.jpg'
import ShelestHomeSlide7 from '../../static/images/shelest-home/shelest-home-7.jpg'
import ShelestHomeSlide8 from '../../static/images/shelest-home/shelest-home-8.jpg'

export const slides = [
  ShelestHomeSlide1,
  ShelestHomeSlide2,
  ShelestHomeSlide3,
  ShelestHomeSlide4,
  ShelestHomeSlide5,
  ShelestHomeSlide6,
  ShelestHomeSlide7,
  ShelestHomeSlide8,
]
