import React from 'react'
import { Space } from 'antd'
import { useTranslation } from 'react-i18next'

import './ShelestHome.scss'

import SolarStructureImage from '../../static/images/shelest-home/solar-structure.png'

const SolarStructure = () => {
  const { t } = useTranslation()

  return (
    <div className="solar-structure-wrapper">
      <div className="solar-structure-info">
        <Space className="solar-structure-inner" size={40} direction="vertical">
          <h1>
            {t('ShelestHome.SolarStructure.Title')}
            <br />
            {t('ShelestHome.SolarStructure.TitleSecond')}
          </h1>
          <div className="solar-structure-description-wrapper">
            <Space
              className="solar-structure-description"
              direction="vertical"
              size={10}
            >
              <p>{t('ShelestHome.SolarStructure.Description')}</p>
              <p>{t('ShelestHome.SolarStructure.DescriptionSecond')}</p>
            </Space>
          </div>
        </Space>
        <Space className="solar-structure-features" size={20}>
          <Space
            className="solar-structure-feature-item"
            direction="vertical"
            size={5}
          >
            <h1>8 {t('ShelestHome.SolarStructure.Features.GA')}</h1>
            <p>{t('ShelestHome.SolarStructure.Features.Territory')}</p>
          </Space>
          <Space
            className="solar-structure-feature-item"
            direction="vertical"
            size={5}
          >
            <h1>4 {t('ShelestHome.SolarStructure.Features.GA')}</h1>
            <p>{t('ShelestHome.SolarStructure.Features.NearShoreZone')}</p>
          </Space>
          <Space
            className="solar-structure-feature-item"
            direction="vertical"
            size={5}
          >
            <h1>41</h1>
            <p>{t('ShelestHome.SolarStructure.Features.Cottages')}</p>
          </Space>
        </Space>
      </div>
      <div className="solar-structure-image">
        <img src={SolarStructureImage} />
      </div>
    </div>
  )
}

export default SolarStructure
