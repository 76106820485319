import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ClockCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import { isEmpty } from 'ramda'
import { useTranslation } from 'react-i18next'

import { validateRange } from '../../utils/helpers'
import useOnClickOutside from '../../hooks/useOnClickOutside'

import './TimePicker.scss'

const TimePicker = ({
  // TODO: make it possible to allow disabling hours
  // whetherToShowHours,
  whetherToShowMinutes,
  whetherToShowSeconds,
  placeholder,
  hoursRange,
  minutesRange,
  secondsRange,
  hourStep,
  minuteStep,
  secondStep,
  onChange,
}) => {
  const { t } = useTranslation
  const [inputValue, setInputValue] = useState({
    hours: null,
    minutes: null,
    seconds: null,
  })
  const [activeKeys, setActiveKeys] = useState({
    hours: null,
    minutes: null,
    seconds: null,
  })
  const [hours, setHours] = useState(null)
  const [minutes, setMinutes] = useState(null)
  const [seconds, setSeconds] = useState(null)
  const [areOptionsShown, setOptionsVisibility] = useState(false)

  const ref = useRef()

  const clearData = e => {
    setHours(null)
    setMinutes(null)
    setSeconds(null)
    setInputValue({
      hours: null,
      minutes: null,
      seconds: null,
    })
    onChange(null)
    setActiveKeys({
      hours: null,
      minutes: null,
      seconds: null,
    })
    if (e) e.stopPropagation()
  }

  const showOptions = () => setOptionsVisibility(true)

  const hideOptions = () => setOptionsVisibility(false)

  const whetherToClearState = () => {
    if (!whetherToShowSeconds && !whetherToShowMinutes) {
      if (!hours) {
        return true
      }
    } else if (!whetherToShowSeconds) {
      if (hours && !minutes) {
        return true
      }
    } else {
      if (hours && (!minutes || !seconds)) {
        return true
      }
    }
  }

  useOnClickOutside(ref, () => {
    if (whetherToClearState()) {
      clearData()
    }
    hideOptions()
  })

  const getTimeMeasurementStep = type => {
    switch (type) {
      case 'hours':
        return hourStep
      case 'minutes':
        return minuteStep
      case 'seconds':
        return secondStep
      default:
        return
    }
  }

  const getTimeMeasurement = (range, type, max) => {
    if (isEmpty(range)) {
      const step = getTimeMeasurementStep(type)
      const array = []

      for (let i = 0; i < max; i += step) {
        if (i < 10) {
          array.push(String(`0${i}`))
        } else {
          array.push(String(i))
        }
      }
      return array
    } else {
      const isRangeValid = validateRange(range, type)

      if (isRangeValid) {
        const array = []
        const step = getTimeMeasurementStep(type)

        if (range[1] < max) {
          for (let i = range[0]; i <= range[1]; i += step) {
            if (i < 10) {
              array.push(String(`0${i}`))
            } else {
              array.push(String(i))
            }
          }
        } else {
          for (let i = range[0]; i < range[1]; i += step) {
            if (i < 10) {
              array.push(String(`0${i}`))
            } else {
              array.push(String(i))
            }
          }
        }
        return array
      }
      return []
    }
  }

  const setMeasurement = (measurement, type) => {
    switch (type) {
      case 'hours':
        setActiveKeys({
          hours: `${type}${measurement}`,
          minutes: null,
          seconds: null,
        })
        setInputValue({ hours: measurement, minutes: null, seconds: null })
        setHours(measurement)
        setMinutes(null)
        setSeconds(null)
        return
      case 'minutes':
        if (!hours) return
        setActiveKeys({
          ...activeKeys,
          minutes: `${type}${measurement}`,
          seconds: null,
        })
        setInputValue({ ...inputValue, minutes: measurement, seconds: null })
        setMinutes(measurement)
        setSeconds(null)
        return
      case 'seconds':
        if (!minutes) return
        setActiveKeys({ ...activeKeys, seconds: `${type}${measurement}` })
        setInputValue({ ...inputValue, seconds: measurement })
        setSeconds(measurement)
        return
      default:
        return
    }
  }

  const getActiveClass = (measurement, type) => {
    switch (type) {
      case 'hours':
        if (`${type}${measurement}` === activeKeys.hours) {
          return 'active'
        } else {
          return ''
        }
      case 'minutes':
        if (`${type}${measurement}` === activeKeys.minutes) {
          return 'active'
        } else {
          return ''
        }
      case 'seconds':
        if (`${type}${measurement}` === activeKeys.seconds) {
          return 'active'
        } else {
          return ''
        }
      default:
        return
    }
  }

  const renderMeasurement = (measurementArray, type) =>
    measurementArray.map(measurement => (
      <li
        className={`${getActiveClass(measurement, type)}`}
        key={`${type}${measurement}`}
        onClick={() => setMeasurement(measurement, type)}
      >
        {measurement}
      </li>
    ))

  const renderOptions = () => {
    // TODO: make it possible to allow to disable hours
    // if (!whetherToShowMinutes && !whetherToShowSeconds && !whetherToShowHours) {
    //   throw new Error('At least one measurement should be presented')
    // }
    const renderHours = getTimeMeasurement(hoursRange, 'hours', 24)
    const renderMinutes = getTimeMeasurement(minutesRange, 'minutes', 60)
    const renderSeconds = getTimeMeasurement(secondsRange, 'seconds', 60)

    return (
      <div id="options-wrapper" className="options-wrapper">
        {renderHours.length ? (
          <div className="measurement-column">
            <span className="options-title">{t('TimePicker.Hours')}</span>
            <ul>{renderMeasurement(renderHours, 'hours')}</ul>
          </div>
        ) : null}
        {whetherToShowMinutes && renderMinutes.length && hours ? (
          <div className="measurement-column">
            <span className="options-title">{t('TimePicker.Minutes')}</span>
            <ul className={`${!hours ? 'disabled' : ''}`}>
              {renderMeasurement(renderMinutes, 'minutes')}
            </ul>
          </div>
        ) : null}
        {whetherToShowSeconds && renderSeconds.length && hours && minutes ? (
          <div className="measurement-column">
            <span className="options-title">{t('TimePicker.Seconds')}</span>
            <ul className={`${!minutes ? 'disabled' : ''}`}>
              {renderMeasurement(renderSeconds, 'seconds')}
            </ul>
          </div>
        ) : null}
      </div>
    )
  }

  const getInputValue = () => {
    if (
      inputValue.hours === null &&
      inputValue.minutes === null &&
      inputValue.seconds === null
    ) {
      return ''
    }
    let value = ''

    if (inputValue.hours) {
      value += inputValue.hours
    }
    if (whetherToShowMinutes && inputValue.minutes) {
      value += `:${inputValue.minutes}`
    }
    if (whetherToShowSeconds && inputValue.seconds) {
      value += `:${inputValue.seconds}`
    }
    return value
  }

  const isTimeSet = () => {
    if (!whetherToShowSeconds && !whetherToShowMinutes) {
      if (hours && !areOptionsShown) {
        return true
      }
    } else if (!whetherToShowSeconds) {
      if (hours && minutes && !areOptionsShown) {
        return true
      }
    } else {
      if (hours && minutes && seconds && !areOptionsShown) {
        return true
      }
    }
  }

  useEffect(() => {
    if (!whetherToShowSeconds && !whetherToShowMinutes) {
      if (hours) {
        hideOptions()
        onChange({ hours, minutes, seconds })
      }
    } else if (!whetherToShowSeconds) {
      if (hours && minutes) {
        hideOptions()
        onChange({ hours, minutes })
      }
    } else {
      if (hours && minutes && seconds) {
        hideOptions()
        onChange({ hours, minutes, seconds })
      }
    }
  }, [hours, minutes, seconds])

  return (
    <div
      tabIndex="0"
      className={`timepicker-wrapper ant-input ${
        areOptionsShown ? 'timepicker-wrapper-opened' : ''
      }`}
      onClick={showOptions}
      ref={ref}
    >
      <input
        id="timepicker-input"
        className={`timepicker-input`}
        autoComplete="false"
        placeholder={placeholder}
        value={getInputValue()}
        readOnly
      />
      <div className={`timepicker-clear ${isTimeSet() ? 'clearable' : ''}`}>
        <ClockCircleTwoTone twoToneColor="#c8c8c8" className="clock-icon" />
        <CloseCircleTwoTone
          twoToneColor="#c8c8c8"
          onClick={e => clearData(e)}
          className="clear-icon"
        />
      </div>
      {areOptionsShown ? renderOptions() : null}
    </div>
  )
}

TimePicker.propTypes = {
  // TODO: make it possible to allow to disable hours
  // whetherToShowHours: PropTypes.bool,
  whetherToShowMinutes: PropTypes.bool,
  whetherToShowSeconds: PropTypes.bool,
  placeholder: PropTypes.string,
  hoursRange: PropTypes.array,
  minutesRange: PropTypes.array,
  secondsRange: PropTypes.array,
  hourStep: PropTypes.number,
  minuteStep: PropTypes.number,
  secondStep: PropTypes.number,
  onChange: PropTypes.func,
}

TimePicker.defaultProps = {
  // TODO: make it possible to allow to disable hours
  // whetherToShowHours: true,
  whetherToShowMinutes: true,
  whetherToShowSeconds: true,
  placeholder: 'Choose time',
  hoursRange: [],
  minutesRange: [],
  secondsRange: [],
  hourStep: 1,
  minuteStep: 1,
  secondStep: 1,
  onChange: () => {},
}

export default TimePicker
