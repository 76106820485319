import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Form, Input, Select, notification } from 'antd'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'

import HeroImage from '../../../../components/HeroImage'
import Button from '../../../../components/Button'
import ImagesMasonry from '../../../../components/ImagesMasonry'

import { ukrainianPhoneNumberPattern } from '../../../../utils/constants'

import './NewYear.scss'

import NewYearHeroImage from '../../../../static/images/special-events/new-year/newYearHero.jpeg'
import NewYearHeroImageMob from '../../../../static/images/special-events/new-year/newYearHeroMob.jpeg'

import config from './config'
import useAbstractionMediaQueries from '../../../../hooks/useAbstractionMediaQueries'

const { Option } = Select

const NewYear = () => {
  const { isTablet } = useAbstractionMediaQueries()
  const sendDataToGTM = useGTMDispatch()

  const { t, i18n } = useTranslation()
  const [isModalOpen, setModalOpenState] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [submitForm] = Form.useForm()

  const HeroImageTitle = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span style={{ color: 'white' }}>
          {t('Home.Navigation.NewYearHeroTitleFirst')}
        </span>
      </div>
    )
  }

  const openModal = () => {
    setModalOpenState(true)
  }

  const closeModal = () => {
    submitForm.resetFields()
    setModalOpenState(false)
  }

  const onSubmit = values => {
    const phoneFinal = `38${values.phone}`

    setLoading(true)

    return fetch('https://api.shelest.ua/etc/new-year', {
      body: JSON.stringify({
        ...values,
        phone: phoneFinal,
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(() => {
        setLoading(false)
        submitForm.resetFields()
        closeModal()
        sendDataToGTM({
          event: 'FormSentNewYear',
          eventType: 'FormSent',
        })
        notification.success({
          message: t('Notification.Description.InvestRequestSuccess'),
        })
      })
      .catch(err => {
        console.log('err: ', err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const bgImage = !isTablet ? NewYearHeroImage : NewYearHeroImageMob

  const langToText = {
    ukr: (
      <>
        <div className="new-year-agenda-wrapper">
          <p>
            «Я маю одну перевагу: я бачу світ таким, яким він має бути» Повністю
            погоджуємося з мсьє Пуаро, адже ми бачимо новорічну ніч такою, якою
            вона має бути. Вперше за 5 років SHELEST влаштовує тематичне
            святкування.
          </p>
          <p>
            Запрошуємо на Новий рік у стилі «Східного Експреса». На три дні ви
            перенесетесь у 30-ті роки минулого сторіччя. Декорації, актори,
            перегляд шедеврів кіно, жива музика, активності — без жодних
            розслідувань й загадок в яскравій компанії ви зустрінете Новий
            2024-й рік.
          </p>
          <br />
          <p>
            Дякуємо ЗСУ за можливість провести свято в компанії друзів та
            близьких. Разом опівночі ми загадаємо одне-єдине бажання, про яке
            мріє вся країна.
          </p>
          <br />
          <p>
            «У цьому є своя романтика, друже. Впродовж трьох днів ці абсолютно
            незнайомі люди нерозлучні» Разом ми забудемо про всі турботи й
            вирушимо назустріч Новому року. І, як у справжньому потязі, наша
            «подорож» відбуватиметься за розкладом.
          </p>
          <br />
          <p>30 грудня</p>
          <p>
            Забуваємо про всі справи, розслабляємось й починаємо готуватися до
            святкування:
          </p>
          <ul>
            <li>14:00 — офіційне відкриття святкування;</li>
            <li>
              з 15:00 — Санта зустрічає діток у своєму будиночку, спілкується з
              дітьми, пригощає смаколиками та подарунками. оформлення та
              заселення в номери;
            </li>
            <li>16:00 — відкриття зони «листи від Санти»;</li>
            <li>
              17:00 — мініярмарок у стилі shelest із глінтвейном і розвагами;
            </li>
            <li>
              18:00-22:00 — показуємо світову класику новорічного кіно та
              пригощаємо попкорном.
            </li>
          </ul>
          <br />
          <p>31 грудня</p>
          <p>
            Із самого ранку наповнюємось гарним настроєм та під різдвяний
            плейліст прямуємо до ресторану:
          </p>
          <ul>
            <li>9:00-11:00 — снідаємо та смакуємо ігристе;</li>
            <li>
              12:00 — запуск новорічного потяга, відкриття будиночка Санти;
            </li>
            <li>
              13:00 — що на вас чекає в майбутньому? дізнайтеся у таролога;
            </li>
            <li>
              14:00 — дитячі майстеркласи: новорічні пряники, створення
              віночків, свічок, оздоблення іграшок;
            </li>
            <li>
              16:00 — мініярмарок у стилі shelest із глінтвейном і розвагами;
            </li>
            <li>
              18:00-20:00 — ви можете трохи відпочити й перевтілитися в образи
              для головної ночі цього року;
            </li>
            <li>
              20:00-21:00 — збір гостей в ресторані, фотозона, виступ
              танцюристів;
            </li>
            <li>
              21:00-22:00 — збираємось в ресторані, смакуємо welcome drinks,
              беремо участь у фотосесії. також для вас виступ танцюристів та
              безпрограшна лотерея;
            </li>
            <li>
              22:00-23:00 — святкова вечеря, розваги від ведучого, виступ
              танцюристів;
            </li>
            <li>
              23:00-23:40 — насолоджуємось виступом Марти Адамчук & Біг бенд;
            </li>
            <li>
              23:40-00:40 — інтерактиви від ведучого, промова Президента,
              опівночі загадуємо найважливіше для нас та країни бажання.
              спостерігаємо за виступом танцюристів та влаштовуємо танцювальний
              майстерклас для гостей;
            </li>
            <li>
              00:40-1:20 — Марта Адамчук & Біг бенд продовжують запалювати
              новорічний настрій;
            </li>
            <li>00:40-3:00 — танцюємо під dj-сет.</li>
          </ul>
          <br />
          <p>1 січня</p>
          <p>
            Пригадуємо найяскравіші моменти вечора, гуляємо в лісі, робимо
            нічого:
          </p>
          <ul>
            <li>
              8:00-12:00 — снідаємо похмільним супом, олів’є та келихом
              ігристого;
            </li>
            <li>
              12:00-17:00 — проведіть час активно на свіжому повітрі або
              подаруйте собі денний сон — після новорічної «подорожі» це
              необхідно:
            </li>
            <li>17:00 — пізній виїзд.</li>
          </ul>
        </div>
        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 20,
            paddingTop: 20,
          }}
        >
          <h2 style={{ textAlign: 'center' }}>
            «Проходить 3 дні, вони розлучаються…» Щоб зустрітися знову! Попереду
            ще багато приємних миттєвостей в SHELEST, то ж ми неодмінно
            побачимось. А поки готуємося до Нового року. Ви з нами, мон амі?
          </h2>
          <Button
            classNames="btn-mid-pad small-btn btn-green h-40"
            onClick={openModal}
            buttonId="new_year_button"
          >
            Я з вами
          </Button>
        </div>
      </>
    ),
    en: (
      <>
        <div className="new-year-agenda-wrapper">
          <p>
            "I have one advantage: I see the world as it should be" Completely
            we agree with Monsieur Poirot, because we see New Year's Eve as it
            is she should be. For the first time in 5 years, SHELEST organizes a
            thematic event celebration.
          </p>
          <p>
            We invite you to the New Year in the style of the Eastern Express.
            For three days you you will be transferred to the 30s of the last
            century. Scenery, actors, viewing masterpieces of cinema, live
            music, activities - without any investigations and mysteries in a
            bright company, you will meet Novy 2024th year.
          </p>
          <br />
          <p>
            Thank you AFU for the opportunity to spend the holiday in the
            company of friends and loved ones Together at midnight we will make
            a single wish, about which the whole country dreams.
          </p>
          <br />
          <p>
            “There's a romance to that, my friend. During three days these
            absolutely strangers are inseparable" Together we will forget all
            worries and let's go to meet the New Year. And, as in a real train,
            ours the "trip" will take place according to the schedule.
          </p>
          <br />
          <p>December 30</p>
          <p>
            We forget about all matters, relax and start preparing for
            celebration:
          </p>
          <ul>
            <li>14:00 - official opening of the celebration;</li>
            <li>
              from 15:00 - Santa meets the children in his house, communicates
              with children, treats them with goodies and gifts. registration
              and check-in in rooms;
            </li>
            <li>16:00 - opening of the "letters from Santa" zone;</li>
            <li>
              17:00 — shelest-style mini-fair with mulled wine and
              entertainment;
            </li>
            <li>
              18:00-22:00 - we show world classics of New Year's movies and
              treat us with popcorn.
            </li>
          </ul>
          <br />
          <p>December 31</p>
          <p>
            From the very morning, we are filled with a good mood and for
            Christmas playlist heading to the restaurant:
          </p>
          <ul>
            <li>9:00-11:00 - we have breakfast and taste sparkling wine;</li>
            <li>
              12:00 — launch of the New Year's train, opening of Santa's house;
            </li>
            <li>
              13:00 - what awaits you in the future? find out from a
              tarotologist;
            </li>
            <li>
              14:00 — children's workshops: New Year's gingerbread, creation
              wreaths, candles, decorating toys;
            </li>
            <li>
              16:00 — shelest-style mini fair with mulled wine and
              entertainment;
            </li>
            <li>
              18:00-20:00 - you can rest a little and transform into images for
              the main night this year;
            </li>
            <li>
              20:00-21:00 — gathering of guests in the restaurant, photo booth,
              performance dancers;
            </li>
            <li>
              21:00-22:00 — we gather in the restaurant, enjoy welcome drinks,
              we take part in a photo shoot. also for you the performance of
              dancers and winless lottery;
            </li>
            <li>
              22:00-23:00 — celebratory dinner, entertainment by the host,
              performance dancers;
            </li>
            <li>
              23:00-23:40 - enjoy the performance of Marta Adamchuk & Big Band;
            </li>
            <li>
              23:40-00:40 — interactives from the presenter, the President's
              speech, at midnight we make the most important wish for us and the
              country. we watch the performance of dancers and arrange a dance
              party master class for guests;
            </li>
            <li>
              00:40-1:20 — Marta Adamchuk & Big Band continue to ignite New
              Year's mood;
            </li>
            <li>00:40-3:00 - we dance to a DJ set.</li>
          </ul>
          <br />
          <p>January 1</p>
          <p>
            We recall the brightest moments of the evening, walk in the forest,
            do nothing:
          </p>
          <ul>
            <li>
              8:00-12:00 - we have breakfast with hangover soup, Olivier and a
              glass sparkling;
            </li>
            <li>
              12:00-17:00 - spend time actively in the fresh air or treat
              yourself to a day's sleep - after the New Year's "trip" it is
              necessary:
            </li>
            <li>17:00 - late departure.</li>
          </ul>
        </div>
        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 20,
            paddingTop: 20,
          }}
        >
          <h2 style={{ textAlign: 'center' }}>
            Take your seats - our train will soon leave for 2024. Are you with
            us mon ami? Leave a request, we will contact you.
          </h2>
          <Button
            classNames="btn-mid-pad small-btn btn-green h-40"
            onClick={openModal}
            buttonId="new_year_button"
          >
            I'm in
          </Button>
        </div>
      </>
    ),
  }

  return (
    <div className="new-year-wrapper">
      <HeroImage
        backgroundImage={bgImage}
        // heroText={<HeroImageTitle />}
        wrapperStyle={{
          height: 600,
        }}
      />
      <div className="new-year-info">
        {/* <div className="new-year-plans">
          <div className="new-year-plans-list-wrapper">
            <h3 className="list-title">Плани на Новий Рік</h3>
            <ul className="list">
              <li className="list-item">Знайти локацію</li>
              <li className="list-item">Придумати тему вечірки</li>
              <li className="list-item">Підготувати меню</li>
              <li className="list-item">Скласти плейлист</li>
              <li className="list-item">Підібрати фільми</li>
              <li className="list-item">Вигадати, як розважити дітей</li>
              <li className="list-item">Конкурси?</li>
              <li className="list-item">Купити продукти</li>
              <li className="list-item">Приготувати святкову вечерю</li>
            </ul>
          </div>
          <div className="new-year-plans-list-wrapper success">
            <h3 className="list-title">Плани на Новий Рік</h3>
            <ul className="list">
              <li className="list-item success">
                <span>
                  забронювати будиночок чи номер в SHELEST на новорічну ніч і
                  робити нічого
                </span>
              </li>
            </ul>
          </div>
        </div> */}
        {langToText[i18n.language]}
        <div style={{ marginTop: 20 }}>
          <ImagesMasonry masonryConfig={config.masonryConfig} />
        </div>
      </div>
      <Modal
        title={t('NewYear.Form.Title')}
        visible={isModalOpen}
        onOk={() => submitForm.submit()}
        okText={t('NewYear.Form.OK')}
        cancelText={t('NewYear.Form.Cancel')}
        onCancel={closeModal}
        okButtonProps={{ loading: isLoading }}
      >
        <Form onFinish={onSubmit} form={submitForm}>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: t('Validation.Required'),
              },
            ]}
          >
            <Input placeholder={t('InputPlaceholders.FullNameShort')} />
          </Form.Item>
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                message: t('Validation.Required'),
              },
              {
                pattern: ukrainianPhoneNumberPattern,
                message: t('Validation.WrongPhoneFormat'),
              },
            ]}
          >
            <Input
              maxLength={10}
              addonBefore="+38"
              placeholder={t('InputPlaceholders.PhoneNumber')}
              onChange={e => setPhoneNumber(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: t('Validation.Required'),
              },
              {
                type: 'email',
                message: t('Validation.InvalidEmailFormat'),
              },
            ]}
          >
            <Input placeholder={t('InputPlaceholders.Email')} />
          </Form.Item>
          <Form.Item
            name="adults"
            rules={[
              {
                required: true,
                message: t('Validation.Required'),
              },
              {
                pattern: /^\d+$/,
                message: t('Validation.OnlyNumbers'),
              },
            ]}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Input
                maxLength={3}
                placeholder={t('InputPlaceholders.Adults')}
              />
            </div>
          </Form.Item>
          <Form.Item
            name="children"
            rules={[
              {
                required: true,
                message: t('Validation.Required'),
              },
              {
                pattern: /^\d+$/,
                message: t('Validation.OnlyNumbers'),
              },
            ]}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Input
                maxLength={3}
                placeholder={t('InputPlaceholders.Children')}
              />
            </div>
          </Form.Item>
          <Form.Item
            label={t('InputPlaceholders.WantToLiveIn')}
            name="appartments"
            rules={[{ required: true, message: t('Validation.Required') }]}
          >
            <Select
              placeholder={t('InputPlaceholders.ChoosePlace.Placeholder')}
            >
              <Option value="готелі">
                {t('InputPlaceholders.ChoosePlace.Hotel')}
              </Option>
              <Option value="будиночку">
                {t('InputPlaceholders.ChoosePlace.House')}
              </Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default NewYear
