import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'
import { ModalProvider } from 'react-modal-hook'
// import ReactGA from 'react-ga'

import Container from './layouts/Container'
//import { ClearCacheProvider, useClearCacheCtx } from 'react-clear-cache';

import { store } from './store'
import registerServiceWorker from './services/registerServiceWorker'

import './i18n/i18n'

import 'antd/dist/antd.less'
import './global.scss'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'



/*
import express from 'express';
const apps = express();
apps.use(function(req, res, next) {
    if (!req.user) {
        res.header('Cache-Control', 'private, no-cache, no-store, must-revalidate');
        res.header('Expires', '-1');
        res.header('Pragma', 'no-cache');
        res.header('PragmaDebug', 'no-cache');
    }
    next();
});*/
//app.use('public', express.static('public'));
//app.use(express.static(path.join(__dirname, 'public')));

// ReactGA.initialize('UA-221151384-1')
// ReactGA.pageview(window.location.pathname + window.location.search)

const App = () => {
  //const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();
  const history = createBrowserHistory()

  useEffect(() => {
    const vh = window.innerHeight * 0.01

    document.documentElement.style.setProperty('--vh', `${vh}px`)

  }, [])

  return (
    <Provider store={store}>
      <ModalProvider>
        <Router history={history}>
          <Container />
        </Router>
      </ModalProvider>
    </Provider>
  )


}


ReactDOM.render(<App />, document.getElementById('root'))

registerServiceWorker()
