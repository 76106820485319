import React from 'react'
import PropTypes from 'prop-types'

import useSystemLanguage from '../../../hooks/useSystemLanguage'

import './SwitcherNavigation.scss'

import roomsTitleUkr from '../../../static/images/rooms/roomsTitleUkr.svg'
import roomsTitleEn from '../../../static/images/rooms/roomsTitleEng.svg'
import roomsTitleRus from '../../../static/images/rooms/roomsTitleRus.svg'

const SwitcherNavigation = ({ rooms, activeRoom, setActiveRoom }) => {
  const { systemLanguage } = useSystemLanguage()

  const getTitle = () => {
    switch (systemLanguage) {
      case 'en':
        return <img src={roomsTitleEn} alt="" />
      case 'ru':
        return <img src={roomsTitleRus} alt="" />
      default:
        return <img src={roomsTitleUkr} alt="" />
    }
  }

  const getRoomsNavigation = () => {
    return rooms.map(room => {
      // rewrite styles
      const roomName =
        room.name === 'Family Classic Forest View Room'
          ? 'Family classic forest view room'
          : room.name

      return (
        <li
          key={room.key}
          onClick={() => setActiveRoom(room)}
          className={`navigation-wrapper-list-item ${
            activeRoom.name === room.name && 'active'
          }`}
        >
          <span className="dash">-</span>
          {roomName}
        </li>
      )
    })
  }

  const title = getTitle()
  const roomsNavigation = getRoomsNavigation()

  return (
    <div className="switcher-navigation-wrapper">
      <div className="navigation-title">{title}</div>
      <ul className="navigation-wrapper">{roomsNavigation}</ul>
    </div>
  )
}

SwitcherNavigation.propTypes = {
  rooms: PropTypes.array.isRequired,
  activeRoom: PropTypes.object.isRequired,
  setActiveRoom: PropTypes.func.isRequired,
}

export default SwitcherNavigation
