import React from 'react'
// import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { DatePicker, Select, Form } from 'antd'
import moment from 'moment'

import TimePicker from '../../../components/TimePicker'
import Button from '../../../components/Button'

import useSystemLanguage from '../../../hooks/useSystemLanguage'
import {
  dateFormat,
  validateMessages,
  hourAndMinutesFormat,
} from '../../../utils/constants'
import { getLocale } from '../../../utils/helpers'

import './BookingRestaurant.scss'

const { Option } = Select

const BookingRestaurant = () => {
  moment.updateLocale('uk', {
    weekdays: [
      'Неділя',
      'Понеділок',
      'Вівторок',
      'Середа',
      'Четвер',
      `П'ятниця`,
      'Субота',
    ],
  })
  moment.updateLocale('ru', {
    weekdays: [
      'Воскресенье',
      'Понедельник',
      'Вторник',
      'Среда',
      'Четверг',
      'Пятница',
      'Суббота',
    ],
  })

  const { t } = useTranslation()
  const { systemLanguage } = useSystemLanguage()

  const onFinish = values => {
    console.log(values)
  }

  const onTimePickerChange = value => {
    console.log('value: ', value)
  }

  return (
    <>
      <div className="filters-wrapper booking-pool">
        <Form onFinish={onFinish} validateMessages={validateMessages}>
          <div className="filters">
            <div className="filters-item">
              <span className="label">
                {t('Booking.Restaurant.Filters.RelaxingDate')}
              </span>
              <Form.Item
                name="date"
                rules={[{ required: true, message: t('Validation.Required') }]}
              >
                <DatePicker
                  format={dateFormat}
                  locale={getLocale(systemLanguage)}
                />
              </Form.Item>
            </div>
          </div>
          <div className="filters">
            <div className="filters-item">
              <span className="label">
                {t('Booking.Restaurant.Filters.Time')}
              </span>
              <Form.Item
                name="time"
                rules={[{ required: true, message: t('Validation.Required') }]}
              >
                <TimePicker
                  whetherToShowSeconds={false}
                  minuteStep={15}
                  hoursRange={[10, 23]}
                  placeholder={t('Booking.Restaurant.Placeholders.SelectTime')}
                />
              </Form.Item>
            </div>
            <div className="filters-item">
              <span className="label">
                {t('Booking.Restaurant.Filters.PersonsCount')}
              </span>
              <Form.Item name="personsCount" initialValue="1">
                <Select style={{ width: 65 }}>
                  <Option value="1">1</Option>
                  <Option value="2">2</Option>
                  <Option value="3">3</Option>
                  <Option value="4">4</Option>
                  <Option value="5">5</Option>
                  <Option value="6">6</Option>
                  <Option value="7">7</Option>
                  <Option value="8">8</Option>
                  <Option value="9">9</Option>
                  <Option value="10">10</Option>
                  <Option value="11">11</Option>
                  <Option value="12">12</Option>
                </Select>
              </Form.Item>
            </div>
            <div className="filters-item">
              <span className="label">
                {t('Booking.Restaurant.Filters.ChildsCount')}
              </span>
              <Form.Item name="childsCount" initialValue="0">
                <Select style={{ width: 65 }}>
                  <Option value="0">0</Option>
                  <Option value="1">1</Option>
                  <Option value="2">2</Option>
                  <Option value="3">3</Option>
                  <Option value="4">4</Option>
                  <Option value="5">5</Option>
                  <Option value="6">6</Option>
                  <Option value="7">7</Option>
                  <Option value="8">8</Option>
                  <Option value="9">9</Option>
                  <Option value="10">10</Option>
                  <Option value="11">11</Option>
                  <Option value="12">12</Option>
                </Select>
              </Form.Item>
            </div>
            <Button
              classNames="btn-green btn-mid-pad pv-0 h-30 fsz-15 small-btn"
              buttonId="booking_restaurant_check_button"
            >
              {t('Booking.Pool.Placeholders.Check')}
            </Button>
          </div>
        </Form>
      </div>
      <div className="inner-content-wrapper">
        <div className="inner-content">
          <h1 className="inner-content-title">
            {t('Booking.Restaurant.SearchStatus.Begin')}
          </h1>
        </div>
      </div>
    </>
  )
}

BookingRestaurant.propTypes = {}

export default BookingRestaurant
