import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({ isAuthenticated, ...props }) => {
  const token = localStorage.getItem('token')

  return token ? <Route key={props.path} {...props} /> : <Redirect to="/" />
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
})

const mapDispatchToProps = dispatch => ({})

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
}

PrivateRoute.defaultProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
