import React from 'react'
// import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { DatePicker, Select, Form } from 'antd'
import moment from 'moment'

import Button from '../../../components/Button'
import TimePicker from '../../../components/TimePicker'

import useSystemLanguage from '../../../hooks/useSystemLanguage'
import { dateFormat, validateMessages } from '../../../utils/constants'
import { getLocale } from '../../../utils/helpers'

import './BookingPool.scss'

import 'moment/locale/uk'
import 'moment/locale/ru'
import 'moment/locale/en-gb'

const { Option } = Select

const BookingPool = () => {
  moment.updateLocale('uk', {
    weekdays: [
      'Неділя',
      'Понеділок',
      'Вівторок',
      'Середа',
      'Четвер',
      `П'ятниця`,
      'Субота',
    ],
  })
  moment.updateLocale('ru', {
    weekdays: [
      'Воскресенье',
      'Понедельник',
      'Вторник',
      'Среда',
      'Четверг',
      'Пятница',
      'Суббота',
    ],
  })

  const { systemLanguage } = useSystemLanguage()
  const { t } = useTranslation()

  const onFinish = values => {
    console.log(values)
  }

  return (
    <>
      <div className="filters-wrapper booking-pool">
        <Form onFinish={onFinish} validateMessages={validateMessages}>
          <div className="filters">
            <div className="filters-item">
              <span className="label">
                {t('Booking.Pool.Filters.RelaxingDate')}
              </span>
              <Form.Item
                name="date"
                rules={[{ required: true, message: t('Validation.Required') }]}
              >
                <DatePicker
                  format={dateFormat}
                  locale={getLocale(systemLanguage)}
                />
              </Form.Item>
            </div>
          </div>
          <div className="filters">
            <div className="filters-item">
              <span className="label">
                {t('Booking.Pool.Filters.RelaxingTime')}
              </span>
              <Form.Item
                name="time"
                rules={[{ required: true, message: t('Validation.Required') }]}
              >
                <TimePicker
                  whetherToShowSeconds={false}
                  minuteStep={15}
                  hoursRange={[10, 23]}
                  placeholder={t('Booking.Pool.Placeholders.SelectTime')}
                />
              </Form.Item>
            </div>
            <div className="filters-item">
              <span className="label">
                {t('Booking.Pool.Filters.SunLoungersCount')}
              </span>
              <Form.Item name="sunLoungers" initialValue="1">
                <Select style={{ width: 65 }}>
                  <Option value="1">1</Option>
                  <Option value="2">2</Option>
                  <Option value="3">3</Option>
                </Select>
              </Form.Item>
            </div>
            <Button
              classNames="btn-green btn-mid-pad pv-0 h-30 fsz-15 small-btn"
              buttonId="booking_pool_check_button"
            >
              {t('Booking.Pool.Placeholders.Check')}
            </Button>
          </div>
        </Form>
      </div>
      <div className="inner-content-wrapper">
        <div className="inner-content">
          <h1 className="inner-content-title">
            {t('Booking.Pool.SearchStatus.Begin')}
          </h1>
        </div>
      </div>
    </>
  )
}

BookingPool.propTypes = {}

export default BookingPool
