import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import HeroImage from '../../components/HeroImage'

import DoNothingBG from '../../static/images/do-nothing/do-nothing-bg.jpeg'
import DoNothingItem from './DoNothingItem'

import { doNothingOptionsUkr, doNothingOptionsEng } from './utils'

import './DoNothing.scss'

const translationToLang = {
  ukr: doNothingOptionsUkr,
  en: doNothingOptionsEng,
}

const HeroImageTitle = () => {
  const { t, i18n } = useTranslation()

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span style={{ color: 'white' }}>
        {t('Home.Navigation.DoNothingTitleFirst')}
      </span>
      <span style={{ color: 'white' }}>
        {t('Home.Navigation.DoNothingTitleSecond')}
      </span>
    </div>
  )
}

const DoNothing = () => {
  const { i18n } = useTranslation()

  const renderedDoNothingOptions = useMemo(() => {
    return translationToLang[i18n.language].map(option => {
      return <DoNothingItem item={option} key={option.key} />
    })
  }, [i18n.language])

  return (
    <div className="do-nothing-wrapper">
      <HeroImage backgroundImage={DoNothingBG} heroText={<HeroImageTitle />} />
      <div className="do-nothing-content-wrapper">
        <div className="do-nothing-text">
          <div className="do-nothing-links">{renderedDoNothingOptions}</div>
        </div>
      </div>
    </div>
  )
}

export default DoNothing
