import React from 'react'
import { useTranslation } from 'react-i18next'

import LandscapeImage from '../../static/images/shelest-home/landscape.jpg'

import './ShelestHome.scss'

const Landscape = () => {
  const { t } = useTranslation()

  return (
    <div className="landscape-wrapper">
      <div className="image-wrapper">
        <img src={LandscapeImage} />
      </div>
      <div className="info-wrapper">
        <h1>{t('ShelestHome.Landscape.Title')}</h1>
        <p>{t('ShelestHome.Landscape.Description')}</p>
      </div>
    </div>
  )
}

export default Landscape
