import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import { Form, Input, Space, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import Button from '../../components/Button'

import NatureImage from '../../static/images/shelest-home/nature.png'
import CozyImage from '../../static/images/shelest-home/cozy.png'
import HomeImage from '../../static/images/shelest-home/home.png'

import ShelestHomeLogo from '../../static/images/shelest-home/shelest-home-logo.png'
import ConceptImage from '../../static/images/shelest-home/concept.png'

import { ukrainianPhoneNumberPattern } from '../../utils/constants'

import SolarStructure from './SolarStructure'
import Landscape from './Landscape'
import Simplicity from './Simplicity'

import { slides } from './utils'

import './ShelestHome.scss'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'

const ShelestHome = () => {
  const [phoneNumber, setPhoneNumber] = useState(null)
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const history = useHistory()
  const sendDataToGTM = useGTMDispatch()

  const carouselSlides = slides.map(slide => (
    <div key={slide} className="shelest-home-carousel-slide">
      <img src={slide} />
    </div>
  ))

  const onFinish = values => {
    const phoneFinal = `38${values.phone}`

    return fetch('https://api.shelest.ua/etc/shelest-home', {
      body: JSON.stringify({
        ...values,
        phone: +phoneFinal,
        // utmSource: localStorage.getItem('utm_source'),
        // utmMedium: localStorage.getItem('utm_medium'),
        // utmCampaign: localStorage.getItem('utm_campaign'),
        // utmContent: localStorage.getItem('utm_content'),
      }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(() => {
      sendDataToGTM({
        event: 'FormSentHome',
        eventType: 'FormSent',
      })
      form.resetFields()
      notification.success({
        message:
          'Перший крок до будинку в Shelest Home зроблено. Найближчим часом наш менеджер зв’яжеться з вами, щоб розрахувати вартість оселі.',
      })
    })
  }

  return (
    <div className="shelest-home-wrapper">
      <div className="shelest-home-hero-section">
        <div className="logo-wrapper">
          <img src={ShelestHomeLogo} />
        </div>
        <div className="text-wrapper">
          <h1>{t('ShelestHome.Hero.Title')}</h1>
          <h2>{t('ShelestHome.Hero.Description')}</h2>
        </div>
        <a href="#form-block" className="uppercase">
          <Button classNames="btn-mid-pad btn-green">
            {t('ShelestHome.Hero.MoreButton')}
          </Button>
        </a>
      </div>
      <div className="home-section shelest-home">
        <h1>{t('ShelestHome.OurGoal.First')}</h1>
        <h1>{t('ShelestHome.OurGoal.Second')}</h1>
      </div>
      <div className="home-section three-photos">
        <div className="home-section-child first">
          <h1>{t('ShelestHome.Nature')}</h1>
          <div className="image-wrapper">
            <img src={NatureImage} />
          </div>
        </div>
        <div className="home-section-child second">
          <div className=" image-wrapper">
            <img src={CozyImage} />
          </div>
          <h1>{t('ShelestHome.Cozy')}</h1>
        </div>
        <div className="home-section-child third">
          <h1>{t('ShelestHome.Home')}</h1>
          <div className="image-wrapper">
            <img src={HomeImage} />
          </div>
        </div>
      </div>
      <div className="slow-living-wrapper">
        <div className="slow-living">
          <div className="slow-living-title">
            <h1>
              {t('ShelestHome.SlowLiving.Title')}
              <br />
              {t('ShelestHome.SlowLiving.TitleSecond')}
            </h1>
          </div>
          <div className="title-description">
            {t('ShelestHome.SlowLiving.Text')}
          </div>
        </div>
        <div className="slow-living-description">
          {t('ShelestHome.SlowLiving.Description')}
        </div>
      </div>
      <SolarStructure />
      <img
        src={ConceptImage}
        style={{
          height: '100%',
        }}
        className="concept-image"
      />
      <Landscape />
      <Simplicity />
      <Carousel
        swipeable
        emulateTouch
        showIndicators={false}
        showThumbs={false}
        showStatus={false}
        className="shelest-home-carousel"
        centerMode
        centerSlidePercentage={80}
        autoPlay={false}
        showArrows={false}
      >
        {carouselSlides}
      </Carousel>
      <div className="form-wrapper" id="form-block">
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          className="form"
        >
          <h1>{t('ShelestHome.Form.Title')}</h1>
          <Form.Item
            required
            name="name"
            label={t('InputPlaceholders.Name')}
            rules={[{ required: true, message: t('Validation.Required') }]}
          >
            <Input placeholder={t('InputPlaceholders.Name')} />
          </Form.Item>
          <Form.Item
            name="phone"
            label={t('InputPlaceholders.Phone')}
            rules={[
              {
                required: true,
                message: t('Validation.Required'),
              },
              {
                pattern: ukrainianPhoneNumberPattern,
                message: t('Validation.WrongPhoneFormat'),
              },
            ]}
          >
            <Input
              maxLength={10}
              addonBefore="+38"
              placeholder={t('InputPlaceholders.Phone')}
              onChange={e => setPhoneNumber(e.target.value)}
            />
          </Form.Item>
          <Form.Item name="email" label="E-mail">
            <Input placeholder="E-mail" />
          </Form.Item>
          <div className="actions-wrapper">
            <Button classNames="little-btn btn-green">
              {t('ShelestHome.Form.SubmitButton')}
            </Button>
            <a
              href="https://shelest.ua/shelest-home.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                classNames="btn-mid-pad little-btn btn-blue"
                htmlType="button"
              >
                {t('ShelestHome.Form.DownloadPDFButton')}
              </Button>
            </a>
            <a
              href="https://shelest.ua/accession_agreement.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                classNames="btn-mid-pad little-btn btn-green"
                htmlType="button"
              >
                {t('ShelestHome.Form.AccessionAgreement')}
              </Button>
            </a>
          </div>
        </Form>
      </div>
    </div>
  )
}

ShelestHome.propTypes = {}

ShelestHome.defaultProps = {}

export default ShelestHome
