import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const useSystemLanguage = () => {
  const [systemLanguage, setSystemLanguage] = useState(
    localStorage.getItem('prefferedLanguage') || 'ukr'
  )
  const { i18n } = useTranslation()
  const { language } = i18n

  useEffect(() => {
    setSystemLanguage(language)
  }, [language])

  return { systemLanguage, setSystemLanguage }
}

export default useSystemLanguage
