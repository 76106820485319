import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { isNil } from 'ramda'

const defaultIconStyle = {
  color: '#4C6852',
}

const Loader = ({ iconStyle, className, size = 20 }) => {
  const styles = isNil(iconStyle) ? defaultIconStyle : iconStyle

  return (
    <LoadingOutlined
      className={className}
      style={{ ...styles, fontSize: size }}
      spin
    />
  )
}

export default Loader
