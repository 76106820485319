import { put, all, takeLatest, delay, take } from 'redux-saga/effects'
import { uid } from 'react-uid'
import { isNil, isEmpty, empty } from 'ramda'
import { notification } from 'antd'

import { onGetTemporaryToken } from '../../auth/auth'
import { BookingService } from '../../../services/api'

import { resetRecentlyAddedHotel } from '../../cart/cart'

import { SUCCESS, FAILURE, START } from '../../constants'

const SEARCH_HOTEL_AVAILABILITY =
  'shelest/booking/hotel/SEARCH_HOTEL_AVAILABILITY'
const CLEAR_FORM = 'shelest/booking/CLEAR_FORM'

const initialState = {
  isHotelAvailabilityLoading: false,
  availableRooms: null,
  searchStatus: 'beginning',
}

const getSearchStatus = result => {
  if (isNil(result) || isEmpty(result)) {
    return 'emptyResult'
  } else {
    return 'nonEmptyResult'
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${SEARCH_HOTEL_AVAILABILITY}${START}`:
      return {
        ...state,
        isHotelAvailabilityLoading: true,
        searchStatus: 'searching',
        availableRooms: initialState.availableRooms,
      }

    case `${SEARCH_HOTEL_AVAILABILITY}${SUCCESS}`:
      return {
        ...state,
        isHotelAvailabilityLoading: false,
        availableRooms: action.payload.response,
        searchStatus: getSearchStatus(action.payload.response),
      }

    case `${SEARCH_HOTEL_AVAILABILITY}${FAILURE}`:
      return {
        ...state,
        isHotelAvailabilityLoading: false,
        searchStatus: 'failure',
      }

    case `${SEARCH_HOTEL_AVAILABILITY}${CLEAR_FORM}`:
      return {
        ...state,
        searchStatus: initialState.searchStatus,
        availableRooms: initialState.availableRooms,
      }

    default:
      return state
  }
}

export const onSearchHotelAvailability = action => ({
  type: `${SEARCH_HOTEL_AVAILABILITY}${START}`,
  payload: action,
})

const onSearchHotelAvailabilitySuccess = action => ({
  type: `${SEARCH_HOTEL_AVAILABILITY}${SUCCESS}`,
  payload: action,
})

const onSearchHotelAvailabilityFailure = () => ({
  type: `${SEARCH_HOTEL_AVAILABILITY}${FAILURE}`,
})

export const onClearHotelForm = () => ({
  type: `${SEARCH_HOTEL_AVAILABILITY}${CLEAR_FORM}`,
})

function* searchHotelAvailability(action) {
  try {
    yield put(resetRecentlyAddedHotel())
    yield delay(1000)

    const tokenType = action.payload.isAuthenticated
      ? 'token'
      : 'temporaryToken'

    let token = localStorage.getItem(tokenType)

    if (isEmpty(token) || isNil(token)) {
      yield put(onGetTemporaryToken())
      yield take('shelest/auth/GET_TEMPORARY_TOKEN_SUCCESS')
    }

    token = localStorage.getItem(tokenType)

    const res = yield BookingService.searchHotelAvailability(
      action.payload,
      token
    )

    yield put(onSearchHotelAvailabilitySuccess(res))
  } catch (err) {
    yield put(onSearchHotelAvailabilityFailure())
  }
}

export function* BookingHotelSagas() {
  yield all([
    takeLatest(`${SEARCH_HOTEL_AVAILABILITY}${START}`, searchHotelAvailability),
  ])
}

export default reducer
