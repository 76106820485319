import React from 'react'

import Bankets from '../../static/images/events/bankets.jpg'
import Pub from '../../static/images/events/pub.jpg'
import Restaurant from '../../static/images/events/restaurant.jpg'
import NearWater from '../../static/images/events/nearwater.JPG'
import Wedding from '../../static/images/events/wedding-main.jpg'

import Banket1 from '../../static/images/events/banket/banket1.jpg'
import Banket2 from '../../static/images/events/banket/banket2.jpg'
import Banket3 from '../../static/images/events/banket/banket3.jpg'
import Banket4 from '../../static/images/events/banket/banket4.jpg'
import Banket5 from '../../static/images/events/banket/banket5.jpg'
import Banket6 from '../../static/images/events/banket/banket6.jpg'
import Banket7 from '../../static/images/events/banket/banket7.jpg'
import Banket8 from '../../static/images/events/banket/banket8.jpg'
import Banket9 from '../../static/images/events/banket/banket9.jpg'

import Pub1 from '../../static/images/events/pub/pub1.jpg'
import Pub5 from '../../static/images/events/pub/pub5.png'
import Pub6 from '../../static/images/events/pub/pub6.jpg'
import Pub10 from '../../static/images/events/pub/pub10.jpg'
import Pub11 from '../../static/images/events/pub/pub11.jpg'
import Pub12 from '../../static/images/events/pub/pub12.jpg'
import Pub13 from '../../static/images/events/pub/pub13.jpg'
import Pub14 from '../../static/images/events/pub/pub14.jpg'

import Restaurant1 from '../../static/images/events/restaurant/restaurant1.jpg'
import Restaurant2 from '../../static/images/events/restaurant/restaurant2.jpg'
import Restaurant5 from '../../static/images/events/restaurant/restaurant5.jpg'
import Restaurant6 from '../../static/images/events/restaurant/restaurant6.jpg'
import Restaurant7 from '../../static/images/events/restaurant/restaurant7.jpg'
import Restaurant10 from '../../static/images/events/restaurant/restaurant10.jpg'

import NearWater1 from '../../static/images/events/near-water/nearwater1.jpeg'
import NearWater2 from '../../static/images/events/near-water/nearwater2.jpeg'
import NearWater3 from '../../static/images/events/near-water/nearwater3.jpeg'
import NearWater4 from '../../static/images/events/near-water/nearwater4.jpeg'

import Weddings1 from '../../static/images/events/weddings/weddings1.jpg'
import Weddings2 from '../../static/images/events/weddings/weddings2.jpg'
import Weddings3 from '../../static/images/events/weddings/weddings3.jpg'
import Weddings4 from '../../static/images/events/weddings/weddings4.jpg'
import Weddings5 from '../../static/images/events/weddings/weddings5.jpg'
import Weddings6 from '../../static/images/events/weddings/weddings6.jpg'
import Weddings7 from '../../static/images/events/weddings/weddings7.jpg'
import Weddings8 from '../../static/images/events/weddings/weddings8.jpg'
import Weddings9 from '../../static/images/events/weddings/wedding10.jpg'
import Weddings10 from '../../static/images/events/weddings/wedding11.jpg'
import Weddings11 from '../../static/images/events/weddings/wedding12.jpg'
import Weddings12 from '../../static/images/events/weddings/wedding13.jpg'
import Weddings13 from '../../static/images/events/weddings/wedding14.jpg'
import Weddings14 from '../../static/images/events/weddings/wedding15.jpg'
import Weddings15 from '../../static/images/events/weddings/wedding16.jpg'
import Weddings16 from '../../static/images/events/weddings/wedding17.jpg'
import Weddings17 from '../../static/images/events/weddings/wedding18.jpg'

import PhoneIcon from '../../static/icons/phone.svg'
import EnvelopeIcon from '../../static/icons/envelope.svg'
import ShelestLogo from '../../static/icons/shelest-big-logo.png'

const ContactsBlock = props => {
  const { t } = props

  return (
    <div className="contacts-block">
      <p>
        {t('Events.ContactUs.Tel')} <img src={PhoneIcon} />{' '}
        <a href="tel:+380676727885">067 672 78 85</a>
      </p>
      <p>
        {t('Events.ContactUs.Email')} <img src={EnvelopeIcon} />{' '}
        <a href="mailto:event@shelest.ua">event@shelest.ua</a>
      </p>
    </div>
  )
}

const EventHeader = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: 10,
    }}
  >
    <img src={ShelestLogo} style={{ height: 50, width: 180 }} />
  </div>
)

const RestaurantInner = props => {
  const { t, i18nField, i18n } = props

  const inner = {
    ukr: (
      <>
        <p>
          Простора локація для святкування важливої події: весілля, Дня
          народження або корпоративу. Посередині залу є бар, а також можна
          встановити сцену, зробити зону для танців і банкету.
        </p>
        <br />
        <p>
          Якщо ви бронюєте цей зал, є можливість одразу розмістити ваших гостей
          у будиночки поруч з рестораном або в готель. Щоб відпочити душею і
          тілом, а на ранок разом згадувати цей особливий день.
        </p>
        <br />
        <p>
          Ми беремо на себе всю організацію, щоб ви прожили свято вашої мрії.
        </p>
        <br />
        <p>
          Кількість місць
          <br />
          Фуршетом: 150
          <br />
          Банкетом: 100
        </p>
        <br />
        <p>
          Площa
          <br />
          299м<sup>2</sup>
        </p>
        <br />
      </>
    ),
    ru: (
      <>
        <p>
          Просторная локация, чтобы отпраздновать важное событие: свадьбу, День
          рождения или корпоратив. Посреди зала есть бар, а также можно
          установить сцену, сделать зону для танцев и банкета.
        </p>
        <br />
        <p>
          Если вы бронируете этот зал, ваши гости могут остаться в домиках рядом
          с рестораном или в отеле. Чтобы отдохнуть душой и телом, а утром
          вместе вспоминать этот особенный день.
        </p>
        <br />
        <p>
          Мы берем на себя всю организацию, чтобы вы прожили праздник вашей
          мечты.
        </p>
        <br />
        <p>
          Количество мест
          <br />
          Фуршетом: 150 <br />
          Банкетом: 100
        </p>
        <br />
        <p>
          Площадь
          <br />
          299м<sup>2</sup>
        </p>
        <br />
      </>
    ),
    en: (
      <>
        <p>
          A spacious location for celebrating an important event: wedding,
          birthday, or corporate party. There is a bar in the middle of the
          hall, and it's also possible to set up a stage and make an area for
          dancing and a banquet.
        </p>
        <br />
        <p>
          If you book this hall, it is possible to immediately place your guests
          in the houses next to the restaurant or in the hotel. To relax with
          your soul and body, and to remember this special day together in the
          morning.
        </p>
        <br />
        <p>
          We take care of all the organization for you to live the holiday of
          your dreams.
        </p>
        <br />
        <p>
          Number of seats
          <br />
          Buffet: 150 <br />
          Banquet: 100
        </p>
        <br />
        <p>
          Square
          <br />
          299м<sup>2</sup>
        </p>
        <br />
      </>
    ),
  }

  return (
    <div>
      {/* <EventHeader /> */}
      <h1>{t(`Events.${i18nField}.EventName`)}</h1>
      {inner[i18n.language]}
      <ContactsBlock t={t} />
    </div>
  )
}

const PubInner = props => {
  const { t, i18nField, i18n } = props

  const inner = {
    ukr: (
      <>
        <p>
          Пити авторські напої під хрускіт каміну, слухати теплі привітання і
          танцювати від щастя. Окрім основного залу, тут є тераса з виходом на
          вулицю і кострова зона. Можна змінити картинку і влаштувати затишні
          посиденьки біля вогнища.
        </p>
        <br />
        <p>
          Кількість місць
          <br />
          Фуршетом: 80
          <br />
          Банкетом: 50
        </p>
        <br />
        <p>
          Площа
          <br />
          180м<sup>2</sup>
        </p>
        <br />
      </>
    ),
    ru: (
      <>
        <p>
          Пить авторские напитки под звуки камина, слушать теплые поздравления и
          танцевать от счастья. Кроме основного зала, здесь есть терраса с
          выходом на улицу и костровая зона. Можно поменять картинку и устроить
          уютные посиделки возле костра.
        </p>
        <br />
        <p>
          Количество мест
          <br />
          Фуршетом: 80
          <br />
          Банкетом: 50
        </p>
        <br />
        <p>
          Площадь
          <br />
          180 м<sup>2</sup>
        </p>
        <br />
      </>
    ),
    en: (
      <>
        <p>
          Drink the signature drinks under the crackle of the fireplace, listen
          to warm greetings, and dance with happiness. In addition to the main
          hall, there is a terrace with access to the street and a bonfire area.
          You can change the picture and arrange cozy gatherings by the fire.
        </p>
        <br />
        <p>
          Number of seats
          <br />
          Buffet: 80
          <br />
          Banquet: 50
        </p>
        <br />
        <p>
          Square
          <br />
          180 м<sup>2</sup>
        </p>
        <br />
      </>
    ),
  }

  return (
    <div>
      {/* <EventHeader /> */}
      <h1>{t(`Events.${i18nField}.EventName`)}</h1>
      {inner[i18n.language]}
      <ContactsBlock t={t} />
    </div>
  )
}

const BanketInner = props => {
  const { t, i18nField, i18n } = props

  const inner = {
    ukr: (
      <>
        <p>
          Затишне місце для свята з найближчими людьми. Спочатку – вечеряти в
          закритій частині залу і насолоджуватися стравами від шефа. А потім
          переміститися на терасу і зігріватися теплими розмовами біля вогнища.
        </p>
        <br />
        <p>
          Кількість місць
          <br />
          Фуршетом: 40
          <br />
          Банкетом: 30
        </p>
        <br />
        <p>
          Площа
          <br />
          55м<sup>2</sup>
        </p>
        <br />
      </>
    ),
    ru: (
      <>
        <p>
          Уютное место для праздника с самыми близкими. Сначала – ужинать в
          закрытой части зала и наслаждаться блюдами от шефа. А потом вместе
          сидеть на террасе и согреваться теплыми разговорами возле костра.
        </p>
        <br />
        <p>
          Количество мест
          <br />
          Фуршетом: 40
          <br />
          Банкетом: 30
        </p>
        <br />
        <p>
          Площадь
          <br />
          55м<sup>2</sup>
        </p>
        <br />
      </>
    ),
    en: (
      <>
        <p>
          A cozy place for a holiday with the closest people. First, have dinner
          in a closed part of the hall and enjoy dishes from our chef. And then
          move to the terrace and warm up with warm conversations by the fire.
        </p>
        <br />
        <p>
          Number of seats
          <br />
          Buffet: 40
          <br />
          Banquet: 30
        </p>
        <br />
        <p>
          Area
          <br />
          55м<sup>2</sup>
        </p>
        <br />
      </>
    ),
  }

  return (
    <div>
      {/* <EventHeader /> */}
      <h1>{t(`Events.${i18nField}.EventName`)}</h1>
      {inner[i18n.language]}
      <ContactsBlock t={t} />
    </div>
  )
}

const NearWaterInner = props => {
  const { t, i18nField, i18n } = props

  const inner = {
    ukr: (
      <>
        <p>
          Локація, де нічого не відволікає від найважливішого: є лише шум річки,
          запах лісу і ваш особливий момент. Можна кататися на каяках або
          вейкбордах, а можна пити ігристе і ходити босоніж по піску.
        </p>
        <br />
        <p>
          В такій атмосфері стається магія — проходять дівич-вечори, весільні
          церемонії та смачні вечері.
        </p>
        <br />
        <p>
          Кількість місць
          <br />
          Фуршетом: 100
          <br />
          Банкетом: 80
        </p>
        <br />
        <p>
          Площа
          <br />
          165м
        </p>
        <br />
      </>
    ),
    ru: (
      <>
        <p>
          Локация, где ничего не отвлекает от самого важного: есть только запах
          леса, спокойные звуки реки и ваш особенный момент. Можно кататься на
          каяках или вейкбордах, а можно пить игристое и ходить босиком по
          песку.
        </p>
        <br />
        <p>
          В такой атмосфере происходит магия — проходят девичники, свадебные
          церемонии и вкусные ужины.
        </p>
        <br />
        <p>
          Количество мест
          <br />
          Фуршетом: 100
          <br />
          Банкетом: 80
        </p>
        <br />
        <p>
          Площадь
          <br />
          165м
        </p>
        <br />
      </>
    ),
    en: (
      <>
        <p>
          A location where nothing distracts from the most important thing:
          there is only the sound of the river, the smell of the forest, and
          your special moment. You can ride kayaks or wakeboards, drink
          sparkling wine and walk barefoot on the sand.
        </p>
        <br />
        <p>
          In such an atmosphere, magic happens - bachelorette parties, wedding
          ceremonies, and delicious dinners take place.
        </p>
        <br />
        <p>
          Number of seats
          <br />
          Buffet: 100
          <br />
          Banquet: 80
        </p>
        <br />
        <p>
          Area
          <br />
          165м
        </p>
        <br />
      </>
    ),
  }

  return (
    <div>
      {/* <EventHeader /> */}
      <h1>{t(`Events.${i18nField}.EventName`)}</h1>
      {inner[i18n.language]}
      <ContactsBlock t={t} />
    </div>
  )
}

const WeddingsInner = props => {
  const { t, i18nField, i18n } = props

  const inner = {
    ukr: (
      <>
        <p>
          Весілля у нас - це стильна локація, магія у повітрі і спогади на все
          життя. Щоб говорити про почуття, обійматися і навіть плакати від
          щастя. Бути тут і зараз, а потім передивлятися фото і знову
          повертатися в цей особливий день.
        </p>
      </>
    ),
    ru: (
      <>
        <p>
          Свадьба у нас – это локация среди леса, магия в воздухе и воспоминания
          на всю жизнь. Чтобы говорить о чувствах, обниматься и даже плакать от
          счастья. А потом пересматривать фото и снова возвращаться в этот
          особенный день.
        </p>
        <br />
        <p>
          Для большой свадебной вечеринки есть зал ресторана. Для празднования с
          меньшим количеством гостей – зал-паб с террасой и костровой, или зона
          у воды – с природой вокруг и водными развлечениями.
        </p>
        <br />
        <p>
          Вы можете арендовать домики и номера в отеле для гостей. Чтобы после
          насыщенного праздника почувствовать полный релакс, остаться еще на
          несколько дней и делать ничего.
        </p>
      </>
    ),
    en: (
      <>
        <p>
          A wedding at SHELEST is a location in the middle of the forest, with
          magic in the air and memories to last a lifetime. To talk about
          feelings, hug and even cry with happiness. And then look at the photo
          again and come back to this special day.
        </p>
        <br />
        <p>
          There is a restaurant hall for a large wedding party. For a
          celebration with a smaller number of guests, there is a hall-pub with
          a terrace and a bonfire, or an area near the water, surrounded by
          nature and water activities.
        </p>
        <br />
        <p>
          You can rent cottages and hotel rooms for your guests. To feel
          complete relaxation after a busy holiday, stay for a few more days and
          do nothing.
        </p>
      </>
    ),
  }

  return (
    <div>
      {/* <EventHeader /> */}
      <h1>{t(`Events.${i18nField}.EventName`)}</h1>
      {inner[i18n.language]}
    </div>
  )
}

export default [
  {
    i18nField: 'Wedding',
    iframe360: null,
    image: Wedding,
    url: 'wedding',
    toRender: false,
    eventName: 'Весілля у нас',
    shortDescription:
      'Весілля у нас - це стильна локація, магія у повітрі і спогади на все життя.',
    masonryConfig: {
      parentConfig: {
        rows: 15,
        cols: 3,
      },
      images: [
        {
          image: Weddings9,
          classes: 'row-span-2 col-span-1 row-start-1 col-start-1',
        },
        {
          image: Weddings10,
          classes: 'row-span-2 col-span-3 row-start-1 col-start-1',
        },
        {
          image: Weddings11,
          classes: 'col-span-2 row-span-2 row-start-1 col-start-2',
        },
        {
          image: Weddings12,
          classes: 'row-span-2 col-span-1 row-start-1 col-start-1',
        },
        {
          image: Weddings13,
          classes: 'row-span-2 col-span-3 row-start-1 col-start-1',
        },
        {
          image: Weddings14,
          classes: 'col-span-2 row-span-2 row-start-1 col-start-2',
        },
        {
          image: Weddings15,
          classes: 'row-span-2 col-span-1 row-start-1 col-start-1',
        },
        {
          image: Weddings16,
          classes: 'row-span-2 col-span-3 row-start-1 col-start-1',
        },
        {
          image: Weddings17,
          classes: 'col-span-2 row-span-2 row-start-1 col-start-2',
        },
        {
          image: Weddings3,
          classes: 'col-span-3 row-span-3 row-start-1 col-start-1',
        },
        // {
        //   image: Weddings3,
        //   classes: 'col-span-2 row-span-1 row-start-2 col-start-2',
        // },
        // {
        //   image: Weddings3,
        //   classes: 'col-span-2 row-span-1 row-start-2 col-start-2',
        // },
        // {
        //   image: Weddings4,
        //   classes: 'row-span-2 col-span-1 row-start-3 col-start-1',
        // },
        // {
        //   image: Weddings5,
        //   classes: 'row-span-2 col-span-1 row-start-3 col-start-2',
        // },
        // {
        //   image: Weddings7,
        //   classes: 'row-span-2 col-span-1 row-start-3 col-start-3',
        // },
        // {
        //   image: Weddings6,
        //   classes: 'row-span-1 col-span-3',
        // },
        // { image: Weddings8, classes: 'row-span-1 col-span-3' },
      ],
    },
    content: props => <WeddingsInner {...props} />,
    hasMasonry: true,
  },
  {
    i18nField: 'Restaurant',
    iframe360: (
      <iframe
        width="100%"
        height="640"
        frameBorder="0"
        allowFullScreen
        allow="xr-spatial-tracking; gyroscope; accelerometer"
        scrolling="no"
        src="https://kuula.co/share/NGvk8?logo=bWVkaWEvMjAzMzg2LzYxNjAtMmUyNC05MzgzLWYxODEucG5n&info=0&logosize=96&fs=1&vr=0&gyro=0&autorotate=0.1&thumbs=3&keys=0"
      ></iframe>
    ),
    url: 'restaurant',
    eventName: 'Ресторан',
    image: Restaurant5,
    shortDescription:
      'Ідеальна локація для святкування важливої події: весілля, Дня народження або корпоративу. Посередині залу є бар, а також можна встановити сцену, зробити зону для танців і банкету.',
    content: props => <RestaurantInner {...props} />,
    masonryConfig: {
      parentConfig: {
        rows: 3,
        cols: 3,
      },
      images: [
        {
          image: Restaurant2,
          classes: 'row-span-1 col-span-1',
          isVertical: true,
        },
        {
          image: Restaurant1,
          classes: 'row-span-1 col-span-2 col-start-2 col-end-4',
        },
        {
          image: Restaurant5,
          classes: 'row-span-1 col-span-3 col-start-1 col-end-4',
        },
        {
          image: Restaurant6,
          classes: 'row-span-1 col-span-1',
          isVertical: true,
        },
        {
          image: Restaurant7,
          classes: 'row-span-1 col-span-2 col-start-2 col-end-4',
        },
        // {
        //   title: 'Планування ресторану',
        //   image: Restaurant10,
        //   classes: 'row-span-1 col-span-3',
        //   isPlan: true,
        // },
      ],
    },
    hasMasonry: true,
    hasPlan: true,
    plan: {
      title: 'Планування ресторану',
      image: Restaurant10,
    },
  },
  {
    i18nField: 'Pub',
    iframe360: (
      <iframe
        width="100%"
        height="640"
        frameBorder="0"
        allowFullScreen
        allow="xr-spatial-tracking; gyroscope; accelerometer"
        scrolling="no"
        src="https://kuula.co/share/NG9dG?logo=bWVkaWEvMjAzMzg2LzYxNjAtMmUyNC05MzgzLWYxODEucG5n&info=0&logosize=96&fs=1&vr=0&gyro=0&autorotate=0.1&thumbs=3&keys=0"
      ></iframe>
    ),
    url: 'pub',
    eventName: 'Паб',
    image: Pub,
    shortDescription:
      'Авторські напої під хрускіт каміну, тераса з виходом на вулицю і красива кострова зона. Тут танцювати, слухати тости від близьких людей і запам’ятовувати кожну секунду.',
    content: props => <PubInner {...props} />,
    masonryConfig: {
      parentConfig: {
        rows: 4,
        cols: 3,
        maxHeight: 2500,
      },
      images: [
        {
          image: Pub1,
          classes: 'row-span-1 col-span-2 col-start-1 row-start-1',
        },
        {
          image: Pub10,
          classes: 'row-span-1 col-span-3 col-end-4 col-start-1 row-start-2',
        },
        { image: Pub6, classes: 'row-span-1 col-span-1 col-start-3 col-end-4' },
        { image: Pub11, classes: 'row-span-1 col-span-1' },
        {
          image: Pub14,
          classes: 'row-span-1 col-span-2 col-start-2 col-end-4',
        },
        { image: Pub12, classes: 'row-span-1 col-span-3 col-start-1' },
        {
          title: 'Планування пабу',
          image: Pub5,
          classes: 'row-span-1 col-span-3',
          isPlan: true,
        },
      ],
    },
    hasMasonry: true,
    hasPlan: true,
    plan: {
      title: 'Планування пабу',
      image: Pub5,
    },
  },
  {
    i18nField: 'Banket',
    iframe360: (
      <iframe
        width="100%"
        height="640"
        frameBorder="0"
        allowFullScreen
        allow="xr-spatial-tracking; gyroscope; accelerometer"
        scrolling="no"
        src="https://kuula.co/share/NG9HY?logo=bWVkaWEvMjAzMzg2LzYxNjAtMmQ5Yi02ZDdlLTExOTMucG5n&info=0&logosize=96&fs=1&vr=0&gyro=0&autorotate=0.1&thumbs=3&keys=0"
      ></iframe>
    ),
    url: 'banket',
    eventName: 'Банкетна кімната',
    image: Bankets,
    shortDescription:
      'Затишне місце, аби святкувати ваш день с найближчими людьми. Зазвичай тут проходять невеликі весілля, дитячі свята або сімейні вечері.',
    content: props => <BanketInner {...props} />,
    masonryConfig: {
      parentConfig: {
        rows: 5,
        cols: 3,
        maxHeight: 2750,
      },
      images: [
        { image: Banket1, classes: 'row-span-1 col-span-3 col-start-1' },
        { image: Banket2, classes: 'row-span-1 col-span-3 col-start-1' },
        {
          image: Banket8,
          classes: 'row-start-3 row-end-5 col-start-1',
        },
        {
          image: Banket3,
          classes: 'row-span-1 col-span-2 row-start-3 col-start-2',
        },
        {
          image: Banket4,
          classes: 'row-span-1 col-span-2 row-start-4 col-start-2',
        },
        { image: Banket5, classes: 'row-span-1 col-span-3' },
        {
          title: 'Планування банкету',
          image: Banket7,
          classes: 'row-span-1 col-span-3',
          isPlan: true,
        },
      ],
    },
    hasPlan: true,
    hasMasonry: true,
    plan: {
      title: 'Планування банкету',
      image: Banket7,
    },
  },
  {
    i18nField: 'NearWater',
    iframe360: null,
    image: NearWater1,
    url: 'near-water',
    eventName: 'Зона біля води',
    shortDescription:
      'Локація, де нічого не відволікає від найважливішого: є лише шум річки, запах лісу і ваш особливий момент. Можна кататися на каяках або вейкбордах, а можна пити ігристе і ходити босоніж по піску.',
    content: props => <NearWaterInner {...props} />,
    masonryConfig: {
      parentConfig: {
        rows: 2,
        cols: 3,
        maxHeight: 1300,
      },
      images: [
        {
          image: NearWater1,
          classes: 'row-span-1 col-span-1 row-start-1 col-start-1',
        },
        {
          image: NearWater2,
          classes: 'row-span-1 col-span-1 row-start-1 col-start-2',
        },
        {
          image: NearWater3,
          classes: 'row-span-1 col-span-1 row-start-1 col-start-3',
        },
        {
          image: NearWater4,
          classes: 'row-span-1 col-span-3 row-start-2 col-start-1',
        },
      ],
    },
    hasMasonry: true,
  },
]
